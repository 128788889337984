import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role, AccessRight, WebUrl } from "../../../util/Constant";
import { arrayGroupByKey, getValidationMessage, isObjectEmpty, stringIsNullOrEmpty } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import RegionFilterDualListBox from "../../../components/custom/RegionFilterDualListBox";
import { showResponseMessage } from "redux/AppAction.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { checkIfPermissionExist } from "util/PermissionChecker.js";

/// <summary>
/// Author : Saitama
/// </summary> 
const UserGrowthReport = props => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors, watch } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD "));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [regions, setRegions] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [postData, setPostData] = useState({});
    const [members, setMembers] = useState({});

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            width: 30,
            disableSortBy: true
        },
        {
            Header: "SHOP",
            Cell: ({ row }) => {
                return <div>{`${row.original.username} (${row.original.userFullName})`}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_NEW_MEMBER",
            accessor: "totalMember",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <a href='#' className='clickable-link text-decoration-underline' onClick={() => { getShopMember(row.original.userId, row.original.username, row.original.userFullName) }}>
                    <span>{row.original.totalMember}</span>
                </a>
            }
        },
    ];
    let _memberTableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            width: 30,
            disableSortBy: true
        },
        {
            Header: "MEMBER",
            Cell: ({ row }) => {
                return <div>{`${row.original.username} (${stringIsNullOrEmpty(row.original.userFullName) ? '-' : row.original.userFullName})`}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "CREATED_TIME",
            accessor: "createdTimeString",
            disableSortBy: true
        },
        {

            id: "action",
            Header: "",
            Cell: ({ row }) => {
                return (
                    checkIfPermissionExist(AccessRight._SET_SCORE_REPORT_PERMISSION, false) && <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => {
                                    const win = window.open(`${WebUrl._URL_SET_SCORE_REPORT}?username=${row.original.username}&startDate=${watch('startDate')}&endDate=${watch('endDate')}`
                                        , "_blank");
                                    win.focus();
                                }}><span>{t("VIEW_SET_SCORE_REPORT")}</span></DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                )
            },
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", width: '1%' }
        }
    ]

    /// <summary>
    /// Author : Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            register({ name: 'shop' });
            register({ name: 'startDate' }, { required: true });
            register({ name: 'endDate' }, { required: true });

            setValue('shop', []);
            setValue('startDate', moment().startOf('month').format("YYYY-MM-DD"));
            setValue('endDate', moment().endOf('day').format("YYYY-MM-DD"));

            getShopOption();
        })();
    }, [])

    /// <summary>
    /// Author : Saitama
    /// </summary>
    async function getShopOption() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];
            var regionOptions = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                regionOptions.push({ label: key, value: key });

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + (shop.shopName ? " (" + shop.shopName + ")" : ""), value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
            setRegions(regionOptions);
        }
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    async function getShopMember(shopId, shopUsername, shopName) {
        try {
            var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_USER_GROWTH_MEMBER}?shopId=${shopId}&startDate=${watch('startDate')}&endDate=${watch('endDate')}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setMembers({
                users: [...responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]],
                shopUsername,
                shopName
            });
        }
        catch (err) {
            _dispatch(showResponseMessage(false, t(err)));
        }
    }

    /// <summary>
    /// Author : Saitama
    /// </summary> 
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "TargetShop": data.shop
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_USER_GROWTH_REPORT);
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            className="col-nowrap"
            initialPageSize={500}
            columns={_tableColumns}
            fetchUrl={apiUrl}
            postData={postData}
            renderFooter={true}
            exportRequired={true} />
        , [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t("USER_GROWTH_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("SHOP")}</label>
                                    <RegionFilterDualListBox
                                        canFilter
                                        name={"shop"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('shop', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        ref={register}
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD"));
                                                setValue("startDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        ref={register}
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD"));
                                                setValue("endDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            <Modal isOpen={!isObjectEmpty(members)} toggle={() => setMembers({})} size={'lg'}>
                <ModalHeader toggle={() => setMembers({})}>{`${members?.shopUsername} (${members?.shopName})`}</ModalHeader>
                <ModalBody>
                    <ReactTable data={members?.users ?? []} columns={_memberTableColumns} />
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-white" onClick={() => setMembers({})}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default UserGrowthReport;