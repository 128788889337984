import React, { useState, useEffect, useRef } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, Status, WebUrl, AlertTypes, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, validatePhoneNumber } from "../../../util/Util";
import { useLocation } from "react-router-dom";
import { setBusy, setIdle, showMessage, showResponseMessage } from '../../../redux/AppAction';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../../util/ApiEngine.js';
import NavigationButton from '../../../components/custom/NavigationButton';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DualListBox from 'react-dual-listbox';

/// <summary>
/// Author : YJ
/// Edited: CK - Shop assignment
/// </summary>
const AdminDetail = props => {
    const { t } = useTranslation();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _history = useHistory();
    const { register, unregister, handleSubmit, errors, triggerValidation, formState, setValue } = useForm();
    const [roleOptions, setRoleOptions] = useState([]);
    const _userData = useSelector(state => state.authState.userData);

    const [status, setStatus] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState('');
    const [roleId, setRoleId] = useState('');
    const [renderPhone, setRenderPhone] = useState(false);
    const _phoneFieldRef = useRef(null);
    const [regionOptions, setRegionOptions] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [shopOptions, setShopOptions] = useState([]);
    const [attachedShops, setAttachedShops] = useState([]);
    const [shopAvailableList, setShopAvailableList] = useState([]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        if (data.password != data.confirmPassword) {
            _dispatch(showResponseMessage(false, "CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY"));
            return;
        }

        let params = {
            "username": data.username,
            "password": data.password,
            "phoneNumber": data.phone,
            "userFullName": data.fullName,
            "roleId": roleId,
            "status": data.status ? Status._ENABLED : Status._DISABLED,
            "isSubAccount": true,
            "regionId": _userData.regionId,
            "shopManagerList": attachedShops.join(","),
        };

        if (!stringIsNullOrEmpty(userId)) {
            params["id"] = userId;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_ADMIN)
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (shopOptions.length) {
            let tempShopAvailableList = [];
            selectedRegions.map(selectedRegion => {
                let filteredShops = shopOptions.filter(shopOption => shopOption.value == selectedRegion);

                if (filteredShops.length && filteredShops[0].options.length) {
                    filteredShops[0].options.map(filteredShop => {
                        tempShopAvailableList.push(filteredShop.value);
                    })
                }
            })

            setShopAvailableList(tempShopAvailableList);
        }
    }, [selectedRegions, shopOptions]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        register({ name: 'roleId' }, { required: "PLEASE_SELECT_ROLE" });
        register({ name: 'phone' });
        setValue("phone", "");
        init();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function init() {
        if (_location.state) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + _location.state["id"]);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setStatus(responseJson[ApiKey._API_DATA_KEY]["status"] == Status._ENABLED);
                setUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
                setPassword(responseJson[ApiKey._API_DATA_KEY]["password"]);
                setConfirmPassword(responseJson[ApiKey._API_DATA_KEY]["password"]);
                setFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
                if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"])) {
                    setPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                    setValue("phone", responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                }
                setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setRoleId(responseJson[ApiKey._API_DATA_KEY]["roleId"]);

                let selectedShops = [];
                let shops = responseJson[ApiKey._API_DATA_KEY]["ownedShops"];

                if (shops != null) {
                    shops.forEach(function (shop) {
                        selectedShops.push(shop.shopUserId);
                    });

                    setAttachedShops(selectedShops);
                }

                unregister('roleId');
            }
        }

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS_ROLE);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const roleList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (role) {
                roleList.push({ label: role.roleName, value: role.id });
            });

            setRoleOptions(roleList);
        }

        setRenderPhone(true);
        getRegionList();
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getRegionList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION)

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var regionList = [];
            var selectedRegionList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
                selectedRegionList.push(region.id);
            });

            setRegionOptions(regionList);
            getShopList(regionList);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getShopList(regionList) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let shopOptionsByRegion = [];

            regionList.map(region => {
                let shopsByRegion = responseJson[ApiKey._API_DATA_KEY].filter(shop => shop.regionId == region.value);

                if (shopsByRegion.length) {
                    let options = [];

                    shopsByRegion.map(shop => {
                        options.push({
                            label: shop.username + " (" + shop.shopName + ")" ,
                            value: shop.id,
                            regionId: shop.regionId
                        });
                    });

                    shopOptionsByRegion.push({
                        label: region.label,
                        value: region.value,
                        options: options
                    })
                }
            })

            setShopOptions(shopOptionsByRegion);
        }
    }

    /// <summary>
    /// Author : CK
    /// Checkbox check/uncheck handler
    /// </summary>
    function onSelectedRegionChange(e) {
        if (selectedRegions.filter(selectedRegion => selectedRegion == e.target.value).length) {
            setSelectedRegions(selectedRegions.filter(selectedRegion => selectedRegion != e.target.value));
        }
        else {
            setSelectedRegions([...selectedRegions, e.target.value]);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const onChangePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        setPhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");

        register({ name: "phone" }, {
            validate: (value) => (validatePhoneNumber(newNumber, countryData.iso2) && validatePhoneNumber(fullNumber, countryData.iso2)) || stringIsNullOrEmpty(newNumber) || "REQUIRED_VALID_PHONE_NUMBER"
        });

        setValue("phone", stringIsNullOrEmpty(newNumber) ? '' : formattedNumber);
        triggerValidation("phone")
    }

    return (
        <div>
            <h1 className="page-header">{!stringIsNullOrEmpty(userId) ? t("EDIT_ADMIN") : t("ADD_NEW_ADMIN")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <PanelBody>
                                <h3>{t("PERSONAL_INFORMATION")}</h3>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("USERNAME")}</b></label>
                                            <input type="text" name="username"
                                                ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                                                defaultValue={username}
                                                className="form-control form-control-lg m-b-5" placeholder={t("ENTER_USERNAME")} />
                                            {errors.username && <div className="invalid-feedback">{t(errors.username.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("PASSWORD")}</b></label>
                                            <input type="password" name="password"
                                                defaultValue={password}
                                                ref={register({ required: "PLEASE_ENTER_PASSWORD" })}
                                                className="form-control form-control-lg m-b-5" placeholder={t("ENTER_PASSWORD")} />
                                            {errors.password && <div className="invalid-feedback">{t(errors.password.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("CONFIRM_PASSWORD")}</b></label>
                                            <input type="password" name="confirmPassword"
                                                defaultValue={confirmPassword}
                                                ref={register({ required: "PLEASE_ENTER_CONFIRM_PASSWORD" })}
                                                className="form-control form-control-lg m-b-5" placeholder={t("ENTER_CONFIRM_PASSWORD")} />
                                            {errors.confirmPassword && <div className="invalid-feedback">{t(errors.confirmPassword.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("FULLNAME")}</b></label>
                                            <input type="text" name="fullName"
                                                defaultValue={fullName}
                                                ref={register({ required: "PLEASE_ENTER_FULL_NAME" })}
                                                className="form-control form-control-lg m-b-5" placeholder={t("ENTER_FULL_NAME")} />
                                            {errors.fullName && <div className="invalid-feedback">{t(errors.fullName.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("PHONE")}</b></label>
                                            {renderPhone && <IntlTelInput
                                                fieldName={"phone"}
                                                preferredCountries={['my']}
                                                style={{ display: "block", width: "100%" }}
                                                containerClassName="intl-tel-input"
                                                inputClassName="form-control form-control-lg"
                                                ref={_phoneFieldRef}
                                                onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                                    onChangePhoneNumber(_phoneFieldRef.current.isValidNumber(phone), newNumber, countryData, fullNumber);
                                                }}
                                                onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                                    onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                                }}
                                                onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                                    onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                                }}
                                                customPlaceholder={(placeholder, countryData) => {
                                                    return placeholder.split(/[- ]+/).join('');
                                                }}
                                                defaultValue={phone}
                                                value={phone}
                                                useMobileFullscreenDropdown={false}
                                                separateDialCode
                                            />}
                                            {(errors.phone) && <div className="invalid-feedback">{t(errors.phone.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("ROLES")}</b></label>
                                            <Select name="roleId" options={roleOptions}
                                                placeholder={(roleOptions.filter(option => option.value == roleId)[0] !== undefined) ? (roleOptions.filter(option => option.value == roleId)[0].label) : ""}
                                                value={roleOptions.filter(option => option.value == roleId)}
                                                onChange={(e) => {
                                                    unregister('roleId');
                                                    setRoleId(e.value);
                                                }} />
                                            {errors.roleId && <div className="invalid-feedback">{t(errors.roleId.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("STATUS")}</b></label><br />
                                            <div className="switcher">
                                                <input type="checkbox" name="status" id="status"
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                    value={true}
                                                    checked={status}
                                                    ref={register} />
                                                <label htmlFor="status"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>
                            <PanelHeader noButton>{t('ASSIGN_SHOPS')}</PanelHeader>
                            <PanelBody>
                                <div className="form-group">
                                    <label>{t("REGION")}</label>
                                    <div className="row">
                                        {
                                            regionOptions.map((region, index) => {
                                                return (
                                                    <div className="col-lg-2" key={index}>
                                                        <div className="checkbox checkbox-css checkbox-inline">
                                                            <input id={region.label + region.value} type="checkbox" name={region.label + region.value}
                                                                checked={selectedRegions.filter(selectedRegion => selectedRegion == region.value).length}
                                                                value={region.value}
                                                                ref={register}
                                                                onChange={onSelectedRegionChange} />
                                                            <label htmlFor={region.label + region.value}>{region.label}</label>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t("SHOPS")}</label>
                                    <DualListBox
                                        canFilter
                                        name="shops"
                                        selected={attachedShops}
                                        options={shopOptions}
                                        available={shopAvailableList}
                                        onChange={(e) => {
                                            setAttachedShops(e);
                                        }}
                                    />
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>
                        </form>
                    </Panel>
                </div>
            </div>
        </div>
    )
}

export default AdminDetail;