import React, { useState, useEffect, useRef } from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from 'components/panel/panel.jsx';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { stringIsNullOrEmpty, createMultiPartFormBody, getValidationMessage, navigateTo, validatePhoneNumber, range } from "../../../util/Util";
import { ShopSettings, Status, Role, AlertTypes, ApiUrl, ApiKey, WebUrl } from 'util/Constant';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'redux/AppAction';
import ApiEngine from '../../../util/ApiEngine.js';
import NavigationButton from '../../../components/custom/NavigationButton';
import Select from "react-select";
import { useLocation } from "react-router-dom";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DualListBox from 'react-dual-listbox';
import { ButtonGroup, Button } from 'reactstrap';

/// <summary>
/// Author: CK
/// </summary>
const AgentDetail = props => {
    var _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _userData = useSelector(state => state['authState']['userData']);
    const { register, handleSubmit, errors, watch, unregister, setValue, triggerValidation } = useForm();
    const [regionOptions, setRegionOptions] = useState([]);
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const [renderPhone, setRenderPhone] = useState(false);
    const _phoneFieldRef = useRef(null);
    const [managerOptions, setManagerOptions] = useState([]);
    const [selectedManager, setSelectedManager] = useState([]);
    const [defaultManagerOption, setDefaultManagerOption] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [max07Options, setMax07Options] = useState(range(0, 0.7, 0.05).map(i => { return { label: i + '%', value: i } }));
    const [max20Options, setMax20Options] = useState(range(0, 20).map((_, i) => { return { label: i + '%', value: i } }));
    const [max40Options, setMax40Options] = useState(range(0, 40).map((_, i) => { return { label: i + '%', value: i } }));
    const [max5Options, setMax5Options] = useState(range(0, 5).map((_, i) => { return { label: i + '%', value: i } }));
    const _CLOSING_DAY_OPTIONS = [
        { label: `7 ${t("DAY")}`, value: 7 },
        { label: `15 ${t("DAY")}`, value: 15 },
        { label: `21 ${t("DAY")}`, value: 21 },
        { label: `31 ${t("DAY")}`, value: 31 },
    ];
    const [closingDayValue, setClosingDayValue] = useState(_CLOSING_DAY_OPTIONS[0].value);

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        register({ name: 'regionId' }, { required: "PLEASE_SELECT_REGION" });
        register({ name: 'turnoverCommission' }, { required: true });
        register({ name: 'lottoCommission' }, { required: true });
        register({ name: 'slotCommission' }, { required: true });
        register({ name: 'fishingCommission' }, { required: true });
        register({ name: 'uplineLottoCommission' }, { required: true });
        register({ name: 'uplineSlotCommission' }, { required: true });
        register({ name: 'uplineFishingCommission' }, { required: true });
        register({ name: 'phone' }, { required: true });
        setValue("phone", "");
        setValue("turnoverCommission", max07Options[0].value);
        setValue("lottoCommission", max20Options[0].value);
        setValue("slotCommission", max40Options[0].value);
        setValue("fishingCommission", max40Options[0].value);
        setValue("uplineLottoCommission", max5Options[0].value);
        setValue("uplineSlotCommission", max5Options[0].value);
        setValue("uplineFishingCommission", max5Options[0].value);
        init();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(watch('regionId')) && regionOptions.length != 0) {
            triggerValidation("regionId")

            var filteredManagerOptions = [];

            regionOptions.filter(i => i.value == watch('regionId')).map(region => {
                let managersByRegion = defaultManagerOption.filter(manager => manager.regionId == region.value);

                if (managersByRegion.length) {
                    let options = [];
                    managersByRegion.map(manager => {
                        options.push({
                            label: manager.label,
                            value: manager.value,
                            regionId: manager.regionId
                        });
                    });

                    filteredManagerOptions.push({
                        label: region.label,
                        value: region.value,
                        options: options
                    })
                }
            });

            setManagerOptions(filteredManagerOptions);
        }
    }, [regionOptions, watch('regionId')]);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let regionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });
            setRegionOptions(regionList);
        }

        if (_userData['userRoleId'] != Role._MANAGER) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?roleId=" + Role._MANAGER);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var managerOptionList = [];
                responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                    managerOptionList.push({ label: user.username + " (" + user.userFullName + ")", value: user.id, regionId: user.regionId });
                });

                setDefaultManagerOption(managerOptionList);
            }

            if (_location.state) {
                var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + _location.state["id"]);

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let agentRespone = responseJson[ApiKey._API_DATA_KEY];

                    if (!stringIsNullOrEmpty(agentRespone.phoneNumber)) {
                        setPhone(agentRespone.phoneNumber);
                        setValue("phone", agentRespone.phoneNumber);
                    }

                    setValue('regionId', agentRespone.regionId);
                    setValue('canCreatePlayer', agentRespone?.agentProfile.createPlayerEnable);
                    setValue('canReload', agentRespone?.agentProfile.reloadEnable);
                    setValue('creditLimit', agentRespone?.agentProfile.creditLimit);
                    setValue('turnoverCommission', agentRespone?.agentProfile.turnoverCommission);
                    setValue('lottoCommission', agentRespone?.agentProfile.lottoCommission);
                    setValue('slotCommission', agentRespone?.agentProfile.slotCommission);
                    setValue('fishingCommission', agentRespone?.agentProfile.fishingCommission);
                    setValue('uplineLottoCommission', agentRespone?.agentProfile.uplineLottoCommission);
                    setValue('uplineSlotCommission', agentRespone?.agentProfile.uplineSlotCommission);
                    setValue('uplineFishingCommission', agentRespone?.agentProfile.uplineFishingCommission);
                    setSelectedAgent(agentRespone);
                    setClosingDayValue(agentRespone ? agentRespone.agentProfile.closingDay :_CLOSING_DAY_OPTIONS[0].value);

                    let managers = responseJson[ApiKey._API_DATA_KEY]["associatedAgentManagers"];

                    if (managers != null) {
                        let agentManagers = [];

                        managers.forEach(function (manager) {
                            agentManagers.push(manager.managerUserId);
                        });

                        setSelectedManager(agentManagers);
                    }
                }
            }
        }
        else {
            // Set current manager as assigned manager for agent
            setSelectedManager(_userData['userId']);
        }

        setRenderPhone(true);
    }

    /// <summary>
    /// Author: CK - Redirect back once completed submission
    /// </summary>
    const onSubmit = async (data) => {
        let params = {
            username: `A${data.phone.substring(1)}`,
            userFullName: data.userFullName,
            roleId: Role._AGENT,
            phoneNumber: `A${data.phone}`,
            regionId: data.regionId,
            createPlayerEnable: data.canCreatePlayer,
            reloadEnable: data.canReload,
            shopName: data.userFullName,
            address: data.address,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            lottoCommission: data.lottoCommission,
            slotCommission: data.slotCommission,
            fishingCommission: data.fishingCommission,
            uplineName: data.uplineName,
            uplineLottoCommission: data.uplineLottoCommission,
            uplineSlotCommission: data.uplineSlotCommission,
            uplineFishingCommission: data.uplineFishingCommission,
            agentManagerList: selectedManager.join(","),
            creditLimit: data.creditLimit,
            turnoverCommission: data.turnoverCommission,
            closingDay: closingDayValue
        };

        if (selectedAgent) {
            params["id"] = selectedAgent.id;
        }
        else {
            params["status"] = Status._ENABLED;
            params["password"] = data.password;
            params["credit"] = data.credit;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_AGENT),
        }));
    };

    /// <summary>
    /// Author : CK
    /// </summary>
    const onChangePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        setPhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");
        register({ name: "phone" }, {
            validate: (value) => (validatePhoneNumber(fullNumber, countryData["iso2"]) && validatePhoneNumber(newNumber, countryData["iso2"])) || stringIsNullOrEmpty(newNumber) || "REQUIRED_VALID_PHONE_NUMBER"
        });
        setValue("phone", stringIsNullOrEmpty(newNumber) ? '' : formattedNumber);
        triggerValidation("phone")
    }

    return (
        <div>
            <h1 className="page-header">
                {_location.state ? t("EDIT_AGENT") : t("CREATE_AGENT")}
                <NavigationButton history={_history} />
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Panel>
                    <PanelHeader noButton>{t("AGENT_DETAIL")}</PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("NAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="userFullName"
                                        name="userFullName"
                                        type="text"
                                        defaultValue={selectedAgent ? selectedAgent.userFullName : ''}
                                        placeholder="Enter Name"
                                        ref={register({ required: true })} />
                                    {errors.userFullName && <div className="invalid-feedback">{t(getValidationMessage(errors.userFullName))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input
                                        autoComplete="new-password"
                                        disabled
                                        className="form-control form-control-lg"
                                        id="username"
                                        defaultValue={selectedAgent ? selectedAgent.username : ''}
                                        type="text"
                                        value={`A${watch('phone')?.substring(1)}`}
                                        placeholder="Enter Username" />
                                    {errors.username && <div className="invalid-feedback">{t(getValidationMessage(errors.username))}</div>}
                                </div>
                            </div>
                            {!_location.state &&
                                <>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("PASSWORD")}</label>
                                            <input
                                                autoComplete="new-password"
                                                className="form-control form-control-lg"
                                                id="password"
                                                name="password"
                                                placeholder="Enter Password"
                                                type="password"
                                                ref={register({ required: true })} />
                                            {errors.password && <div className="invalid-feedback">{t(getValidationMessage(errors.password))}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("CONFIRM_PASSWORD")}</label>
                                            <input
                                                autoComplete="new-password"
                                                className="form-control form-control-lg"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                placeholder="Enter Confirm Password"
                                                type="password"
                                                ref={register({
                                                    required: true,
                                                    validate: (value) => {
                                                        return value === watch('password') || 'PASSWORD_DO_NOT_MATCH'
                                                    }
                                                })} />
                                            {errors.confirmPassword && <div className="invalid-feedback">{t(getValidationMessage(errors.confirmPassword))}</div>}
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("PHONE")}</label>
                                    {renderPhone && <IntlTelInput
                                        fieldName={"phone"}
                                        preferredCountries={['my']}
                                        style={{ display: "block", width: "100%" }}
                                        containerClassName="intl-tel-input"
                                        inputClassName="form-control form-control-lg"
                                        ref={_phoneFieldRef}
                                        onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(_phoneFieldRef.current.isValidNumber(phone), newNumber, countryData, fullNumber);
                                        }}
                                        onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                        }}
                                        onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                        }}
                                        customPlaceholder={(placeholder, countryData) => {
                                            return placeholder.split(/[- ]+/).join('');
                                        }}
                                        defaultValue={''}
                                        value={phone}
                                        useMobileFullscreenDropdown={false}
                                        separateDialCode
                                    />}
                                    {errors.phone && <div className="invalid-feedback">{t(getValidationMessage(errors.phone))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("REGION")}</label>
                                    <Select
                                        name="regionId"
                                        options={regionOptions}
                                        placeholder={(regionOptions.filter(option => option.value == watch('regionId'))[0] !== undefined) ? (regionOptions.filter(option => option.value == watch('regionId'))[0].label) : ""}
                                        value={regionOptions.filter(option => option.value == watch('regionId'))}
                                        ref={register({ required: true })}
                                        onChange={(e) => {
                                            setValue("regionId", e.value);
                                        }} />
                                    {errors.regionId && <div className="invalid-feedback">{t(getValidationMessage(errors.regionId))}</div>}
                                </div>
                            </div>
                            {
                                !_location.state &&
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("CREDIT")}</label>
                                        <input
                                            className="form-control form-control-lg"
                                            id="credit"
                                            name="credit"
                                            placeholder={t("ENTER_CREDIT")}
                                            step={0.01}
                                            type="number"
                                            ref={register({ required: true, min: 0 })}
                                        />
                                        {errors.credit && <div className="invalid-feedback">{t(getValidationMessage(errors.credit))}</div>}
                                    </div>
                                </div>
                            }
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("CREDIT_LIMIT")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="creditLimit"
                                        name="creditLimit"
                                        placeholder={t("ENTER_CREDIT_LIMIT")}
                                        step={0.01}
                                        type="number"
                                        ref={register({ required: true, min: 0 })}
                                    />
                                    {errors.creditLimit && <div className="invalid-feedback">{t(getValidationMessage(errors.creditLimit))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("CLOSING_DAY")}</label><br/>
                                    <ButtonGroup className="btn-group-selection">
                                        {
                                            _CLOSING_DAY_OPTIONS.map((option) =>
                                                <Button
                                                    type="button"
                                                    color="default"
                                                    active={option.value == closingDayValue}
                                                    onClick={() => setClosingDayValue(option.value)}
                                                >
                                                    {option.label}
                                                </Button>
                                            )
                                        }
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    <PanelHeader noButton>{t("ADDRESS")}</PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("ADDRESS")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="address"
                                        name="address"
                                        type="text"
                                        defaultValue={selectedAgent?.agentProfile ? selectedAgent.agentProfile.address : ''}
                                        ref={register}
                                    />
                                    {errors.address && <div className="invalid-feedback">{t(getValidationMessage(errors.address))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("CITY")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="city"
                                        name="city"
                                        type="text"
                                        defaultValue={selectedAgent?.agentProfile ? selectedAgent.agentProfile.city : ''}
                                        ref={register}
                                    />
                                    {errors.city && <div className="invalid-feedback">{t(getValidationMessage(errors.city))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("STATE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="state"
                                        name="state"
                                        type="text"
                                        defaultValue={selectedAgent?.agentProfile ? selectedAgent.agentProfile.state : ''}
                                        ref={register}
                                    />
                                    {errors.state && <div className="invalid-feedback">{t(getValidationMessage(errors.state))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("POSTAL_CODE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="postalCode"
                                        name="postalCode"
                                        type="number"
                                        defaultValue={selectedAgent?.agentProfile ? selectedAgent.agentProfile.postalCode : ''}
                                        ref={register}
                                    />
                                    {errors.postalCode && <div className="invalid-feedback">{t(getValidationMessage(errors.postalCode))}</div>}
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    <PanelHeader noButton>{t("COMMISSION")}</PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("SLOT_COMMISSION")} %</label>
                                    <Select
                                        name="slotCommission"
                                        options={max40Options}
                                        value={max40Options.filter(option => option.value == watch('slotCommission'))}
                                        ref={register({ required: true })}
                                        onChange={(e) => {
                                            setValue("slotCommission", e.value);
                                        }} />
                                    {errors.slotCommission && <div className="invalid-feedback">{t(getValidationMessage(errors.slotCommission))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("FISHING_COMMISSION")} %</label>
                                    <Select
                                        name="fishingCommission"
                                        options={max40Options}
                                        value={max40Options.filter(option => option.value == watch('fishingCommission'))}
                                        ref={register({ required: true })}
                                        onChange={(e) => {
                                            setValue("fishingCommission", e.value);
                                        }} />
                                    {errors.fishingCommission && <div className="invalid-feedback">{t(getValidationMessage(errors.fishingCommission))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("LOTTO_COMMISSION")} %</label>
                                    <Select
                                        name="lottoCommission"
                                        options={max20Options}
                                        value={max20Options.filter(option => option.value == watch('lottoCommission'))}
                                        ref={register({ required: true })}
                                        onChange={(e) => {
                                            setValue("lottoCommission", e.value);
                                        }} />
                                    {errors.lottoCommission && <div className="invalid-feedback">{t(getValidationMessage(errors.lottoCommission))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TURNOVER_COMMISSION")} %</label>
                                    <Select
                                        name="turnoverCommission"
                                        options={max07Options}
                                        value={max07Options.filter(option => option.value == watch('turnoverCommission'))}
                                        ref={register({ required: true, min: 0 })}
                                        onChange={(e) => {
                                            setValue("turnoverCommission", e.value);
                                        }} />
                                    {errors.turnoverCommission && <div className="invalid-feedback">{t(getValidationMessage(errors.turnoverCommission))}</div>}
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    <PanelHeader noButton>{t("UPLINE")}</PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("UPLINE_NAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="uplineName"
                                        name="uplineName"
                                        placeholder={t("ENTER_UPLINE_NAME")}
                                        defaultValue={selectedAgent?.agentProfile.uplineName}
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("UPLINE_SLOT_COMMISSION")} %</label>
                                    <Select
                                        name="uplineSlotCommission"
                                        options={max5Options}
                                        value={max5Options.filter(option => option.value == watch('uplineSlotCommission'))}
                                        ref={register({ required: true })}
                                        onChange={(e) => {
                                            setValue("uplineSlotCommission", e.value);
                                        }} />
                                    {errors.uplineSlotCommission && <div className="invalid-feedback">{t(getValidationMessage(errors.uplineSlotCommission))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("UPLINE_FISHING_COMMISSION")} %</label>
                                    <Select
                                        name="uplineFishingCommission"
                                        options={max5Options}
                                        value={max5Options.filter(option => option.value == watch('uplineFishingCommission'))}
                                        ref={register({ required: true })}
                                        onChange={(e) => {
                                            setValue("uplineFishingCommission", e.value);
                                        }} />
                                    {errors.uplineFishingCommission && <div className="invalid-feedback">{t(getValidationMessage(errors.uplineFishingCommission))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("UPLINE_LOTTO_COMMISSION")} %</label>
                                    <Select
                                        name="uplineLottoCommission"
                                        options={max5Options}
                                        value={max5Options.filter(option => option.value == watch('uplineLottoCommission'))}
                                        ref={register({ required: true })}
                                        onChange={(e) => {
                                            setValue("uplineLottoCommission", e.value);
                                        }} />
                                    {errors.uplineLottoCommission && <div className="invalid-feedback">{t(getValidationMessage(errors.uplineLottoCommission))}</div>}
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    {
                        _userData['userRoleId'] != Role._MANAGER &&
                        <>
                            <PanelHeader noButton>{t("ASSIGN_MANAGERS")}</PanelHeader>
                            <PanelBody>
                                <div className="form-group">
                                    <DualListBox
                                        canFilter
                                        selected={selectedManager}
                                        options={managerOptions}
                                        onChange={(e) => { setSelectedManager(e) }}
                                    />
                                </div>
                            </PanelBody>
                        </>
                    }
                    <PanelHeader noButton>{t("SETTING")}</PanelHeader>
                    <PanelBody>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="playerCheckbox" type="checkbox" name="canCreatePlayer" checked={watch('canCreatePlayer')}
                                onChange={(e) => setValue('canCreatePlayer', e.target.checked)}
                                ref={register} />
                            <label htmlFor="playerCheckbox">{t("CAN_CREATE_PLAYER")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="reloadCheckbox" type="checkbox" name="canReload" checked={watch('canReload')}
                                onChange={(e) => setValue('canReload', e.target.checked)}
                                ref={register} />
                            <label htmlFor="reloadCheckbox">{t("CAN_RELOAD")}</label>
                        </div>
                        <div className="row m-t-20">
                            <div className="col-lg-2">
                                <button type="submit" className="btn btn-primary">{t("BTN_SAVE")}</button>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </form>
        </div >
    )
}

export default AgentDetail;