import React, { useState, useEffect } from 'react';
import { ApiKey, ApiUrl, Role } from '../../util/Constant';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import DualListBox from 'react-dual-listbox';
import { useForm } from "react-hook-form";
import { setIdle, setBusy } from '../../redux/AppAction';
import { useDispatch } from 'react-redux';

/// <summary>
/// Author: CK
/// </summary>
const AttachShopModal = props => {
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm();
    const [regionOptions, setRegionOptions] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([])
    const [shopOptions, setShopOptions] = useState([]);
    const [attachedShops, setAttachedShops] = useState([]);
    const [shopAvailableList, setShopAvailableList] = useState([]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (props.isModalVisible) {
            init();
        }
        else {
            setShopOptions([]);
            setAttachedShops([]);
            setShopAvailableList([]);
        }
    }, [props.isModalVisible]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (shopOptions.length) {
            let tempShopAvailableList = [];
            selectedRegions.map(selectedRegion => {
                let filteredShops = shopOptions.filter(shopOption => shopOption.value == selectedRegion);

                if (filteredShops.length && filteredShops[0].options.length) {
                    filteredShops[0].options.map(filteredShop => {
                        tempShopAvailableList.push(filteredShop.value);
                    })
                }
            })

            setShopAvailableList(tempShopAvailableList);
        }
    }, [selectedRegions, shopOptions]);

    /// <summary>
    /// Author: CK
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION)

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var regionList = [];
            var selectedRegionList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
                selectedRegionList.push(region.id);
            });

            setRegionOptions(regionList);
            var attachedShopList = await getAttachedShop(props.getAttachedShopUrl);
            await getShopList(regionList, attachedShopList);
        }
        _dispatch(setIdle());
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getShopList(regionList, attachedShopList) {
        var responseJson = await ApiEngine.get(props.getShopListUrl ?? ApiUrl._API_GET_SHOPS + "?RoleId=" + (props.roleId ?? Role._SHOP));
        
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let shopOptionsByRegion = [];
            var shopToFilter = responseJson[ApiKey._API_DATA_KEY];

            attachedShopList.forEach(function (attachedShop) {
                if (shopToFilter.filter(i => i.userId == attachedShop.userId).length == 0) {
                    shopToFilter.push(attachedShop);
                }
            });

            regionList.map(region => {
                let shopsByRegion = shopToFilter.filter(shop => shop.regionId == region.value);

                if (shopsByRegion.length) {
                    let options = [];

                    shopsByRegion.map(shop => {
                        options.push({
                            label: shop.username + " (" + (shop.shopName ? shop.shopName : shop.userFullName) + ")",
                            value: shop.id,
                            regionId: shop.regionId
                        });
                    });

                    shopOptionsByRegion.push({
                        label: region.label,
                        value: region.value,
                        options: options
                    })
                }
            })

            setShopOptions(shopOptionsByRegion);
        }
    }

    /// <summary>
    /// Author : CK
    /// Checkbox check/uncheck handler
    /// </summary>
    function onSelectedRegionChange(e) {
        if (selectedRegions.filter(selectedRegion => selectedRegion == e.target.value).length) {
            setSelectedRegions(selectedRegions.filter(selectedRegion => selectedRegion != e.target.value));
        }
        else {
            setSelectedRegions([...selectedRegions, e.target.value]);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getAttachedShop(getAttachedShopUrl) {
        var attachedShopUserList = [];
        var responseJson = await ApiEngine.get(getAttachedShopUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var attachedShopList = [];
            (responseJson[ApiKey._API_DATA_KEY] ?? []).forEach(function (attachedShop) {
                attachedShopList.push(attachedShop.userId);
            });

            attachedShopUserList = responseJson[ApiKey._API_DATA_KEY];
            setAttachedShops(attachedShopList);
        }
        else {
            props.onFinish(false);
        }

        return attachedShopUserList;
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function onSubmit() {
        props.onFinish(attachedShops);
    }

    return (
        <Modal isOpen={props.isModalVisible} toggle={() => { props.onFinish(false) }} size="lg">
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => { props.onFinish(false) }}><h4 className='mb-0'>
                    {t(props.roleId ? "ATTACH_AGENTS" : "ATTACH_SHOPS")} {" (" + props.title + ") "}
                </h4>
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label>{t("REGION")}</label>
                        <div className="row">
                            {
                                regionOptions.map((region, index) => {
                                    return (
                                        <div className="col-lg-2" key={index}>
                                            <div className="checkbox checkbox-css checkbox-inline">
                                                <input id={region.label + region.value} type="checkbox" name={region.label + region.value}
                                                    checked={selectedRegions.filter(selectedRegion => selectedRegion == region.value).length}
                                                    value={region.value}
                                                    ref={register}
                                                    onChange={onSelectedRegionChange} />
                                                <label htmlFor={region.label + region.value}>{region.label}</label>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{t("SHOPS")}</label>
                        <DualListBox
                            canFilter
                            name="shops"
                            selected={attachedShops}
                            options={shopOptions}
                            available={shopAvailableList}
                            onChange={(e) => {
                                setAttachedShops(e);
                            }}
                            ref={register({ required: true })}
                        />
                        {errors.shops && <div className="invalid-feedback">{t(errors.shops.message)}</div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AttachShopModal;