import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo } from "../../../util/Util";
import { ApiKey, ApiUrl, AlertTypes, Role, AnnouncementType, WebUrl } from "../../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from '../../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import DualListBox from 'react-dual-listbox';
import ApiEngine from '../../../util/ApiEngine.js';
import PreviewMessageModal from '../../../components/custom/PreviewMessageModal';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const SystemMessageDetail = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    let _location = useLocation();
    let _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, triggerValidation, watch, getValues } = useForm();

    const [messageId, setMessageId] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState(false);
    const [regions, setRegions] = useState([]);
    const [targetRole, setTargetRole] = useState([]);
    const [regionOption, setRegionOption] = useState([]);
    const [roleOption, setRoleOption] = useState([]);
    const [isConfirm, setIsConfirm] = useState(false);
    const [alertRequired, setAlertRequired] = useState(false);
    const _submitBtnRef = useRef(null);

    const [previewParams, setPreviewParams] = useState([]);
    const [modalVisibility, setModalVisibility] = useState(false);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'regions' }, { required: "PLEASE_SELECT_REGION" });
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const regionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });

            setRegionOption(regionList);
        }

        if (_location.state) {
            responseJson = await ApiEngine.get(ApiUrl._API_GET_SYSTEM_MESSAGE_BY_ID + "?id="+_location.state.id);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setContent(responseJson[ApiKey._API_DATA_KEY].content);
                setTitle(responseJson[ApiKey._API_DATA_KEY].title);
                setStatus(responseJson[ApiKey._API_DATA_KEY].status);
                setMessageId(responseJson[ApiKey._API_DATA_KEY].id);
                setRegions(responseJson[ApiKey._API_DATA_KEY].regionIdList);
                setTargetRole(responseJson[ApiKey._API_DATA_KEY].roleList);
                setValue('regions', responseJson[ApiKey._API_DATA_KEY].regionIdList);
            }
        }

        responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS_ROLE + "?includeInternal=true");

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const roleList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (role) {
                if (role.id != Role._MEMBER) {
                    roleList.push({ label: role.roleName, value: role.id });
                }
            });

            setRoleOption(roleList);
        }

        triggerRoleCheck(false);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (status) {
            _dispatch(showMessage({
                type: AlertTypes._WARNING,
                content: t("IT_WILL_BROADCAST_DIRECTLY_ARE_YOU_SURE") + "?",
                showCancel: true,
                confirmBtnText: t("CONFIRM"),
                onConfirm: () => { setIsConfirm(true); _submitBtnRef.current.click(); },
                onCancel: () => { setIsConfirm(false); }
            }));
        }
    }, [alertRequired])

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        if (!isConfirm && status) {
            setAlertRequired(!alertRequired);
            return;
        }

        let params = {
            "regionId": data.regions,
            "title": data.title,
            "content": data.content,
            "systemMessageTypeId": AnnouncementType._ANNOUNCEMENT_TYPE_WEB,
            "status": status
        }

        if (data.role != null && data.role.filter(o => o != false).length != 0) {
            params["role"] = data.role.filter(o => o != false);
        }

        if (!stringIsNullOrEmpty(messageId)) {
            params["id"] = messageId;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_MESSAGE, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_SYSTEM_MESSAGE)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function triggerRoleCheck(performValidate) {
        var role = watch("role");

        var isValid = role != null && role.filter(o => o != false).length != 0;

        register({ name: "roleSelection" }, {
            validate: (value) => isValid || "REQUIRED_SELECT_ROLE"
        });

        if (performValidate) {
            triggerValidation("roleSelection");
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function previewTarget() {
        const triggerResult = await triggerValidation();
        if (!triggerResult) {
            return;
        }

        let params = {
            "regionId": getValues()['regions'],
            "systemMessageTypeId": AnnouncementType._ANNOUNCEMENT_TYPE_WEB,
        }
        var role = watch("role");

        if (role != null && role.filter(o => o != false).length != 0) {
            params["role"] = role.filter(o => o != false);
        }

        setPreviewParams(params);
        setModalVisibility(true);
    }

    return (
        <div>
            <PreviewMessageModal
                params={previewParams}
                messageType={AnnouncementType._ANNOUNCEMENT_TYPE_WEB}
                visible={modalVisibility}
                onFinish={() => { setModalVisibility(false) }} />
            <h1 className="page-header">{_location.state ? t("EDIT_SYSTEM_MESSAGE") : t("ADD_NEW_SYSTEM_MESSAGE")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <div className="panel panel-inverse">
                        <div className="panel-body">
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row form-group">
                                    <label className="col-lg-3 col-form-label"><b>{t("REGION")}</b></label>
                                    <div className="col-lg-5">
                                        <DualListBox
                                            canFilter
                                            name="regions"
                                            selected={regions}
                                            options={regionOption}
                                            onChange={(e) => {
                                                setValue('regions', (e == null || e.length == 0) ? '' : e);
                                                setRegions(e);
                                            }}
                                        />
                                        {errors.regions && <div className="invalid-feedback">{t(errors.regions.message)}</div>}
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <label className="col-lg-3 col-form-label"><b>{t("TITLE")}</b></label>
                                    <div className="col-lg-5">
                                        <input type="text" name="title" className="form-control m-b-5"
                                            ref={register({
                                                required: "PLEASE_ENTER_TITLE"
                                            })}
                                            placeholder={t("PLEASE_ENTER_TITLE")} defaultValue={title} />
                                        {errors.title && <div className="invalid-feedback">{t(errors.title.message)}</div>}
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <label className="col-lg-3 col-form-label"><b>{t("CONTENT")}</b></label>
                                    <div className="col-lg-5">
                                        <textarea ref={register} class="form-control" rows="5"
                                            style={{ marginTop: "10px" }}
                                            name="content"
                                            ref={register({
                                                required: "PLEASE_ENTER_CONTENT"
                                            })}
                                            defaultValue={content}>
                                        </textarea>
                                        {errors.content && <div className="invalid-feedback">{t(errors.content.message)}</div>}
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <label className="col-lg-3 col-form-label"><b>{t("BROADCAST")}</b></label>
                                    <div className="col-lg-5">
                                        <div className="switcher">
                                            <input type="checkbox" name="status" id="status"
                                                onChange={(e) => {
                                                    setStatus(e.target.checked);
                                                    setIsConfirm(!e.target.checked);
                                                }}
                                                value={true}
                                                checked={status}
                                                ref={register} />
                                            <label htmlFor="status"></label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        {Object.keys(roleOption).map((key, value) => {
                                            return (
                                                <div className="checkbox checkbox-css checkbox-inline" key={key}>
                                                    <input type="checkbox" value={roleOption[key]["value"]} id={"role" + key} name={"role[" + key + "]"} ref={register}
                                                        defaultChecked={targetRole.filter(o => o == roleOption[key]["value"]).length != 0}
                                                        onChange={() => triggerRoleCheck(true)} />
                                                    <label htmlFor={"role" + key}>{roleOption[key]["label"]}</label>
                                                </div>);
                                        })}
                                        {errors.roleSelection && <div className="invalid-feedback">{t(errors.roleSelection.message)}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" ref={_submitBtnRef} style={{ margin: "5px" }} className="btn btn-primary">{t("SUBMIT")}</button>
                                        <button type="button" onClick={previewTarget} style={{ margin: "5px" }} className="btn btn-warning">{t("PREVIEW")}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SystemMessageDetail);