/// <summary>
/// Author : Yong Sheng Chuan
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
	static _API_SUCCESS_KEY = "success";
	static _API_MESSAGE_KEY = "message";
	static _API_DATA_KEY = "data";
	static _API_CODE_KEY = "code";

	static _API_CONTENT_TYPE = "Content-Type";
	static _API_ACCEPT = "Accept";
	static _API_APPLICATION_JSON = "application/json";
	static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
	static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
	static _API_POST = "POST";
	static _API_GET = "GET";
}

/// <summary>
/// Author : Yong Sheng Chuan
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
	static _API_LOGIN = "/Account/Login";
	static _API_LOGOUT = "/Account/Logout";
	static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
	static _API_GET_USERS_ROLE = "/Account/GetAllRoles";
	static _API_GET_ROLE_ACCESS_DATA = "/Account/GetRoleAccessData";
	static _API_GET_ROLE_BY_ID = "/Account/GetRoleById";
	static _API_CREATE_OR_UPDATE_ROLE = "/Account/CreateOrUpdateRole";
	static _API_GET_USERS = "/Account/GetUser";
	static _API_CREATE_OR_UPDATE_USER_ACCOUNT = "/Account/CreateOrUpdateUserAccount";
	static _API_GET_ACCOUNT = "/Account/GetAccountById";
	static _API_CHANGE_PASSWORD = "/Account/ChangePassword";
	static _API_VERIFY_PASSWORD = "/Account/VerifyPassword";
	static _API_RESET_MEMBER_PASSWORD = "/Account/ResetMemberPassword";
	static _API_BULK_ASSIGN_SHOP_SETTINGS = "/Account/BulkUpdateShopProfile";
	static _API_GET_ACCOUNT_BY_REGIONS = "/Account/GetAccountByRegions";
	static _API_GET_PLAYER_LIST = "/Account/GetPlayerList";
	static _API_CREATE_OR_UPDATE_PLAYER = "/Account/CreateOrUpdate";
	static _API_GET_PLAYER_BY_ID = "/Account/GetPlayerById";
	static _API_COPY_USER_ACCOUNT = "/Account/CopyUserAccount";
	static _API_PERFORM_MEMBER_ROLLOVER_RESET = "/Account/PerformMemberRolloverReset";
	static _API_GET_MEMBERS_BY_RANK_ID = "/Account/GetMembersByRankId";
	static _API_UPDATE_MEMBER_PROFILE_PERMISSION = "/Account/UpdateMemberProfilePermission";

	static _API_SEARCH_MEMBER = "/Shop/SearchMember";
	static _API_RETRIEVE_RELOAD_DETAILS = "/Shop/RetreiveReloadDetailsByUsername";
	static _API_GET_TRANSACTION_REQUEST = "/Shop/GetTransactionRequest";
	static _API_SETTLE_TRANSACTION_REQUEST = "/Shop/SettleTransactionRequest";
	static _API_GET_SHOPS = "/Shop/GetShops";

	static _API_GET_VENDOR_API_LIST = "/Vendor/GetVendorApiList";
	static _API_GET_VENDOR_LIST = "/Vendor/GetVendorList";
	static _API_GET_VENDOR_BY_ID = "/Vendor/GetVendorById";
	static _API_GET_VENDOR_API_BY_ID = "/Vendor/GetVendorApiById";
	static _API_CREATE_OR_UPDATE_VENDOR = "/Vendor/CreateOrUpdateVendor";
	static _API_GET_VENDOR_LOTTO_PACKAGE_BY_API = "/Vendor/api/{id}/lotto-package";
	static _API_CREATE_OR_UPDATE_VENDOR_API = "/Vendor/CreateOrUpdateVendorApi";
	static _API_GET_VENDOR_API_SHOP_BY_API_ID = "/Vendor/GetVendorApiShopByApiId";
	static _API_AVAILABLE_API_META_SHOP_LIST = "/Vendor/GetAvailableApiShopList";
	static _API_ATTACH_VENDOR_API_META_SHOP = "/Vendor/AttachVendorApiMetaShop";
	static _API_AVAILABLE_API_META_AGENT_LIST = "/Vendor/GetAvailableAgentList";
	static _API_ATTACH_VENDOR_API_META_AGENT = "/Vendor/AttachVendorApiMetaToAgent";
	static _API_GET_VENDOR_API_AGENT_BY_API_ID = "/Vendor/GetVendorApiAgentByApiId";

	static _API_GET_SMS_SETTING_BY_ID = "/Configuration/GetSmsSettingById";
	static _API_GET_SMS_SETTING_TYPE = "/Configuration/GetSmsSettingType";
	static _API_GET_SMS_SETTING = "/Configuration/GetSmsSetting";
	static _API_GET_SYSTEM_SETTING = "/Configuration/GetSystemSetting";
	static _API_GET_SYSTEM_SETTING_BY_NAME = "/Configuration/GetSystemSettingByName";
	static _API_CREATE_OR_UPDATE_SYSTEM_SETTING = "/Configuration/CreateOrUpdateSystemSetting";
	static _API_CREATE_OR_UPDATE_SMS_SETTING = "/Configuration/CreateOrUpdateSmsSetting";
	static _API_GET_SMS_SETING_SHOP_BY_SMS_SETTING_ID = "/Configuration/GetAttachedShopBySmsSettingId";
	static _API_ATTACH_SMS_SETTING_SHOP = "/Configuration/AttachSmsSettingShop";
	static _API_GET_REGION = "/Configuration/GetRegion";
	static _API_GET_REGION_SETTING_BY_ID = "/Configuration/GetRegionSettingById";
	static _API_CREATE_OR_UPDATE_SYSTEM_MESSAGE = "/Configuration/CreateOrUpdateSystemMessage";
	static _API_GET_SYSTEM_MESSAGE = "/Configuration/GetSystemMessage";
	static _API_GET_SYSTEM_MESSAGE_BY_ID = "/Configuration/GetSystemMessageById";
	static _API_CREATE_OR_UPDATE_REGION_SETTING = "/Configuration/CreateOrUpdateRegionSetting";
	static _API_GET_ROLLOVER_TRANSACTION_TYPE = "/Configuration/GetRolloverTransactionType";

	static _API_GET_SHOP_CREDIT = "/Shop/GetShopCredit";
	static _API_SHOP_CREATE_PLAYER = "/Shop/CreatePlayer";
	static _API_SHOP_RESET_PASSWORD = "/Shop/ResetPlayerPassword";
	static _API_SHOP_SET_PLAYER_STATUS = "/Shop/SetPlayerStatus";
	static _API_SHOP_DELETE_PLAYER = "/Shop/DeletePlayer";
	static _API_SHOP_RECOVER_PLAYER = "/Shop/RecoverPlayer";
	static _API_SHOP_SET_SCORE = "/Shop/SetPlayerScore";
	static _API_GET_PLAYER_DETAIL = "/Shop/GetPlayerDetail";
	static _API_CLAIM_REWARD = "/Shop/ClaimReward";
	static _API_RELOAD_CREDIT = "/Shop/ReloadCredit";
	static _API_SHOP_BULK_SET_SCORE = "/Shop/BulkSetPlayerScore";
	static _API_GET_SHOP_ATTACHED_VENDOR_API = "/Shop/GetShopAttachedVendorApi";
	static _API_UPDATE_SHOP_LIMIT = "/Shop/UpdateShopLimit";
	static _API_REQUEST_ADJUST_SHOP_LIMIT = "/Shop/RequestAdjustShopLimit";
	static _API_APPROVE_OR_REJECT_SHOP_LIMIT_REQUEST = "/Shop/ApproveOrRejectSetShopLimitRequest";
	static _API_UPDATE_UPPER_SHOP_LIMIT = "/Shop/SetShopUpperLimit";
	static _API_GET_SCORE_LOG = "/Shop/GetScoreLog";
	static _API_GET_GAME_LOG = "/Shop/GetGameLog";
	static _API_REQUEST_APPEAL_TRANSACTION = "/Shop/RequestAppealTransaction";
	static _API_UPDATE_AGENT_CREDIT = "/Agent/UpdateAgentCredit";
	static _API_UPDATE_AGENT_CREDIT_LIMIT = "/Agent/UpdateAgentCreditLimit";
	static _API_GET_AGENT_LOTTO_PACKAGE = "/Agent/GetAgentLottoPackage";
	static _API_CREATE_OR_UPDATE_AGENT_LOTTO_PACKAGE = "/Agent/CreateOrUpdateAgentLottoPackage";
	static _API_GET_AGENT_VENDORS = "/Agent/GetAgentVendors";
	static _API_GET_AGENT_LIST = "/Agent/GetAgentList";

	static _API_DELETE_PRIZE = "/Promotion/DeletePrize";
	static _API_GET_PRIZE_TYPES = "/Promotion/GetPrizeTypes";
	static _API_CREATE_OR_UPDATE_PRIZE = "/Promotion/CreateOrUpdatePrize";
	static _API_GET_PROMOTIONS = "/Promotion/GetPromotions";
	static _API_CREATE_OR_UPDATE_PROMOTION = "/Promotion/CreateOrUpdatePromotion";

	static _API_GET_PROMOTION_DETAIL_BY_ID = "/Promotion/GetPromotionDetailById";
	static _API_BROADCAST_MESSAGE = "/Configuration/BroadcastAnnouncement";
	static _API_GENERATE_MEMBER_PRIZE = "/Promotion/GenerateMemberPrize";
	static _API_CREATE_OR_UPDATE_MEMBER_PRIZE = "/Promotion/CreateOrUpdateMemberPrize";
	static _API_BROADCAST_MEMBER_PRIZE = "/Promotion/BroadcastMemberPrize";
	static _API_GET_MEMBER_PRIZE = "/Promotion/GetMemberPrize";
	static _API_DELETE_MEMBER_PRIZE = "/Promotion/DeleteMemberPrize";
	static _API_GET_PROMOTION_STATUS = "/Promotion/GetPromotionBroadcastStatus";
	static _API_SET_MEMBER_PRIZE_STATUS = "/Shop/SetMemberPrizeStatus";
	static _API_SET_PROMOTION_STATUS = "/Promotion/SetPromotionStatus";
	static _API_PROMOTION_WINNING_DETAIL = "/Promotion/GetWinningDetail";
	static _API_GET_PRIZE_RANK = "/Promotion/GetPrizeRank";

	static _API_PREVIEW_ANNOUNCEMENT_TARGET = "/Configuration/PreviewAnnouncementTarget";
	static _API_GET_MINI_PROGRAM_LIST = "/Configuration/GetMiniProgramList";
	static _API_GET_MINI_PROGRAM_SHOP_BY_MINI_PROGRAM_ID = "/Configuration/GetAttachedShopByMiniProgramId";
	static _API_ATTACH_MINI_PROGRAM_SHOP = "/Configuration/AttachMiniProgramShop";
	static _API_GET_MINI_PROGRAM_BY_ID = "/Configuration/GetMiniProgramById";
	static _API_CREATE_OR_UPDATE_MINI_PROGRAM = "/Configuration/CreateOrUpdateMiniProgram";
	static _API_SET_ANNOUNCEMENT_STATUS = "/Configuration/SetAnnouncementStatus";
	static _API_GET_OPERATION_LOG = "/Configuration/GetOperationLog";
	static _API_GET_USER_LAST_MESSAGE = "/Configuration/GetUserLastMessage";
	static _API_GET_PAYMENT_GATEWAY = "/Configuration/GetPaymentGateway";
	static _API_GET_PAYMENT_GATEWAY_BY_ID = "/Configuration/GetPaymentGatewayById";
	static _API_CREATE_OR_UPDATE_PAYMENT_GATEWAY = "/Configuration/CreateOrUpdatePaymentGateway";
	static _API_GET_PAYMENT_GATEWAY_SHOPS_BY_ID = "/Configuration/GetPaymentGatewayShopById";
	static _API_ATTACH_PAYMENT_GATEWAY_SHOP = "/Configuration/AttachPaymentGatewayShop";
	static _API_AVAILABLE_PAYMENT_GATEWAY_SHOP_LIST = "/Configuration/GetAvailablePaymentGatewayShopList";
	static _API_CREATE_OR_UPDATE_IP_BLACKLIST = "/Configuration/CreateOrUpdateIPBlackList";
	static _API_GET_IP_BLACKLIST = "/Configuration/GetIPBlackList";
	static _API_GET_IP_BLACKLIST_BY_ID = "/Configuration/GetIPBlackListById";
	static _API_REMOVE_IP_BLACKLIST_BY_ID = "/Configuration/DeleteIPBlackListById";
	static _API_GET_LUCKY_NUMBERS = "/Configuration/GetLuckyNumbers";
	static _API_GET_VENDOR_ROLLOVER_TYPES = "/Configuration/GetVendorRolloverTypes";
	static _API_GET_APPEAL_TRANSACTION = "/Configuration/GetAppealTransaction";
	static _API_PROCESS_APPEAL_TRANSACTION = "/Configuration/ProcessAppealTransaction";

	static _API_GET_SET_SCORE_LIMIT_REPORT = "/Report/GetSetScoreLimitReport";
	static _API_GET_SET_SCORE_REPORT = "/Report/GetSetScoreReport";
	static _API_GET_AGENT_COMMISSION_REPORT = "/Report/GetAgentCommissionReport";
	static _API_GET_AGENT_COMMISSION_DETAIL_REPORT = "/Report/GetAgentCommissionDetailReport";
	static _API_GET_CLAIM_REWARD_REPORT = "/Report/GetClaimRewardReport";
	static _API_GET_MANAGER_LIMIT_REPORT = "/Report/GetManagerLimitReport";
	static _API_GET_SALES_REPORT = "/Report/GetSalesReport";
	static _API_GET_REWARD_SUMMARY_REPORT = "/Report/GetRewardSummaryReport";
	static _API_GET_REWARD_DETAIL_REPORT = "/Report/GetRewardDetailReport";
	static _API_GET_REWARD_RECORD_DATE_REPORT = "/Report/GetRewardRecordDateReport";
	static _API_GET_TURNOVER_REWARD_REPORT = "/Report/GetRewardReportV2";
	static _API_GET_TURNOVER_REWARD_DETAIL = "/reward-history-detail/{historyId}";
	static _API_GET_TURNOVER_REWARD_SUMMARY_REPORT = "/Report/GetRewardSummaryReportV2";

	static _API_GET_KIOSK_TIMEOUT_REPORT = "/Report/GetKioskTimeoutReport";
	static _API_GET_SHOP_MEMBER_DETAIL = "/Report/GetShopMemberDetail";
	static _API_GET_DAILY_TRANSACTION = "/Report/GetDailyTransaction";
	static _API_DISABLE_SET_LIMIT_DATA = "/Report/DisableSetLimitData";
	static _API_GET_TOPUP_TRANSACTION = "/Report/GetTopUpTransaction";
	static _API_GET_STRIKE_REPORT = "/Report/GetStrikeReport";
	static _API_CONFIRM_STRIKE = "/Shop/ConfirmStrike";
	static _API_UPDATE_BET_RESULT_HISTORY = "/Shop/UpdateBetResultHistory";
	static _API_GET_ROLLOVER_REPORT = "/Report/GetRolloverReport";
	static _API_GET_UPPER_LIMIT_WARNING_REPORT = "/Report/GetUpperLimitWarningReport";
	static _API_GET_CS_USED_LIMIT = "/Report/cs-used-limit";

	static _API_UPDATE_TOPUP_TRANSACTION = "/Transaction/UpdateTopupTransaction";
	static _API_VALIDATE_EWALLET_TRANSACTION = "/validate-ewallet-transaction";
	static _API_CREATE_EWALLET_TRANSACTION = "/create-ewallet-transaction";

	static _API_GET_SHOP_BY_REFERRAL_CODE = "/Account/GetShopUserByReferralCode";

	static _API_UPDATE_SHOP_REQUEST = "/Account/UpdateShopRequest";

	static _API_GET_CREDIT_TRANSFER_REPORT = "/Report/GetCreditTransferReport";

	static _API_GET_SHOP_LOGIN_REQUEST = "/Configuration/GetShopLoginRequest";
	static _API_UPDATE_SHOP_LOGIN_REQUEST = "/Configuration/UpdateShopLoginRequest";

	static _API_GET_SHOP_BY_VENDOR_ID = "/Shop/GetShopByVendorId";

	static _API_GET_STRIKE_DETAILS = "/Report/GetStrikeDetails";
	static _API_PERFORM_REWARD_CALCULATION = "/Account/PerformRewardCalculation";
	static _API_GET_INTERFACE_SHOP = "/Account/GetInterfaceShop";
	static _API_GET_SHOP_LIMIT_REQUEST_LOGS = "/Shop/ShopLimitRequestLogs";

	static _API_VENDOR_CATEGORY = "/Vendor/category";
	static _API_GET_SUPPORTED_LANGUAGE = "/Configuration/GetLanguages";
	static _API_ASSIGNED_VENDOR_CATEGORY = "/Vendor/assign-category";

	static _API_GET_RANK = "/Configuration/rank";
	static _API_CREATE_OR_UPDATE_RANK = "/Configuration/rank";
	static _API_RANK_VENDOR_RATE = "/Configuration/rank-vendor-rate";

	static _API_GET_NOTIFICATION_TYPE = "Configuration/notification-template-type";
	static _API_NOTIFICATION_TEMPLATE = "Configuration/notification-template";
	static _API_NOTIFICATION_ATTACHED_SHOP = "Configuration/template-shop";

	static _API_GET_USER_EXPERIENCE_HISTORY = "Account/experience-history";
	static _API_GET_MEMBER_DOWNLINE = "Account/downlines";

	static _API_ANNOUNCEMENT_EVENT = "announcement-events";
	static _API_GET_ANNOUNCEMENT_TYPE = "announcement-events/types";
	static _API_UPDATE_ANNOUNCEMENT_EVENT_STATUS = "announcement-events/{id}/status";
	static _API_UPDATE_ANNOUNCEMENT_EVENT_TYPE_STATUS = "announcement-events/types/batch-status";
	static _API_GET_TURNOVER_REPORT = "/Report/GetTurnoverReport";

	static _API_EVENT = "/Event";
	static _API_EVENT_PRIZE = "/Event/Prize";
	static _API_EVENT_PRIZE_TYPE = "/Event/PrizeType";
	static _API_COPY_EVENT = "/copy-event";

	static _API_FEEDBACK_CATEGORY = "/Feedback/Category";
	static _API_FEEDBACK_OPTION = "/Feedback/Option";
	static _API_MEMBER_FEEDBACK = "/Feedback/member-feedback";
	static _API_SHOP_FEEDBACK = "/Feedback/shop-feedback";
	static _API_FEEDBACK_REPLY = "/Feedback/reply";

	static _API_BANNER = "Configuration/banner";

	static _API_GET_KOG_RANKING = "Kog";
	static _API_GET_KOG_DETAIL = "Kog/detail";
	static _API_QUALIFY_KOG_DETAIL = "Kog/qualification";
	static _API_KOG_PRIZE_OWNER = "Kog/event-prize-owner";
	static _API_SEARCH_EVENT_PRIZE_WINNER = "Kog/event-winner-search";
	static _API_DISTRIBUTE_LUCKY_PRIZE = "Kog/distribute-lucky-prize";

	static _API_GET_ECREDIT_LOG = "/Report/GetTransferCreditRegionalReport";

	static _API_GET_PROMOTION_DETAILS_COUNT = "/Promotion/GetPromotionDetailCount";

	static _API_GET_USER_TRANSACTION_LOG = "/Report/GetOverallTransaction";
	static _API_GET_MEMBER_ROLLOVER_SUMMARY = "/file/member-turnovers";
	static _API_GET_MEMBER_ROLLOVER_DETAIL = "/Account/turnover-detail";

	static _API_GET_OVERALL_DOWNLINE = "/Report/GetOverallDownline";
	static _API_GET_RANK_SUMMARY = "/Report/GetRankSummary";
	static _API_DISTRIBUTE_PRIZE = "kog/bulk-distribute-reward";
	static _API_SINGLE_DISTRIBUTE = "kog/claim-prize";

	static _API_GET_API_LOG = "/Report/GetApiLog";
	static _API_GET_AGENT_NETPROFIT_REPORT = "/Report/GetNetProfitReport";
	static _API_GET_AGENT_WINLOSS_REPORT = "/Report/GetAgentWinLoss";
	static _API_GET_AGENT_WINLOSS_REPORT_2 = "/Report/GetAgentWinLossV2";
	static _API_GET_AGENT_WINLOSS_REPORT_DETAIL = "/Report/GetAgentWinLossDetail";

	static _API_KOG_BOT_MEMBER = "/Kog/event-bot-member";
	static _API_GET_LOTTO_SEAMLESS_SETTLEMENT_REPORT = "/Report/GetLottoSeamlessSettlementReport";

	static _API_GET_ONLINE_WITHDRAWAL_REQUEST = "/Transaction/online-withdrawal";
	static _API_PLAYER_SET_SCORE = "/player/playerSetScore";

	static _API_GET_PAYMENT_GATEWAY_TELCO = "/Configuration/payment-gateway-rate-type";
	static _API_UPDATE_MANAGER_LIMIT = "/manager/update-limit";
	static _API_UPDATE_MANAGER_THRESHOLD = "/manager/update-threshold";
	static _API_GET_MANAGER_LIMIT_TRANSACTION_REPORT = "/manager/limit-transactions";
	static _API_GET_SHOP_ATTACHED_VENDOR = "/Shop/GetShopAttachedAppVendorApi";
	static _API_GET_SHOP_CREATED_PLAYER = "/Shop/GetShopPlayers";

	static _API_FETCH_LOBBY_GAME_LIST = "/vendor/:vendorId/games/fetch";
	static _API_LOBBY_GAME = "/vendor/games";
	static _API_UPLOAD_GAME_LIST_FILE = "/vendor/games/upload";
	static _API_RESYNC_HIGH_BONUS_REWARD = "/vendor/resync-high-bonus-reward";

	static _API_GET_THIRD_PARTY = "/Configuration/third-party-program";
	static _API_GET_THIRD_PARTY_API = "/Configuration/third-party-program/api";
	static _API_ATTACH_THIRD_PARTY_API_SHOP = "/Configuration/third-party-program/api/attach";
	static _API_ATTACHED_THIRD_PARTY_API_SHOP_LIST = "/Configuration/third-party-program/shop";
	static _API_AVAILABLE_THIRD_PARTY_API_SHOP_LIST = "/Configuration/third-party-program/available-shop";

	static _API_CLAIM_LUCKY_SPIN_PRIZE = "/Promotion/ClaimLuckySpinPrize";

	static _API_GET_BONUSES = "/Configuration/bonuses";
	static _API_CREATE_OR_UPDATE_BONUSES = "/Configuration/bonus";
	static _API_BONUS_UNLOCK_CHECK = "/Account/bonus-unlock";
	static _API_BONUS_ATTACH_SHOP = "/Configuration/bonus/{id}/assign";
	static _API_BONUS_ATTACH_SHOP = "/Configuration/bonus/{id}/assign";
	static _API_BONUS_AVAILABLE_SHOPS = "/Configuration/bonus/{id}/available-shops";
	static _API_BONUS_SHOPS = "/Configuration/bonus/{id}/shops";
	static _API_GET_WELCOME_BONUS_DETAIL = "/Configuration/welcome-bonus-summary";

	static _API_GET_MEMBER_NOTES = "/Shop/GetMemberNotes";
	static _API_SAVE_MEMBER_NOTES = "/Shop/CreateOrUpdateMemberNotes";
	static _API_SHOP_UPDATE_USER_STATUS = "/Shop/ShopUpdateUserStatus";

	static _API_GET_USER_GROWTH_REPORT = "/Report/GetUserGrowthReport";
	static _API_GET_USER_GROWTH_MEMBER = "/Report/GetUserGrowthMember";
}

/// <summary>
/// Author : Yong Sheng Chuan
/// Url to navigate to pages
/// </summary>
export class WebUrl {
	static _URL_MAIN = "/";
	static _URL_LOGIN = "/login";
	static _URL_DASHBOARD = "/dashboard";
	static _URL_ADMIN_MANAGE_VENDOR = "/admin/manageVendor";
	static _URL_ADMIN_VENDOR_DETAIL = "/admin/vendorDetails";
	static _URL_ADMIN_MANAGE_VENDOR_API = "/admin/manageVendorApi";
	static _URL_ADMIN_VENDOR_API_DETAIL = "/admin/vendorApiDetails";

	static _URL_SHOP_RELOAD = "/shop/reload";
	static _URL_SHOP_CLAIM_BONUS = "/shop/claimBonus";
	static _URL_SHOP_SEARCH = "/shop/search";
	static _URL_SHOP_BLOCK_PUK = "/shop/blockPuk";
	static _URL_SHOP_MEMBER_DETAILS = "/shop/memberDetails";
	static _URL_SHOP_REPORT = "/shop/report";
	static _URL_SHOP_PLAYER_RELOAD = "/shop/playerReload";
	static _URL_SHOP_TRANSACTION_REQUEST = "/shop/transactionRequest";

	static _URL_SYSTEM_SETTINGS = "/systemSettings";
	static _URL_SMS_SETTINGS = "/smsSettings";
	static _URL_SMS_DETAILS = "/smsSetails";

	static _URL_SHOP_REGISTER_MEMBER = "/shop/registerMember";
	static _URL_SHOP_REGISTER_PLAYER = "/shop/registerPlayer";
	static _URL_SHOP_AGENT_COMMISSION_REPORT = "/shop/agentCommissionReport";
	static _URL_SHOP_CREDIT_TOPUP_REPORT = "/shop/creditTopupReport";
	static _URL_SHOP_CREDIT_TRANSFER_REPORT = "/shop/creditTransferReport";

	static _URL_ADMIN_MANAGE_ROLE = "/admin/manageRole";
	static _URL_ADMIN_ROLE_DETAIL = "/admin/roleDetail";

	static _URL_ADMIN_MANAGE_ADMIN = "/admin/manageAdmin";
	static _URL_ADMIN_ADMIN_DETAIL = "/admin/adminDetail";
	static _URL_ADMIN_MANAGE_SHOP = "/admin/manageShops";
	static _URL_ADMIN_MANAGE_AGENT = "/admin/manageAgents";
	static _URL_ADMIN_CREATE_OR_UPDATE_AGENT = "/admin/AgentDetail";
	static _URL_ADMIN_AGENT_VENDOR_API_DETAILS = "/admin/Agent/VendorApiDetails";
	static _URL_ADMIN_MANAGE_CUSTOMER_SERVICE = "/admin/manageCustomerService";
	static _URL_ADMIN_MANAGE_SHOP_API = "/admin/manageShopApis";
	static _URL_ADMIN_CREATE_OR_UPDATE_SHOP = "/admin/ShopDetail";
	static _URL_ADMIN_MANAGE_MEMBER = "/admin/manageMember";
	static _URL_ADMIN_CREATE_OR_UPDATE_MEMBER = "/admin/MemberDetail";
	static _URL_ADMIN_MANAGE_MEMBER_PLAYER = "/admin/ManageMemberPlayer";
	static _URL_ADMIN_CREATE_OR_UPDATE_CUSTOMER_SERVICE = "/admin/CustomerServiceDetail";

	static _URL_ADMIN_MANAGE_SHOP_MESSAGE = "/admin/manageShopMessage";
	static _URL_ADMIN_SHOP_MESSAGE_DETAIL = "/admin/shopMessageDetail";
	static _URL_ADMIN_MANAGE_SYSTEM_MESSAGE = "/admin/manageSystemMessage";
	static _URL_ADMIN_SYSTEM_MESSAGE_DETAIL = "/admin/systemMessageDetail";
	static _URL_ADMIN_MANAGE_PHONE_MESSAGE = "/admin/managePhoneMessage";
	static _URL_ADMIN_PHONE_MESSAGE_DETAIL = "/admin/phoneMessageDetail";
	static _URL_ADMIN_VIEW_ANNOUNCEMENT = "/admin/viewAnnouncement";

	static _URL_ADMIN_MANAGE_MANAGER = "/admin/manageManager";
	static _URL_ADMIN_MANAGER_DETAIL = "/admin/managerDetails";

	static _URL_ADMIN_OPERATION_LOG = "/admin/operationLog";
	static _URL_ADMIN_MANAGE_PLAYER = "/admin/managePlayer";
	static _URL_ADMIN_PLAYER_DETAIL = "/admin/playerDetails";
	static _URL_PROMOTION_MANAGE_SPIN_PROMOTION = "/promotion/manageLuckySpin";
	static _URL_PROMOTION_MANAGE_SPIN_PROMOTION_DETAIL = "/promotion/LuckySpinDetail";

	static _URL_MANAGE_LUCKY_NUMBER = "/admin/manageLuckyNumber";
	static _URL_ADMIN_MANAGE_MINI_PROGRAM = "/admin/manageMiniProgram";
	static _URL_ADMIN_MINI_PROGRAM_DETAIL = "/admin/miniProgramDetail";
	static _URL_ADMIN_MANAGE_PAYMENT_GATEWAY = "/admin/managePaymentGateway";
	static _URL_ADMIN_CREATE_OR_UPDATE_PAYMENT_GATEWAY = "/admin/paymentGatewayDetail";
	static _URL_PROMOTION_MANAGE_PROMOTION = "/promotion/manageBonuns";
	static _URL_PROMOTION_MANAGE_PROMOTION_DETAIL = "/promotion/manageBonusDetail";

	static _URL_ADMIN_MANAGE_IP_BLACKLIST = "/admin/manageIPBlackList";
	static _URL_ADMIN_MANAGE_SHOP_LOGIN = "/manageShopLogin";

	static _URL_SET_LIMIT_REPORT = "/setLimitReport";
	static _URL_SET_SCORE_REPORT = "/setScoreReport";
	static _URL_SALES_REPORT = "/salesReport";
	static _URL_REWARD_REPORT = "/rewardReport";
	static _URL_TURNOVER_REWARD_REPORT = "/turnoverRewardReport";
	static _URL_CLAIM_REWARD_REPORT = "/claimRewardReport";
	static _URL_MANAGER_LIMIT_REPORT = "/managerLimitReport";
	static _URL_KIOSK_TIMEOUT_REPORT = "/kioskTimeoutReport";
	static _URL_AGENT_COMMISSION_REPORT = "/agentCommissionReport";
	static _URL_AGENT_NETPROFIT_REPORT = "/agentNetProfitReport";
	static _URL_SHOP_VIEW_ANNOUNCEMENT = "/viewShopAnnouncement";
	static _URL_TOPUP_REPORT = "/topUpReport";
	static _URL_CREDIT_TRANSFER_REPORT = "/creditTransferReport";
	static _URL_STRIKE_REPORT = "/strikeReport";
	static _URL_MANAGE_APPEAL_TRANSACTION = "/appealTransaction";
	static _URL_MANAGE_ROLLOVER_REPORT = "/admin/manageRolloverReport";
	static _URL_MANAGE_UPPER_LIMIT_WARNING_REPORT = "/admin/manageUpperLimitWinningReport";
	static _URL_SHOP_LIMIT_APPROVAL_REPORT = "/shopLimitApprovalReport";

	static _URL_ADMIN_MANAGE_VENDOR_CATEGORY = "/admin/manageVendorCategory";
	static _URL_ADMIN_VENDOR_CATEGORY_DETAIL = "/admin/vendorCategoryDetails";

	static _URL_ADMIN_MANAGE_RANK = "/admin/rank";
	static _URL_ADMIN_RANK_DETAIL = "/admin/rankDetails";

	static _URL_NOTIFICATION_SETTINGS = "/notificationSettings";
	static _URL_NOTIFICATION_DETAILS = "/notificationDetails";

	static _URL_USER_EXPERIENCE_REPORT = "/userExperienceReport";
	static _URL_ADMIN_MANAGE_MEMBER_DOWNLINE = "/manageMemberDownline";

	static _URL_ADMIN_MANAGE_APP_ANNOUNCEMENT = "/manageAppAnnouncement";
	static _URL_ADMIN_MANAGE_APP_ANNOUNCEMENT_DETAIL = "/appAnnouncementDetail";

	static _URL_ADMIN_MANAGE_KOG_EVENT = "/manageKOGEvent";
	static _URL_ADMIN_MANAGE_KOG_EVENT_DETAIL = "/kogEventDetail";
	static _URL_ADMIN_KOG_EVENT_RANKING = "/kogEventRanking";

	static _URL_TURNOVER_REPORT = "/admin/turnoverReport";

	static _URL_FEEDBACK_SETTINGS = "/admin/feedbackSettings";
	static _URL_MEMBER_FEEDBACK_REPORT = "/admin/memberFeedbackReport";
	static _URL_SHOP_FEEDBACK_REPORT = "/admin/shopFeedbackReport";

	static _URL_ADMIN_MANAGE_BANNER = "/manageBanner";
	static _URL_ADMIN_MANAGE_BANNER_DETAIL = "/manageBannerDetail";

	static _URL_MEMBER_ECREDIT_LOG = "/memberEcreditTransferLog";
	static _URL_REGION_ECREDIT_LOG = "/regionEcreditTransferLog";

	static _URL_VIEW_USER_ACTION_LOG = "/viewUserActionLog";
	static _URL_VIEW_USER_TRANSACTION_LOG = "/viewUserTransactionLog";
	static _URL_LUCKY_SPIN_WINNER = "/lucky-spin-winner";

	static _URL_ADMIN_OVERALL_DOWNLINE_REPORT = "/overallDownlineReport";
	static _URL_ADMIN_RANK_SUMMARY_REPORT = "/rankSummary";

	static _URL_API_LOG = "/api-log";
	static _URL_AGENT_WINLOSS_REPORT = "/agentWinlossReport";
	static _URL_AGENT_WINLOSS_REPORT_2 = "/agentWinlossReport2";
	static _URL_LOTTO_SEAMLESS_SETTLEMENT_REPORT = "/lottoSeamlessSettlementReport";
	static _URL_ADMIN_KOG_VIP_MEMBER = "/kogManageVipUser";

	static _URL_ONLINE_WITHDRAWAL_REQUEST = "/online-withdrawal-request";
	static _URL_MANAGER_LIMIT_TRANSACTION_REPORT = "/manager-limit-transaction-report";

	static _URL_SHOP_PLAYER_CREATION_REPORT = "/shop/player-creation-report";

	static _URL_MANAGE_THIRD_PARTY = "/admin/third-party/management";
	static _URL_THIRD_PARTY_DETAIL = "/admin/third-party/detail";
	static _URL_MANAGE_THIRD_PARTY_API = "/admin/third-party-api/management";
	static _URL_THIRD_PARTY_API_DETAIL = "/admin/third-party-api/detail";
	static _URL_MANAGE_WELCOME_BONUS = "/admin/configuration/manage-welcome-bonus";
	static _URL_USER_GROWTH_REPORT = "/admin/user-growth-report";
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export class Status {
	static _ENABLED = 1;
	static _DISABLED = 0;
	static _DELETED = 2;
}

/// <summary>
/// Author : Yong Sheng Chuan
/// this value needed to be tally to value in server, do not change recklessly
/// </summary>
export class AccessRight {
	static _ADMIN_SUB_ACCOUNT_CONTROL_PERMISSION = "Admin Sub Account Control";
	static _MEMBER_CONTROL_PERMISSION = "Member Control";
	static _PLAYER_CONTROL_PERMISSION = "Player Control";
	static _CUSTOMER_SERVICE_CONTROL_PERMISSION = "Customer Service Control";
	static _SHOP_CONTROL_PERMISSION = "Shop Control";
	static _MANAGER_CONTROL_PERMISSION = "Manager Control";
	static _GAME_VENDOR_CONTROL_PERMISSION = "Game Vendor Control";
	static _ANNOUNCEMENT_CONTROL_PERMISSION = "Announcement Control";
	static _PROMOTION_CONTROL_PERMISSION = "Promotion Control";
	static _SYSTEM_SETTING_CONTROL_PERMISSION = "System Setting Control";
	static _SMS_SETTING_CONTROL_PERMISSION = "Sms Setting Control";
	static _SET_SCORE_REPORT_PERMISSION = "Set Score Report";
	static _AGENT_COMM_REPORT_PERMISSION = "Agent Comm Report";
	static _SET_LIMIT_REPORT_PERMISSION = "Set Limit Report";
	static _REWARD_REPORT_PERMISSION = "Reward Report";
	static _SALES_REPORT_PERMISSION = "Sales Report";
	static _MANAGER_LIMIT_REPORT_PERMISSION = "Manager Limit Report";
	static _KIOSK_TIMEOUT_REPORT_PERMISSION = "Kiosk Timeout Report";
	static _TOP_UP_REPORT_PERMISSION = "Top Up Report";
	static _STRIKE_REPORT_PERMISSION = "Strike Report";
	static _MINI_PROGRAM_CONTROL_PERMISSION = "Mini Program Control";
	static _ACCESS_LEVEL_CONTROL_PERMISSION = "Access Level Control";
	static _OPERATIONAL_LOG_CONTROL_PERMISSION = "Operational Log Control";
	static _DASHBOARD_CONTROL_PERMISSION = "Dashboard Control";
	static _LUCKY_NUMBER_CONTROL_PERMISSION = "Lucky Number Control";
	static _REWARD_CALCULATION_CONTROL_PERMISSION = "Reward Calculation Control";
	static _READ_PERMISSION_STRING = "(READ)";
	static _WRITE_PERMISSION_STRING = "(WRITE)";
	static _READ_BIT_POSITION = 1;
	static _WRITE_BIT_POSITION = 0;
	static _CREDIT_TRANSFER_REPORT_PERMISSION = "Credit Transfer Report";
	static _SHOP_LOGIN_CONTROL_PERMISSION = "Shop Login Control";
	static _APPEAL_TRANSACTION_CONTROL_PERMISSION = "Appeal Transaction Control";
	static _ROLLOVER_REPORT_CONTROL_PERMISSION = "Rollover Report Control";
	static _UPPER_LIMIT_REPORT_CONTROL_PERMISSION = "Upper Limit Report Control";
	static _SET_SHOP_LIMIT_APPROVAL_CONTROL_PERMISSION = "Set Shop Limit Approval Control";
	static _RANK_CONTROL_PERMISSION = "Rank Control";
	static _ANNOUNCEMENT_EVENT_CONTROL = "Announcement Event Control";
	static _EVENT_CONTROL_PERMISSION = "Event Control";
	static _TURNOVER_REPORT_CONTROL_PERMISSION = "Turnover Report Control";
	static _FEEDBACK_CONTROL_PERMISSION = "Feedback Control";
	static _BANNER_CONTROL_PERMISSION = "Banner Control";
	static _OVERALL_DOWNLINE_REPORT_PERMISSION = "Overall Downline Report";
	static _RANK_SUMMARY_REPORT_PERMISSION = "Rank Summary Report";
	static _AGENT_CONTROL_PERMISSION = "Agent Control";
	static _AGENT_NETPROFIT_PERMISSION = "Agent Net Profit Report";
	static _ONLINE_WITHDRAWAL_PERMISSION = "Online Withdrawal";
	static _REVERT_ONLINE_WITHDRAWAL_PERMISSION = "Revert Online Withdrawal";
	static _MANAGER_LIMIT_CONTROL_PERMISSION = "Manager Limit Control";
	static _USER_GROWTH_REPORT_PERMISSION = "User Growth Report";
}

/// <summary>
/// Author : CK
/// </summary>
export class SearchMemberAction {
	static _RELOAD_ACTION = "RELOAD_ACTION";
	static _BLOCK_DELETE_ACTION = "BLOCK_DELETE_ACTION";
}

/// <summary>
/// Author : CK
/// </summary>
export class Language {
	static _CHINESE = "cn";
	static _ENGLISH = "en";
	static _BAHASA_MELAYU = "bm";
}

/// <summary>
/// Author : CK
/// </summary>
export class SessionKey {
	static _LANGUAGE = "language";
	static _LOGIN_GUID = "loginGuid";
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export class Role {
	static _SUPER_ADMIN = 1;
	static _SHOP = 2;
	static _MANAGER = 3;
	static _MEMBER = 4;
	static _ADMIN_SUB_ACCOUNT = 0;
	static _AGENT = 20;
}

/// <summary>
/// Author: CK
/// </summary>
export class InputValidationKey {
	static _REQUIRED_KEY = "required";
	static _MAX_LENGTH_KEY = "maxLength";
	static _MIN_LENGTH_KEY = "minLength";
	static _MIN_KEY = "min";
	static _MAX_KEY = "max";
	static _PATTERN = "pattern";
}

/// <summary>
/// Author: Chris
/// </summary>
export class AlertTypes {
	static _DEFAULT = "default";
	static _INFO = "info";
	static _SUCCESS = "success";
	static _WARNING = "warning";
	static _DANGER = "danger";
	static _ERROR = "error";
	static _INPUT = "input";
	static _CUSTOM = "custom";
}

/// <summary>
/// Author: Chris
/// </summary>
export class FilterStatus {
	static _ACTIVE = 1;
	static _DELETED = 2;
	static _SUSPENDED = 0;
}

/// <summary>
/// Author: Chris
/// </summary>
export class ShopSettings {
	static _MEMBER = "member";
	static _PLAYER = "player";
	static _RELOAD = "reload";
}

/// <summary>
/// Author: Wind
/// this is match db value, do not change
/// </summary>
export class SettingType {
	static _SETTING_TYPE_GENERAL = 1;
	static _SETTING_TYPE_BROADCAST = 2;
	static _SETTING_TYPE_REWARD_ROLLOVER_FACTOR = 3;
	static _SETTING_TYPE_MEMBER_SETTING = 4;
	static _SETTING_TYPE_SMS = 5;
}

/// <summary>
/// Author: YJ
/// this is match db value, do not change
/// </summary>
export class AnnouncementType {
	static _ANNOUNCEMENT_TYPE_WEB = 1;
	static _ANNOUNCEMENT_TYPE_SHOP = 2;
	static _ANNOUNCEMENT_TYPE_PHONE = 3;
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export class LoginType {
	static _LOGIN_TYPE_SHOP = 2;
	static _LOGIN_TYPE_ADMIN = 1;
}

/// <summary>
/// Author: YJ
/// </summary>
export class ProcessedAnnouncementStatus {
	static _PROCESSED_ANNOUNCEMENT_DRAFT = 1;
	static _PROCESSED_ANNOUNCEMENT_CANCELLED = 2;
	static _PROCESSED_ANNOUNCEMENT_IN_PROGRESS = 3;
	static _PROCESSED_ANNOUNCEMENT_COMPLETED = 4;
	static _PROCESSED_ANNOUNCEMENT_BROADCAST_CANCELLED = 5;
}

/// <summary>
/// Author: Nelson
/// </summary>
export class PrizeType {
	static _PRIZE_TYPE_REBATE = 1;
	static _PRIZE_TYPE_CASH = 2;
	static _PRIZE_TYPE_REWARD = 3;
	static _PRIZE_TYPE_PRIZE = 4;
}

/// <summary>
/// Author: CK
/// this is match db value, do not change
/// </summary>
export class SystemSettingName {
	static _CHANGE_MEMBER_CARD_ID_PENALTY_COUNT = "changeMemberCardIdPenaltyCount";
	static _CHANGE_MEMBER_CARD_ID_PENALTY = "changeMemberCardIdPenalty";
}

/// <summary>
/// Author: CK
/// this is match db value, do not change
/// </summary>
export class TransactionType {
	static _BONUS_CLAIM = "BONUS_CLAIM";
	static _REWARD_CLAIM = "REWARD_CLAIM";
	static _SET_LIMIT = "SET_LIMIT";
	static _SET_SCORE = "SET_SCORE";
	static _WALLET_TRANSFER = "WALLET_TRANSFER";
	static _TOP_UP_GPAY = "TOPUP_GPAY";
	static _TOP_UP_GPAY_DUITNOW = "TOPUP_GPAY_DUITNOW";
	static _TOP_UP_EGHL = "TOPUP_EGHL";
	static _TOP_UP_E_WALLET = "TOPUP_MXPAY_EWALLET";
	static _TOPUP_GPAY_DUITNOW = "TOPUP_GPAY_DUITNOW";
	static _SEAMLESS_SET_CREDIT = "SEAMLESS_SET_CREDIT";
	static _ONLINE_WITHDRAWAL = "ONLINE_WITHDRAWAL";
	static _VENDING_MACHINE = "VENDING_MACHINE";
	static _MOBILE_PIN_TOPUP = "WIN_PAY";
	static _KINGPAY_DUITNOW = "KINGPAY_DUITNOW";
	static _PASSWORD_RESET = "PASSWORD_RESET";
	static _REGISTER_BONUS = "REGISTER_BONUS";
}

/// <summary>
/// Author: YJ
/// this is match db value, do not change
/// </summary>
export class RegionId {
	static _AGENT = 1;
	static _EAST = 2;
	static _ALL = 3;
	static _MIDDLE = 4;
	static _NORTH = 5;
	static _SOUTH = 6;
}

/// <summary>
/// Author: YJ
/// this is match db value, do not change
/// </summary>
export class TopUpStatus {
	static _PENDING = 0;
	static _SUCCESS = 1;
	static _FAILED = 2;
	static _PENDING_APPROVAL = 3;
}

/// <summary>
/// Author: Nelson
/// </summary>
export class CommonStatus {
	static _ALL_STATUS = -1;
	static _PENDING_STATUS = 0;
	static _APPROVE_STATUS = 1;
	static _REJECT_STATUS = 2;
	static _INACTIVE_STATUS = 3;
}

/// <summary>
/// Author: YJ
/// dont simply change, it is match db record
/// </summary>
export class TransactionTypeId {
	static _BONUS_CLAIM = 1;
	static _REWARD_CLAIM = 2;
	static _SET_LIMIT = 3;
	static _SET_SCORE = 4;
	static _WALLET_TRANSFER = 5;
	static _TOP_UP_GPAY = 6;
	static _TOP_UP_EGHL = 7;
	static _TOP_UP_MXPAY_EWALLET = 8;
	static _TOP_UP_GPAY_DUITNOW = 9;
	static _SEAMLESS_SET_CREDIT = 10;
}

/// <summary>
/// Author: YJ
/// </summary>
export class CustomResponseCode {
	static _ROLLOVER_APPEAL_REQUIRED = 222;
}

/// <summary>
/// Author: YJ
/// </summary>
export class Theme {
	static _EPAY = "ePay";
	static _OREN = "Oren";
	static _MANTAP = "Mantap";
}

/// <summary>
/// Author: YJ
/// </summary>
export class TransactionRequestStatus {
	static _PENDING = 0;
	static _SUCCESS = 1;
	static _REJECTED = 2;
	static _EXPIRED = 3;
	static _PENDING_PASSWORD_CONFIRMATION = 4;
}

/// <summary>
/// Author: YJ
/// </summary>
export class FirebaseKey {
	static _TRANSACTION_REQUEST = "TransactionRequest-";
}

/// <summary>
/// Author: CK
/// </summary>
export class UPPER_LIMIT_WARNING_LEVEL {
	static _NONE = 1;
	static _REACH_LIMIT = 2;
	static _EXCEED_LIMIT = 3;
	static _BELOW_LIMIT = 4;
	static _REACH_ZERO = 5;
	static _ALL_HIT_LIMIT = -1;
}

/// <summary>
/// Author: Wind
/// </summary>
export class NotificationTag {
	static _NOTIFICATION_TEMPLATE_TAG_EXPERIENCE = "experience";
	static _NOTIFICATION_TEMPLATE_TAG_INACTIVE_PERIOD = "period";
	static _NOTIFICATION_TEMPLATE_TAG_RANK = "rank";
	static _NOTIFICATION_TEMPLATE_TAG_RANK_IMAGE = "rankImage";
}

/// <summary>
/// Author: Wind
/// </summary>
export class AnnouncementEventTypeId {
	static _WITHDRAW = 1;
	static _WITHDRAW_WITH_FAKE = 2;
	static _DEPOSIT_WITH_FAKE = 3;
	static _LUCKY_SPIN = 4;
	static _LUCKY_SPIN_WITH_FAKE = 5;
	static _USER_DEFINED = 6;
}

/// <summary>
/// Author: Wind
/// </summary>
export class AnnouncementTag {
	static _USERNAME = "username";
	static _PRICE_NAME = "priceName";
	static _AMOUNT = "amount";
}

/// <summary>
/// Author : CK
/// </summary>
export class ValidationPattern {
	static _EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	static _PHONE_NUMBER_REGEX = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
	static _NUMBER_REGEX = /^([1-9]|[1-9][0-9]|[1-9][0-9]*)$/;
	static _DECIMAL_REGEX = /^[1-9][0-9]*(\.\d{1,2})?$|^[0](\.\d{1,2})?$/;
	static _DIGIT_REGEX = /^\d+$/;
	static _ALPHABETIC_REGEX = /^[a-zA-Z ]+$/;
	static _STANDARD_REGEX = /^[A-Za-z0-9!@#$%^&*()_+}{":?><';,.~ /`|\\]*$/;
	static _IC_NUMBER_REGEX = /^(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))([0-9]{2})([0-9]{4})$/;
	static _PASSPORT_NUMBER_REGEX = /^[A-Za-z]{1,2}[0-9]{6,9}[A-Za-z]{0,1}$/;
	static _ALPHANUMBERIC_REGEX = /^[0-9a-zA-Z ]+$/;
}

/// <summary>
/// Author: Wind
/// </summary>
export class EventStatus {
	static _PAUSE = 0;
	static _START = 1;
	static _PENDING = 2;
	static _END = 3;
}

/// <summary>
/// Author: CK
/// </summary>
export class OverallTransactionTypeId {
	static _ONLINE_BANKING = 1;
	static _SHOP_SET_SCORE = 2;
	static _SHOP_SET_CREDIT = 3;
	static _SEND_CREDIT = 4;
	static _CONVERT_TO_CREDIT = 5;
	static _CONVERT_TO_SCORE = 6;
	static _TRANSFER_SCORE = 7;
	static _REWARD_TO_CREDIT = 8;
	static _EGHL = 9;
	static _E_WALLET_MXPAY = 10;
	static _DUITNOW = 11;
	static _PASSWORD_RESET= 12;
}

/// <summary>
/// Author: CK
/// </summary>
export class PaymentGatewayType {
	static _ONLINE_BANKING_GPAY = 1;
	static _E_WALLET_MXPAY = 2;
	static _DUITNOW_GPAY = 3;
	static _VENDING_MACHINE = 4;
	static _WINPAY = 5;
	static _KINGPAY_DUITNOW = 6;
}

/// <summary>
/// Author : A
/// </summary>
export class PackageType {
	static _NORMAL = 0;
	static _GD = 1;
}

/// <summary>
/// Author : A
/// </summary>
export class PackagePayRateType {
	static _BASE = 0;
	static _CUSTOM = 1;
}

/// <summary>
/// Author : A
/// </summary>
export class ReportType {
	static _ALL = -1;
	static _SHOP = Role._SHOP;
	static _AGENT = Role._AGENT;
}

/// <summary>
/// Author : A
/// </summary>
export class BannerType {
	static _KOG = { key: 0, value: "KOG" };
	static _HOME = { key: 1, value: "HOME" };
}

/// <summary>
/// Author : Wind
/// </summary>
export const _PRINT_DELIMITER = "|";

/// <summary>
/// Author : Wind
/// </summary>
export class OnlineWithdrawalStatus {
	static _SYSTEM_REJECTED = -2;
	static _REJECTED = -1;
	static _PENDING = 0;
	static _PENDING_APPROVAL = 1;
	static _SUCCESS = 2;
}

/// <summary>
/// Author : Wind
/// </summary>
export class ManagerTransactionType {
	static _MANAGER_LIMIT = 1;
	static _SHOP_LIMIT = 2;
}

/// <summary>
/// Author : YJ
/// </summary>
export class EventCalculationMethod {
	static _NORMAL = 1;
	static _WIN_DIVIDE_BET = 2;
}

export const _SYSTEM_SUPPORT_ROLE_NAME = "System Support";

/// <summary>
/// Author : CK
/// </summary>
export class ViewDataType {
	static _ALL = 0;
	static _JACKPOT = 1;
	static _BONUS = 2;
}