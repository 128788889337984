import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, WebUrl, AlertTypes, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, arrayGroupByKey } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import Select from "react-select";
import { setBusy, setIdle, showResponseMessage, showMessage } from '../../../redux/AppAction';
import { Collapse } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";

/// <summary>
/// Author : CK
/// </summary>
const TurnoverRewardReport = props => {
    let _dispatch = useDispatch();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [summaryApiUrl, setSummaryApiUrl] = useState('');
    const [selectedShop, setSelectedShop] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [detailData, setDetailData] = useState([]);
    const [showSummary, setShowSummary] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [phone, setPhone] = useState('');
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [postData, setPostData] = useState({});
    const [summaryPostData, setSummaryPostData] = useState({});
    const [recordDateSummary, setRecordDateSummary] = useState([]);
    const [showRecordDateSummary, setShowRecordDateSummary] = useState(false);
    const [vendorOption, setVendorOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const RewardReportType = [
        { value: -1, label: t("ALL") },
        { value: 1, label: t("REWARD_HISTORY_TYPE_TURNOVER") },
        { value: 2, label: t("REWARD_HISTORY_TYPE_HIGH_PAYOUT") },
        { value: 3, label: t("REWARD_HISTORY_TYPE_HIGH_BONUS") },
        { value: 4, label: t("REWARD_HISTORY_TYPE_DOWNLINE_COMMISSION") },
        { value: 5, label: t("REWARD_HISTORY_TYPE_SPIN") },
        { value: 6, label: t("REWARD_HISTORY_TYPE_KOG") },
        { value: 7, label: t("REWARD_HISTORY_TYPE_ANG_POW") },
        { value: 8, label: t("REWARD_HISTORY_TYPE_WELCOME_BONUS") }
    ]
    const [selectedRewardType, setSelectedRewardType] = useState(RewardReportType[0]['value']);
    const [showResyncBonusPanel, setShowResyncBonusPanel] = useState(false);
    const [resyncDate, setResyncDate] = useState(moment().startOf('day').format("YYYY-MM-DD"));
    const [playerUsername, setPlayerUsername] = useState('');
    var _userData = useSelector(state => state['authState']['userData']);

    let _summaryTableColumns = [
        {
            Header: "DATE",
            accessor: "date",
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{row.original.amount}</span> : <span className="text-danger">{row.original.amount}</span>;
            },
            disableSortBy: true
        }
    ]

    let _detailTableColumns = [
        {
            Header: "PLAYER_USERNAME",
            accessor: "playerUsername",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "vendorGameName",
            Cell: ({ row }) => {
                return <span>{row.original.vendorGameName ?? row.original.vendorName}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "TURNOVER",
            accessor: "turnoverAmount",
            disableSortBy: true
        },
        {
            Header: t("FACTOR") + " (%)",
            accessor: "factor",
            disableSortBy: true
        },
        {
            Header: "REWARD",
            accessor: "reward",
            Cell: ({ row }) => {
                let rewardEarnt = row.original.reward;
                return <span className="text-success">{rewardEarnt.toFixed(Math.max(2, (rewardEarnt.toString().split('.')[1] || []).length))}</span>;
            },
            disableSortBy: true
        },
    ]

    let _tableColumns = [
        {
            Header: "PROCESS_DATE",
            accessor: "createdTime",
            disableSortBy: true
        },
        {
            Header: "USERNAME",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "SHOP",
            accessor: "shopUsername",
            disableSortBy: true
        },
        {
            Header: "REGION",
            accessor: "region",
            disableSortBy: true
        },
        {
            Header: "REWARD_TYPE",
            accessor: "typeId",
            Cell: ({ value }) => {
                return <span className="badge badge-primary">{t(RewardReportType.find(r => r.value == value).label)}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "PRE_REWARD",
            accessor: "preReward",
            disableSortBy: true
        },
        {
            Header: "REWARD",
            accessor: "reward",
            Cell: ({ row }) => {
                return row.original.reward > 0 ? <span className="text-success">{row.original.reward}</span> : <span className="text-danger">{row.original.reward}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "POST_REWARD",
            accessor: "postReward",
            disableSortBy: true
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    readRewardDetail(row.original.historyId);
                                }}>
                                <span>{t("VIEW_DETAIL")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
        },
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function init() {
        await getVendorOption();
        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getVendorOption() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var vendorList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
            });

            setVendorOption(vendorList);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function readRewardDetail(historyId) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_TURNOVER_REWARD_DETAIL.replace("{historyId}", historyId));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowDetail(true);
            setDetailData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    function filterTurnoverRewardReport(isSummary) {
        var data = {
            "Username": phone,
            "StartDate": startDate + " 00:00:00",
            "EndDate": endDate + " 23:59:59",
            "TargetShop": selectedShop,
            "TargetVendor": selectedVendor,
            "TypeId": selectedRewardType
        };

        if (isSummary) {
            setSummaryPostData(data);
            setSummaryApiUrl(ApiUrl._API_GET_TURNOVER_REWARD_SUMMARY_REPORT);
        }
        else {
            setPostData(data);
            setApiUrl(ApiUrl._API_GET_TURNOVER_REWARD_REPORT);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getRecordDateSummary() {
        var data = {
            "Phone": phone,
            "StartDate": startDate + " 00:00:00",
            "EndDate": endDate + " 23:59:59",
            "TargetShop": selectedShop,
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_GET_REWARD_RECORD_DATE_REPORT, data);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowRecordDateSummary(true);
            setRecordDateSummary(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function resyncHighBonusReward() {
        console.log(playerUsername, resyncDate);

        _dispatch(showMessage({
            content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
            showCancel: true,
            required: true,
            inputType: 'password',
            validationMsg: t('INVALID_PASSWORD'),
            confirmBtnText: t("CONFIRM"),
            type: AlertTypes._INPUT,
            onConfirm: async (value) => {
                var responseJson = await ApiEngine.post(`${ApiUrl._API_RESYNC_HIGH_BONUS_REWARD}?playerUsername=${playerUsername}&drawDate=${resyncDate}`);
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }
        }))
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} postData={postData} showOverallInfo={true} fetchUrl={apiUrl} />, [postData, apiUrl])

    return (
        <div>
            <h1 className="page-header">{t("REWARD_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD"));
                                        }
                                        else {
                                            setStartDate("");
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD"));
                                        }
                                        else {
                                            setEndDate("");
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("PHONE_NUMBER")}</label>
                                <input type="text" name="phone" className="form-control form-control-lg" onInput={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("REWARD_TYPE")}</label>
                                <Select name="rewardTypeId" options={RewardReportType}
                                    value={RewardReportType.filter(option => option.value == selectedRewardType)}
                                    placeholder=""
                                    onChange={(e) => {
                                        setSelectedRewardType(e.value);
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className="row m-b-12">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("SHOP")}</label>
                                <DualListBox
                                    canFilter
                                    name="shops"
                                    options={shopOption}
                                    selected={selectedShop}
                                    onChange={(e) => {
                                        setSelectedShop(e);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("VENDOR")}</label>
                                <DualListBox
                                    canFilter
                                    name="vendor"
                                    options={vendorOption}
                                    selected={selectedVendor}
                                    onChange={(e) => {
                                        setSelectedVendor(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => filterTurnoverRewardReport(false)}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            {
                _userData?.isAdminLevel &&
                <Panel>
                    <div onClick={() => {
                        setShowResyncBonusPanel(!showResyncBonusPanel);
                    }}>
                        <PanelHeader noButton>
                            {t("RESYNC_HIGH_BONUS_REWARD")}
                        </PanelHeader>
                    </div>
                    <Collapse isOpen={showResyncBonusPanel}>
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-2">
                                    <label>{t("DRAW_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="resyncDate"
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        value={resyncDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setResyncDate(e.format("YYYY-MM-DD"));
                                            }
                                        }} />
                                </div>
                                <div className="col-lg-2">
                                    <label>{t("PLAYER_USERNAME")}</label>
                                    <input
                                        type="text"
                                        name="playerUsername"
                                        className="form-control form-control-lg"
                                        onInput={(e) => setPlayerUsername(e.target.value)}
                                    />
                                </div>
                                <div
                                    className="col-lg-1 flex-column justify-content-end"
                                    style={{ display: "flex" }}
                                >
                                    <button onClick={() => {
                                        resyncHighBonusReward();
                                    }} className="btn btn-primary">{t('RESYNC')}</button>
                                </div>
                            </div>
                        </PanelBody>
                    </Collapse>
                </Panel>
            }
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div class="col">
                            <button type="button" className="btn btn-primary m-r-10" onClick={() => {
                                filterTurnoverRewardReport(true);
                                setShowSummary(true)
                            }}
                            >{t('SUMMARY')}</button>
                        </div>
                    </div>
                    <div className="row m-b-10">
                        <div className="col-lg-12">
                            <div className="form-group">
                                {PaginationTable}
                            </div>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Modal isOpen={showDetail} toggle={() => setShowDetail(false)} style={{ paddingBottom: "100px" }} className="modal-lg" keyboard={true} >
                <ModalHeader toggle={() => setShowDetail(false)}>{t("REWARD_DETAIL")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <ReactTable columns={_detailTableColumns} data={detailData} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn btn-inverse-brand" onClick={() => setShowDetail(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showSummary} toggle={() => setShowSummary(false)} style={{ paddingBottom: "100px" }} className="modal-lg" keyboard={true} >
                <ModalHeader toggle={() => setShowSummary(false)}>{t("SUMMARY")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <ReactTable columns={_summaryTableColumns} fetchUrl={summaryApiUrl} postData={summaryPostData} exportRequired={true} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn btn-inverse-brand" onClick={() => setShowSummary(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default TurnoverRewardReport;