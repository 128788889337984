import React, { useState, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import NavigationButton from '../../../components/custom/NavigationButton';
import { Panel, PanelBody, PanelFooter } from '../../../components/panel/panel.jsx';
import { useHistory, useLocation } from "react-router-dom";
import { WebUrl, ApiKey, ApiUrl, LanguageKey, AlertTypes, Status, Role, BannerType } from '../../../util/Constant';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../redux/AppAction";
import { createMultiPartFormBody, stringIsNullOrEmpty, navigateTo, getValidationMessage, arrayGroupByKey } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import 'react-cron-generator/dist/cron-builder.css';
import DateTime from 'react-datetime';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import DualListBox from 'react-dual-listbox';

/// <summary>
/// Author: Wind
/// </summary> 
const BannerDetail = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, setValue, watch } = useForm();
    const [supportedLanguage, setSupportedLanguage] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [dayOfWeek, setDayOfWeek] = useState([1, 2, 3, 4, 5, 6, 0]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: "PLEASE_ENTER_START_DATE" });
        register({ name: 'endDate' }, { required: "PLEASE_ENTER_END_DATE" });
        register({ name: 'status' });

        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"));
        setValue('status', true);

        (async () => {
            var languageList = [];
            var langResponseJson = await ApiEngine.get(ApiUrl._API_GET_SUPPORTED_LANGUAGE);
            if (langResponseJson[ApiKey._API_SUCCESS_KEY]) {
                languageList = langResponseJson[ApiKey._API_DATA_KEY].map(item => ({ ...item, file: {}, preview: null }));
            }

            var shopResponseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP);

            if (shopResponseJson[ApiKey._API_SUCCESS_KEY]) {
                var shopListByRegion = arrayGroupByKey(shopResponseJson[ApiKey._API_DATA_KEY], "regionName");
                var shopOptionsByRegion = [];

                for (var key in shopListByRegion) {
                    var shopList = [];

                    shopListByRegion[key].map(shop => {
                        shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                    });

                    shopOptionsByRegion.push({
                        label: key,
                        value: key,
                        options: shopList
                    })
                }

                setShopOption(shopOptionsByRegion);
            }

            if (_location.state && _location.state.id) {
                var responseJson = await ApiEngine.get(ApiUrl._API_BANNER + "/" + _location.state.id);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setValue("startDate", moment(responseJson[ApiKey._API_DATA_KEY].startDate).format("YYYY-MM-DD HH:mm:ss"));
                    setValue("endDate", moment(responseJson[ApiKey._API_DATA_KEY].endDate).format("YYYY-MM-DD HH:mm:ss"));
                    setValue("cta", responseJson[ApiKey._API_DATA_KEY].cta);
                    setValue("status", responseJson[ApiKey._API_DATA_KEY].status == Status._ENABLED ? true : false);
                    setValue("bannerType", responseJson[ApiKey._API_DATA_KEY].bannerType);
                    setSelectedShop(responseJson[ApiKey._API_DATA_KEY].bannerShops?.map(s => s.userId));
                    languageList = languageList.map(item => ({
                        ...item, title: responseJson[ApiKey._API_DATA_KEY].titleLocalizations.find(l => l.languageId == item.id).value,
                        description: responseJson[ApiKey._API_DATA_KEY].descriptionLocalizations.find(l => l.languageId == item.id).value,
                        preview: responseJson[ApiKey._API_DATA_KEY].imageLocalizations.find(l => l.languageId == item.id).value
                    }));

                    if(responseJson[ApiKey._API_DATA_KEY].frequency != -1){
                        responseJson[ApiKey._API_DATA_KEY].frequency.split(",").map((f) => {
                            setValue(`frequency.${f}`, f);
                        });
                    }
                }
            }

            setSupportedLanguage(languageList);
        })();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    const updateImage = (acceptedFiles, id) => {
        let newItem = [...supportedLanguage];
        let index = newItem.findIndex(i => i.id == id);

        if (acceptedFiles.length != 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();

            newItem[index].file = file;

            reader.onloadend = () => {
                newItem[index].preview = reader.result;
                setSupportedLanguage(newItem);
            }

            reader.readAsDataURL(file);
        }
        else {
            newItem[index].preview = null;
            newItem[index].file = {};
            setSupportedLanguage(newItem);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const submitForm = async (data, e) => {
        var responseJson;

        let frequencyArr = data.frequency.filter(d => d !== false);

        let params = {
            title: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}_title`] }))),
            description: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}_description`] }))),
            startDate: data.startDate,
            endDate: data.endDate,
            cta: data.cta,
            status: data.status ? Status._ENABLED : Status._DISABLED,
            shopIds: selectedShop,
            bannerType: data.bannerType,
            frequency: frequencyArr.length > 0 ? frequencyArr : -1
        }

        for (var i = 0; i < supportedLanguage.length; i++) {
            if (stringIsNullOrEmpty(supportedLanguage[i]["preview"])) {
                _dispatch(showMessage({
                    type: AlertTypes._ERROR,
                    content: t("PLEASE_UPLOAD_BANNER_IMAGE") + ' (' + supportedLanguage[i]['name'] + ')'
                }));
                return;
            }
            params[`${supportedLanguage[i]["id"]}`] = supportedLanguage[i]["file"];
        }

        if (_location.state && _location.state.id) {
            responseJson = await ApiEngine.put(ApiUrl._API_BANNER + '/' + _location.state.id, createMultiPartFormBody(params));
        }
        else {
            responseJson = await ApiEngine.post(ApiUrl._API_BANNER, createMultiPartFormBody(params));
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_BANNER)
        }));
    }

    return (
        <div>
            <h1 className="page-header">{_location.state ? t("EDIT_BANNER") : t("CREATE_BANNER")}<NavigationButton history={_history} /></h1>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                    <div className="col-xl-9">
                        <Panel>
                            <PanelBody>
                                <div className="row">
                                    {
                                        supportedLanguage && supportedLanguage.map((item, index) => {
                                            return (
                                                <div className="col-lg-4" key={index}>
                                                    <div className="form-group">
                                                        <label>{t("TITLE") + " (" + item.name + ")"}</label>
                                                        <input
                                                            className="form-control form-control-lg"
                                                            id={item.id + '_title'}
                                                            name={item.id + '_title'}
                                                            defaultValue={item.title}
                                                            placeholder={t("ENTER_BANNER_TITLE") + " (" + item.name + ")"}
                                                            ref={register({ required: "PLEASE_ENTER_TITLE" })} />
                                                        {errors[`${item.id + '_title'}`] && <div className="invalid-feedback">{t(getValidationMessage(errors[`${item.id + '_title'}`]))}</div>}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="row">
                                    {
                                        supportedLanguage && supportedLanguage.map((item, index) => {
                                            return (
                                                <div className="col-lg-4" key={index}>
                                                    <div className="form-group">
                                                        <label>{t("DESCRIPTION") + " (" + item.name + ")"}</label>
                                                        <input
                                                            className="form-control form-control-lg"
                                                            id={item.id + '_description'}
                                                            name={item.id + '_description'}
                                                            defaultValue={item.description}
                                                            placeholder={t("ENTER_BANNER_DESCRIPTION") + " (" + item.name + ")"}
                                                            ref={register({ required: "PLEASE_ENTER_DESCRIPTION" })} />
                                                        {errors[`${item.id + '_description'}`] && <div className="invalid-feedback">{t(getValidationMessage(errors[`${item.id + '_description'}`]))}</div>}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>{t("START_DATE")}</label>
                                            <DateTime
                                                inputProps={{
                                                    className: 'form-control form-control-lg bg-white',
                                                    readOnly: true
                                                }}
                                                name="startDate"
                                                timeFormat="HH:mm:ss"
                                                dateFormat="YYYY-MM-DD"
                                                closeOnSelect={true}
                                                value={watch('startDate')}
                                                onChange={(e) => {
                                                    if (e instanceof moment) {
                                                        setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                    }
                                                    else {
                                                        setValue("startDate", "");
                                                    }
                                                }} />
                                            {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>{t("END_DATE")}</label>
                                            <DateTime
                                                inputProps={{
                                                    className: 'form-control form-control-lg bg-white',
                                                    readOnly: true
                                                }}
                                                name="endDate"
                                                timeFormat="HH:mm:ss"
                                                dateFormat="YYYY-MM-DD"
                                                closeOnSelect={true}
                                                value={watch('endDate')}
                                                onChange={(e) => {
                                                    if (e instanceof moment) {
                                                        setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                    }
                                                    else {
                                                        setValue("endDate", "");
                                                    }
                                                }} />
                                            {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>{t("LINKS")}</label><br />
                                            <div>
                                                <input
                                                    type="text"
                                                    name="cta"
                                                    ref={register}
                                                    className="form-control form-control-lg"
                                                    placeholder={t("ENTER_BANNER_LINKS")} />
                                                {errors.cta && <div className="invalid-feedback">{t(errors.cta.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        supportedLanguage && supportedLanguage.map((item, index) => {
                                            return (
                                                <div className="col-lg-4" key={index}>
                                                    <div className="form-group">
                                                        <label>{t("BANNER") + " (" + item.name + ")"}</label>
                                                        <Dropzone accept={'image/*'} onDrop={acceptedFiles => updateImage(acceptedFiles, item.id)}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                    <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        {!item.preview && <h4 style={{ color: "grey" }}>{t("UPLOAD_BANNER") + " (" + item.name + ")"}</h4>}
                                                                        {item.preview && <aside className="thumbsContainer">
                                                                            <div className="thumb">
                                                                                <div className="thumbInner">
                                                                                    <img
                                                                                        src={item.preview}
                                                                                        className="dropzone-img"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </aside>}
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                        <button type="button" className="mt-2 btn btn-danger"
                                                            onClick={() => {
                                                                updateImage([], item.id);
                                                            }}>
                                                            {t("REMOVE_IMAGE")}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>{t("SHOP")}</label><br />
                                            <DualListBox
                                                canFilter
                                                name="shops"
                                                selected={selectedShop}
                                                options={shopOption}
                                                onChange={(e) => {
                                                    setSelectedShop(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>
                    </div>
                    <div className='col-xl-3'>
                        <Panel>
                            <PanelBody>
                                <div className="form-group">
                                    <label>{t("BANNER_TYPE")}</label><br />
                                    <select ref={register} name="bannerType" className='form-control form-control-lg'>
                                        {Object.values(BannerType).map((bt) => <option value={bt.key}>{bt.value}</option>)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>{t("ENABLE")}</label><br />
                                    <div className="switcher">
                                        <input
                                            type="checkbox"
                                            name="status"
                                            id="status"
                                            onChange={(e) => setValue('status', e.target.checked)}
                                            checked={watch('status')} />
                                        <label htmlFor="status"></label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t("DAY_TO_DISPLAY")}</label><br />
                                    <p>{t("IGNORE_TO_DISPLAY_EVERYDAY")}</p>
                                    {
                                        dayOfWeek.map((d) => <div className="d-flex align-items-center mb-2">
                                            <div className="switcher">
                                                <input
                                                    type="checkbox"
                                                    name={`frequency.${d}`}
                                                    id={`day${d}`}
                                                    value={d}
                                                    ref={register}
                                                />
                                                <label htmlFor={`day${d}`}></label>
                                            </div>
                                            <label className='mb-0 ml-2'>{moment().day(d).format('dddd')}</label>
                                        </div>)
                                    }
                                </div>
                            </PanelBody>
                            <PanelFooter>
                                <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                            </PanelFooter>
                        </Panel>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default BannerDetail;