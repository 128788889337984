import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import {
    Panel,
    PanelHeader,
    PanelBody,
    PanelFooter,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, WebUrl, AccessRight, Role, ReportType, ManagerTransactionType } from "../../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, getValidationMessage } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';

/// <summary>
/// Author : Wind
/// </summary>
const ManagerLimitTransactionReport = props => {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [regionOption, setRegionOption] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [defaultManagerOption, setDefaultManagerOption] = useState([]);
    const [managerOption, setManagerOption] = useState([]);
    const [selectedManager, setSelectedManager] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [postData, setPostData] = useState({});
  
    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            width: 30,
            disableSortBy: true
        },
        {
            Header: "DATE",
            accessor: "createdTime",
            disableSortBy: true,
            Cell: ({ row }) => {
                return moment(row.original.createdTime).format('DD/MM/YYYY hh:mm:ss A')
            },
        },
        {
            Header: "OPERATED_BY",
            accessor: "createdByUsername",
            disableSortBy: true
        },
        {
            Header: "MANAGER_USERNAME",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "INITIAL_LIMIT",
            accessor: "preCreditLimit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.preCreditLimit.toFixed(2)
            },
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.creditLimit > 0 ? <span className="text-success">{row.original.creditLimit.toFixed(2)}</span> : <span className="text-danger">{row.original.creditLimit.toFixed(2)}</span>;
            },
            disableSortBy: true,
        },
        {
            Header: "FINAL_LIMIT",
            accessor: "postCreditLimit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.postCreditLimit.toFixed(2)
            },
        },
        {
            Header: "TRANSACTION_TYPE",
            accessor: "typeName",
            Cell: ({ row }) => {
                return (
                    <span className={`badge badge-primary`}>{t(row.original.typeName)}</span>
                )
            },
            disableSortBy: true
        },
    ]

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'manager' });
        register({ name: 'endDate' }, { required: true });

        setValue('manager', []);
        setValue('startDate', startDate);
        setValue('endDate', endDate);
        init();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        var filteredManagerOptions = defaultManagerOption.filter(manager => selectedRegion.includes(manager.regionId));

        var filterSelectedManager = selectedManager.filter(manager => {
            var managerDetail = defaultManagerOption.find(managerOptions => manager == managerOptions.value);
            if (selectedRegion.includes(managerDetail.regionId)) {
                return manager;
            }
        });

        setManagerOption(filteredManagerOptions);
        setSelectedManager(filterSelectedManager);
        setValue('manager', filterSelectedManager);
    }, [selectedRegion]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var regionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.prefix, value: region.id });
            });

            setRegionOption(regionList);
        }

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?roleId=" + Role._MANAGER);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var managerOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                managerOptionList.push({ label: user.username, value: user.id, regionId: user.regionId });
            });

            setDefaultManagerOption(managerOptionList);
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            "startDate": data.startDate,
            "endDate": data.endDate,
            "managerIds": data.manager,
        };

        if (data.reportType != ReportType._ALL) {
            params['typeId'] = parseInt(data.reportType);
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_MANAGER_LIMIT_TRANSACTION_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable
        columns={_tableColumns}
        fetchUrl={apiUrl}
        postData={postData}
        exportRequired={true} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t('MANAGER_LIMIT_TRANSACTION')}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("REGION")}</label>
                                    <DualListBox
                                        canFilter
                                        options={regionOption}
                                        selected={selectedRegion}
                                        onChange={(e) => {
                                            setSelectedRegion(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("MANAGER_USERNAME")}</label>
                                    <DualListBox
                                        canFilter
                                        name="manager"
                                        options={managerOption}
                                        selected={selectedManager}
                                        onChange={(e) => {
                                            setValue('manager', e);
                                            setSelectedManager(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select ref={register} name="reportType" className='form-control form-control-lg'>
                                        <option value={ReportType._ALL}>{t("ALL")}</option>
                                        <option value={ManagerTransactionType._MANAGER_LIMIT}>{t("MANAGER_LIMIT")}</option>
                                        <option value={ManagerTransactionType._SHOP_LIMIT}>{t("SHOP_LIMIT")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManagerLimitTransactionReport;