import React, { useState, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import NavigationButton from '../../../components/custom/NavigationButton';
import { Panel, PanelBody, PanelHeader } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import { useHistory, useLocation } from "react-router-dom";
import { WebUrl, SettingType, ApiKey, ApiUrl, LanguageKey, AlertTypes, AnnouncementEventTypeId, AnnouncementTag, Role } from '../../../util/Constant';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import { createMultiPartFormBody, stringIsNullOrEmpty, navigateTo, createFormBody } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import Cron from 'react-cron-generator';
import 'react-cron-generator/dist/cron-builder.css';
import DateTime from 'react-datetime';
import moment from 'moment';
import DualListBox from 'react-dual-listbox';

/// <summary>
/// Author: Wind
/// </summary> 
const AppAnnouncementDetail = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, setValue, unregister, watch } = useForm();
    const [announcementTypeOption, setAnnouncementTypeOption] = useState([]);
    const [selectedTagsValue, setSelectedTagsValue] = useState('');
    const [supportedLanguage, setSupportedLanguage] = useState([]);
    const [notificationTypeId, setNotificationTypeId] = useState();
    const [cursorPosition, setCursorPosition] = useState();
    const [currentInput, setCurrentInput] = useState();
    const [cron, setCron] = useState('');
    const [cronVisible, setCronVisible] = useState(false);
    const [regionOption, setRegionOption] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState();
    const [fullShopList, setFullShopList] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [previousSelectedRegion, setPreviousSelectedRegion] = useState();
    const [previousSelectedShop, setPreviousSelectedShop] = useState([]);
    const _TAG_OPTIONS = [
        { label: AnnouncementTag._USERNAME, value: '{' + AnnouncementTag._USERNAME + '}' },
        { label: AnnouncementTag._PRICE_NAME, value: '{' + AnnouncementTag._PRICE_NAME + '}' },
        { label: AnnouncementTag._AMOUNT, value: '{' + AnnouncementTag._AMOUNT + '}' }
    ];
    const _ANNOUCEMENT_TYPE_WITHOUT_CRON = [
        AnnouncementEventTypeId._WITHDRAW,
        AnnouncementEventTypeId._LUCKY_SPIN,
        AnnouncementEventTypeId._USER_DEFINED
    ];
    const _DEFAULT_CRON = '0 0 00 1/1 * ? *';

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'announcementTypeId' }, { required: "PLEASE_SELECT_CATEGORY" });
        register({ name: 'startDate' }, { required: "PLEASE_ENTER_START_DATE" });
        register({ name: 'endDate' }, { required: "PLEASE_ENTER_END_DATE" });
        register({ name: 'cron' });
        register({ name: 'isEnabled' });

        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('cron', _DEFAULT_CRON);
        setValue('isEnabled', true);

        (async () => {
            try {
                var languageList = [];
                var typeList = [];
                var langResponseJson = await ApiEngine.get(ApiUrl._API_GET_SUPPORTED_LANGUAGE);

                if (langResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    languageList = langResponseJson[ApiKey._API_DATA_KEY];
                }
                else {
                    throw langResponseJson[ApiKey._API_MESSAGE_KEY];
                }
                
                var typeResponseJson = await ApiEngine.get(ApiUrl._API_GET_ANNOUNCEMENT_TYPE);
                
                if (typeResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    typeList = typeResponseJson[ApiKey._API_DATA_KEY].map(item => ({
                        ...item,
                        label: item.eventName,
                        value: item.id,
                        disableInterval: _ANNOUCEMENT_TYPE_WITHOUT_CRON.includes(item.id)
                    }));
                    
                    setAnnouncementTypeOption(typeList);
                }
                else {
                    throw typeResponseJson[ApiKey._API_MESSAGE_KEY];
                }

                var regionResponseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);
                
                if (regionResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    setRegionOption(regionResponseJson[ApiKey._API_DATA_KEY]);
                }
                else {
                    throw regionResponseJson[ApiKey._API_MESSAGE_KEY];
                }

                var shopResponseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP);

                if (shopResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    setFullShopList(shopResponseJson[ApiKey._API_DATA_KEY]);
                }
                else {
                    throw shopResponseJson[ApiKey._API_MESSAGE_KEY];
                }

                if (_location.state && _location.state.id) {
                    var responseJson = await ApiEngine.get(ApiUrl._API_ANNOUNCEMENT_EVENT + "/" + _location.state.id);
                    
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setPreviousSelectedRegion(responseJson[ApiKey._API_DATA_KEY].regionId);
                        setSelectedRegion(responseJson[ApiKey._API_DATA_KEY].regionId);
                        setValue('announcementTypeId', responseJson[ApiKey._API_DATA_KEY].announcementEventTypeId);
                        setValue('title', responseJson[ApiKey._API_DATA_KEY].title);
                        setValue('startDate', moment(responseJson[ApiKey._API_DATA_KEY].startDate).format('YYYY-MM-DD HH:mm:ss'));
                        setValue('endDate', moment(responseJson[ApiKey._API_DATA_KEY].endDate).format('YYYY-MM-DD HH:mm:ss'));
                        languageList = languageList.map(item => ({ ...item, value: responseJson[ApiKey._API_DATA_KEY].localizations.find(l => l.languageId == item.id).value }));
                        setValue('isEnabled', responseJson[ApiKey._API_DATA_KEY].isEnabled);
                        setValue('cron', responseJson[ApiKey._API_DATA_KEY].interval);
                        setPreviousSelectedShop(responseJson[ApiKey._API_DATA_KEY].announcementEventShops.map(s => { return (s.shopId) }));
                        setSelectedShop(responseJson[ApiKey._API_DATA_KEY].announcementEventShops.map(s => { return (s.shopId) }));
                    }
                    else {
                        throw responseJson[ApiKey._API_MESSAGE_KEY];
                    }
                }
                
                setSupportedLanguage(languageList);
            }
            catch (err) {
                _dispatch(showMessage({
                    type: AlertTypes._ERROR,
                    content: t(err),
                    onConfirm: () => navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_APP_ANNOUNCEMENT)
                }));
            }
        })();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(watch('announcementTypeId')) && !announcementTypeOption.find(a => a.value == watch('announcementTypeId')).disableInterval) {
            setCronVisible(true);
        }
        else {
            setCronVisible(false);
        }
    }, [watch('announcementTypeId')]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (fullShopList.length > 0) {
            var shopList = [...fullShopList];
            shopList = shopList.filter(s => s.regionId == selectedRegion).map(item => ({ label: item.username + " (" + item.shopName + ")", value: item.id }));
            setShopOption(shopList);

            if (selectedRegion == previousSelectedRegion) {
                setSelectedShop(previousSelectedShop);
            }
            else {
                setSelectedShop([]);
            } 
        }
    }, [selectedRegion]);

    /// <summary>
    /// Author: Wind
    /// </summary> 
    const handleInputCursor = (e) => {
        setCursorPosition(e.target.selectionStart);
    }

    /// <summary>
    /// Author: Wind
    /// </summary> 
    const handleCurrentInput = (e, id) => {
        setCurrentInput(id);
        handleInputCursor(e);
    }

    /// <summary>
    /// Author: Wind
    /// </summary> 
    function insertTagIntoContent() {
        if (currentInput) {
            let textBeforeCursorPosition = watch(currentInput).substring(0, cursorPosition);
            let textAfterCursorPosition = watch(currentInput).substring(cursorPosition, watch(currentInput).length);

            setValue(currentInput, textBeforeCursorPosition + selectedTagsValue + textAfterCursorPosition);
        }
        else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t('PLEASE_SELECT_ONE_OF_THE_CONTENT_TO_INSERT_TAG'),
                onConfirm: () => { }
            }));
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            messages: supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}`] })),
            title: data.title,
            startDate: data.startDate,
            endDate: data.endDate,
            announcementEventTypeId: parseInt(data.announcementTypeId),
            isEnabled: data.isEnabled
        };

        if (!announcementTypeOption.find(a => a.value == data.announcementTypeId).disableInterval) {
            let cronData = data.cron.replace(/\?/g, "*").substring(1);
            cronData = cronData.substring(0, cronData.length - 1);
            params['interval'] = cronData;
        }

        if (!stringIsNullOrEmpty(selectedRegion) && selectedShop.length > 0) {
            params['regionId'] = parseInt(selectedRegion);
            params['shopIds'] = selectedShop;
        }
        
        var responseJson;

        if (_location.state && _location.state.id) {
            responseJson = await ApiEngine.put(ApiUrl._API_ANNOUNCEMENT_EVENT + '/' + _location.state.id, params);
        }
        else {
            responseJson = await ApiEngine.post(ApiUrl._API_ANNOUNCEMENT_EVENT, params);
        }
        
        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_APP_ANNOUNCEMENT)
        }));
    }

    return (
        <div>
            <h1 className="page-header">{_location.state ? t("EDIT_ANNOUNCEMENT_TEMPLATE") : t("CREATE_ANNOUNCEMENT_TEMPLATE")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("CATEGORY")}</b></label>
                                    <div className="col-md-4">
                                        <Select 
                                            name="announcementTypeId"
                                            options={announcementTypeOption}
                                            value={announcementTypeOption.find(a => a.value == watch('announcementTypeId'))}
                                            onChange={(e) => {
                                                setValue('announcementTypeId', e.value);
                                            }} />
                                        {errors.announcementTypeId && <div className="invalid-feedback">{t(errors.announcementTypeId.message)}</div>}
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("TITLE")}</b></label>
                                    <div className="col-md-4">
                                        <input 
                                            type="text"
                                            name="title"
                                            ref={register({ required: "PLEASE_ENTER_TITLE" })}
                                            className="form-control form-control-lg"
                                            placeholder={t("ENTER_ANNOUNCEMENT_TITLE")} />
                                        {errors.title && <div className="invalid-feedback">{t(errors.title.message)}</div>}
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("START_DATE")}</b></label>
                                    <div className="col-md-4">
                                        <DateTime 
                                            inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                            name="startDate"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat="HH:mm:ss"
                                            closeOnSelect={true}
                                            value={watch('startDate')}
                                            onChange={(e) => {
                                                if (e instanceof moment) {
                                                    setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                }
                                                else {
                                                    setValue("startDate", "");
                                                }
                                            }} />
                                        {errors.startDate && <div className="invalid-feedback">{t(errors.startDate.message)}</div>}
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("END_DATE")}</b></label>
                                    <div className="col-md-4">
                                        <DateTime
                                            inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                            name="startDate"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat="HH:mm:ss"
                                            closeOnSelect={true}
                                            value={watch('endDate')}
                                            onChange={(e) => {
                                                if (e instanceof moment) {
                                                    setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                }
                                                else {
                                                    setValue("endDate", "");
                                                }
                                            }} />
                                        {errors.endDate && <div className="invalid-feedback">{t(errors.endDate.message)}</div>}
                                    </div>
                                </div>
                                {
                                    cronVisible &&
                                    <div className="row m-b-10">
                                        <label className="col-md-3 col-form-label"><b>{t("INTERVAL")}</b></label>
                                        <div className="col-md-4">
                                            <Cron
                                                name="cron"
                                                onChange={(e) => { setValue('cron', e); }}
                                                value={watch('cron')}
                                                showResultText={true}
                                                showResultCron={true}
                                            />
                                            {errors.cron && <div className="invalid-feedback">{t(errors.cron.message)}</div>}
                                        </div>
                                    </div>
                                }
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("SELECTABLE_TAGS")}</b></label>
                                    <div className="col-md-4">
                                        <label>{t("SELECTABLE_TAGS")}</label>
                                        <Select
                                            options={_TAG_OPTIONS}
                                            value={_TAG_OPTIONS.filter(option => option.value == selectedTagsValue)}
                                            onChange={(e) => { setSelectedTagsValue(e.value); }} />
                                        <br />
                                        <button
                                            type="button" 
                                            className="btn btn-primary"
                                            onClick={() => {
                                            !stringIsNullOrEmpty(selectedTagsValue) && insertTagIntoContent()
                                        }}>{t("INSERT_TAG")}</button>
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("CONTENT")}</b></label>
                                    <div className="row col-md-8">
                                        {
                                            supportedLanguage && supportedLanguage.map((item, index) => {
                                                return (
                                                    <div className="col-md-4">
                                                        <label>{t("CONTENT") + " (" + item.name + ")"}</label>
                                                        <textarea
                                                            className="form-control form-control-lg"
                                                            rows="4"
                                                            id={item.id}
                                                            name={item.id}
                                                            defaultValue={item.value}
                                                            ref={register({ required: "PLEASE_ENTER_CONTENT" })}
                                                            placeholder={t("ENTER_ANNOUNCEMENT_TEMPLATE") + " (" + item.name + ")"}
                                                            onClick={(e) => handleCurrentInput(e, item.id)}
                                                            onFocus={(e) => handleCurrentInput(e, item.id)}
                                                            onKeyUp={(e) => handleInputCursor(e)} />
                                                        {errors[`${item.id}`] && <div className="invalid-feedback">{t(errors[`${item.id}`].message)}</div>}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("ENABLE")}</b></label>
                                    <div className="col-md-5">
                                        <div className="switcher">
                                            <input
                                                type="checkbox"
                                                name="isEnabled"
                                                id="isEnabled"
                                                onChange={(e) => setValue('isEnabled', e.target.checked)}
                                                checked={watch('isEnabled')} />
                                            <label htmlFor="isEnabled"></label>
                                        </div>
                                    </div>
                                </div>
                                <PanelHeader noButton>{t('ASSIGN_SHOPS')}</PanelHeader>
                                <PanelBody>
                                    <div className="form-group">
                                        <label>{t("REGION")}</label>
                                        <div className="row col-md-10">
                                            {
                                                regionOption && regionOption.map((item) => {
                                                    return (
                                                        <div className="col-md-1 form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="region"
                                                                id={"region[" + item.id + "]"}
                                                                value={item.id}
                                                                checked={selectedRegion == item.id}
                                                                onChange={(e) => { setSelectedRegion(e.target.value); }} />
                                                            <label className="form-check-label" for={"region[" + item.id + "]"}>
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{t("SHOPS")}</label>
                                        <DualListBox 
                                            canFilter
                                            name="shops"
                                            selected={selectedShop}
                                            options={shopOption}
                                            onChange={(e) => {
                                                setSelectedShop(e);
                                            }}
                                        />
                                    </div>
                                </PanelBody>
                                <div className="row m-b-10">
                                    <div className="col-md-3">
                                        <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    )
}

export default AppAnnouncementDetail;