import ReactTable from 'components/custom/ReactTable';
import { Panel, PanelBody } from 'components/panel/panel';
import React, { useEffect, useMemo, useState } from 'react';
import DualListBox from 'react-dual-listbox';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ApiEngine from 'util/ApiEngine';
import { ApiKey, ApiUrl } from 'util/Constant';
import { arrayGroupByKey, isObjectEmpty, numberWithCurrencyFormat, stringIsNullOrEmpty } from '../../../util/Util';
import moment from 'moment';
import DateTime from 'react-datetime';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import RegionFilterDualListBox from '../../../components/custom/RegionFilterDualListBox';

/// <summary>
/// Author : CK
/// </summary>
const LottoSeamlessSettlementReport = () => {
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState("");
    const [postData, setPostData] = useState({});
    const [vendorOption, setVendorOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [agents, setAgents] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [drawDate, setDrawDate] = useState(moment().startOf('day').format('YYYY-MM-DD'));
    const [viewDetails, setViewDetails] = useState({});
    const [viewUrl, setViewUrl] = useState("");
    const [regions, setRegions] = useState([]);

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            drawDate: drawDate
        }
    });

    const _COLUMNS = [
        {
            Header: "DRAW_DATE",
            accessor: "parsedDrawDate",
            disableSortBy: true,
            style: { width: "10%", wordBreak: "break-word", overflowWrap: "break-word", whiteSpace: "normal" }
        },
        {
            Header: "AGENT",
            accessor: "agentUsername",
            disableSortBy: true
        },
        {
            Header: "PLAYER",
            accessor: "playerUsername",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "gameName",
            disableSortBy: true
        },
        {
            Header: "ORDER_STRING",
            accessor: "orderString",
            disableSortBy: true,
            style: { width: "15%", wordBreak: "break-word", overflowWrap: "break-word", whiteSpace: "normal" }
        },
        {
            Header: "RECEIPT_STRING",
            accessor: "receiptString",
            disableSortBy: true,
            style: { width: "35%", wordBreak: "break-word", overflowWrap: "break-word", whiteSpace: "normal" }
        },
        {
            Header: "TICKET_ID",
            accessor: "linkedId",
            disableSortBy: true
        },
        {
            Header: "ORDER_AMOUNT",
            accessor: "orderAmount",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span className="d-flex">
                        {numberWithCurrencyFormat(row.original.orderAmount, 2)}
                    </span >
                )
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.orderAmount);
                }, 0);

                return <strong>{numberWithCurrencyFormat(totalAmount, 2)}</strong>
            },
        },
        {
            Header: "STRIKE_AMOUNT",
            accessor: "strikeAmount",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span className="d-flex">
                        {numberWithCurrencyFormat(row.original.strikeAmount, 2)}
                    </span >
                )
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.strikeAmount);
                }, 0);

                return <strong>{numberWithCurrencyFormat(totalAmount, 2)}</strong>
            },
        },
        {
            Header: "CREATED_TIME",
            accessor: "parsedCreatedTime",
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        (async () => {
            await getVendorOption();
            await getAgentOption();
        })();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getVendorOption() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var vendorList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
            });

            setVendorOption(vendorList);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getAgentOption() {
        await ApiEngine.get(ApiUrl._API_GET_AGENT_LIST)
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var data = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
                    var optionList = [];
                    var regionOptions = [];

                    for (var key in data) {
                        var agentList = [];

                        data[key].map(shop => {
                            agentList.push({ label: shop.username, value: shop.id });
                        });

                        optionList.push({
                            label: key,
                            value: key,
                            options: agentList
                        })

                        regionOptions.push({
                            label: key,
                            value: key
                        });
                    }

                    setRegions(regionOptions);
                    setAgents(optionList);
                }
            })
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    function constructModalHeader() {
        let header = "";

        if (viewDetails["IsLotto"]) {
            header = t("LOTTO");
        }
        else {
            header = t("SLOT");
        }

        return header + " - " + viewDetails["AgentUsername"];
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            columns={_COLUMNS}
            fetchUrl={apiUrl}
            postData={postData}
            exportRequired={true}
            renderFooter={true}
            showOverallInfo={true}
        />
        , [apiUrl, postData]);

    /// <summary>
    /// Author : CK
    /// </summary>
    const submitForm = async (data, e) => {
        setPostData({
            DrawDate: drawDate,
            Vendors: selectedVendor,
            Agents: selectedAgents
        });

        setApiUrl(ApiUrl._API_GET_LOTTO_SEAMLESS_SETTLEMENT_REPORT);
    }

    return (
        <div>
            <h1 className="page-header d-flex justify-content-between">
                {t("LOTTO_SEAMLESS_SETTLEMENT_REPORT")}
            </h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row">
                            <div className="col-lg-3 col-md-3">
                                <div className="form-group">
                                    <label>{t("DRAW_DATE")}</label>
                                    <DateTime
                                        ref={register}
                                        defaultValue={moment().startOf('day').format('YYYY-MM-DD')}
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="drawDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={drawDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setDrawDate(e.format("YYYY-MM-DD"));
                                                setValue("drawDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("drawDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>{t("AGENT")}</label>
                                    <RegionFilterDualListBox
                                        canFilter
                                        name={"agents"}
                                        ref={register}
                                        options={agents}
                                        selectedOptions={selectedAgents}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('agents', e);
                                            setSelectedAgents(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <DualListBox
                                        canFilter
                                        name="vendor"
                                        ref={register}
                                        options={vendorOption}
                                        selected={selectedVendor}
                                        onChange={(e) => {
                                            setValue('vendor', e);
                                            setSelectedVendor(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default LottoSeamlessSettlementReport;