import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

// constants & utils
import {
    ApiKey,
    ApiUrl,
    AccessRight,
    Role,
    FilterStatus,
    PrizeType,
    AlertTypes,
    WebUrl,
    ProcessedAnnouncementStatus,
    Status
} from "../../../util/Constant";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";

// components
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DualListBox from "react-dual-listbox";
import {
    Panel,
    PanelBody,
    PanelFooter,
} from "../../../components/panel/panel.jsx";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import NavigationButton from "../../../components/custom/NavigationButton";
import ReactTable from "../../../components/custom/ReactTable";
import { showResponseMessage, setBusy, setIdle, showMessage } from "../../../redux/AppAction";
import ApiEngine from "../../../util/ApiEngine";
import DateTime from 'react-datetime';
import { createMultiPartFormBody, stringIsNullOrEmpty, navigateTo, getValidationMessage, imagePathToFileObject } from "../../../util/Util";
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import ClipLoader from "react-spinners/ClipLoader";

/// <summary>
/// Author : Lewis
/// </summary>
const ManagePromotionDetail = (props) => {
    var _location = useLocation();

    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();

    const {
        register: promotionRegister,
        handleSubmit: promotionHandleSubmit,
        errors: promotionErrors,
        setValue: promotionSetValue,
        unregister: promotionUnregister
    } = useForm();

    const {
        register: prizeRegister,
        handleSubmit: prizeHandleSubmit,
        errors: prizeErrors,
        setValue: prizeSetValue,
        unregister: prizeUnregister,
        clearError: prizeClearError,
        watch: prizeWatch
    } = useForm();

    const [prizeModalVisibility, setPrizeModalVisibility] = useState(false);
    const [prizeTypes, setPrizeTypes] = useState([]);
    const [prizeTypeId, setPrizeTypeId] = useState(PrizeType._PRIZE_TYPE_CASH);
    const [displayPrizeCount, setDisplayPrizeCount] = useState('');
    const [winPercentage, setWinPercentage] = useState('');
    const [spinCountToWin, setSpinCountToWin] = useState('');
    const [actualPrizeCount, setActualPrizeCount] = useState('');
    const [prizeName, setPrizeName] = useState('');
    const [prizeId, setPrizeId] = useState('');
    const [prizeAmount, setPrizeAmount] = useState('');
    const [prizes, setPrizes] = useState([]);

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'));
    const [targetShops, setTargetShops] = useState([]);
    const [shops, setShops] = useState([]);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [regionId, setRegionId] = useState('');
    const [regions, setRegions] = useState([]);
    const [activeDays, setActiveDays] = useState('');
    const [isAppOnly, setIsAppOnly] = useState(false);
    const [isOrderbyReward, setIsOrderByReward] = useState(false);
    const [promotionId, setPromotionId] = useState('');
    const [spinCountGrant, setSpinCountGrant] = useState('');
    const [freeSpinCount, setFreeSpinCount] = useState('');
    const [loginCountRequired, setLoginCountRequired] = useState('');
    const [showPrizeDetails, setShowPrizeDetails] = useState(false);
    const [coolDownDurationList, setCoolDownDurationList] = useState([]);
    const [coolDownDuration, setCoolDownDuration] = useState(0);
    const [promotionStatus, setPromotionStatus] = useState(props.isSpinPromotion ? Status._DISABLED : Status._ENABLED);
    const [promotionMethod, setPromotionMethod] = useState(props.isSpinPromotion);
    const [memberPrize, setMemberPrize] = useState([]);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertPrizeId, setAlertPrizeId] = useState('');
    const [alertMemberId, setAlertMemberId] = useState('');
    const [customAlert, setCustomAlert] = useState();
    const [dbJackpotExist, setDbJackpotExist] = useState(false);
    const [broadcastStatus, setBroadcastStatus] = useState(ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT);
    const [alertText, setAlertText] = useState('');
    const [prizeAccumulate, setPrizeAccumulate] = useState(false);
    const [lowerBound, setLowerBound] = useState('');
    const [upperBound, setUpperBound] = useState('');
    const [prizeLogoFile, setPrizeLogoFile] = useState();
    const [prizeLogoImage, setPrizeLogoImage] = useState('');
    const [isNewLogo, setIsNewLogo] = useState(false);
    const [maxWinCount, setMaxWinCount] = useState('');
    const [minRewardPoint, setMinRewardPoint] = useState('0');
    const [prizeMaxWinCount, setPrizeMaxWinCount] = useState('-1');
    const [prizeRanks, setPrizeRanks] = useState([]);
    const [prizeRankId, setPrizeRankId] = useState("");
    const [rankCoolDown, setRankCoolDown] = useState("");
    const [promotionLogoFile, setPromotionLogoFile] = useState();
    const [promotionLogoImage, setPromotionLogoImage] = useState('');
    const [pageData, setPageData] = useState([]);
    const [countData, setCountData] = useState([]);
    const [memberPrizeLoading, setMemberPrizeLoading] = useState(true);

    const _NOT_BROADCAST_ARR = [
        ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT,
        ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_CANCELLED
    ];

    const _MAX_PERCENTAGE = 100;

    const [prizeColumns, setPrizeColumns] = useState([]);

    const _CUSTOM_ALERT = {
        DELETE_PRIZE: "DeletePrize",
        DELETE_ALL: "DeleteAll",
        BROADCAST: "Broadcast",
        DELETE_MEMBER_PRIZE: "DeleteMemberPrize"
    }

    const _LOADER_SIZE = 30;

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const _prizeTableColumns = [
        {
            Header: t("PRIZE_TYPE"),
            accessor: "prizeTypeString",
        },
        {
            Header: t("PRIZE_NAME"),
            accessor: "prizeName",
        },
        {
            Header: t("PRIZE_AMOUNT"),
            accessor: "prizeAmount",
        },
        {
            Header: t("ACTUAL_NUMBER_OF_PRIZE"),
            accessor: "actualPrizeCount",
        },
        {
            Header: t("NUMBER_OF_PRIZE_TO_DISPLAY"),
            accessor: "displayPrizeCount",
        },
        {
            Header: t("PERCENTAGE"),
            accessor: "winPercentage",
        },
        {
            Header: t("LOWER_BOUND"),
            accessor: "lowerBound",
        },
        {
            Header: t("UPPER_BOUND"),
            accessor: "upperBound",

        },
        {
            Header: t("ACTION"),
            id: "action",
            Cell: ({ row }) => {
                if (memberPrizeLoading) {
                    return <ClipLoader size={_LOADER_SIZE} color={"#2554C7"} />
                }
                else {
                    return (checkIfPermissionExist(AccessRight._PROMOTION_CONTROL_PERMISSION, true) && memberPrize.length == 0) && (
                        <div className="btn-group m-r-5 m-b-5">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle caret color="default">
                                    <i className="fas fa-cog"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => { initializePrizeForm(true, row.original); }}><span className="text-warning">{t("EDIT")}</span></DropdownItem>
                                    <DropdownItem onClick={() => {
                                        setAlertText(t('DELETE_CURRENT_PRIZE_CONFIRMATION'));
                                        setAlertPrizeId(row.original.id);
                                        setCustomAlert(_CUSTOM_ALERT.DELETE_PRIZE);
                                        setAlertVisible(true);
                                    }}><span className="text-warning"> {t("DELETE")}</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    )
                }
            }
        }
    ];

    const _TABLE_COLUMNS = useMemo(
        () => [
            {
                Header: t("FULLNAME"),
                accessor: "memberName",
            },
            {
                Header: t("PHONE_NUMBER"),
                accessor: "memberPhone",
            },
            {
                Header: t("SHOP_NAME"),
                Cell: ({ row }) => {
                    return (<span>{row.original.shopUsername} ({row.original.shopName})</span>);
                }
            },
            {
                Header: t("PRIZE_TYPE"),
                accessor: "prizeType",
            },
            {
                Header: t("PRIZE_NAME"),
                accessor: "prizeName",
            },
            {
                Header: t("PRIZE_VALUE"),
                accessor: "prizeValue",
            },
            {
                Header: t("PRIZE_AMOUNT"),
                accessor: "prizeCount",
            },
            {
                Header: t("NUMBER"),
                accessor: "messageNumber",
            },
            {
                Header: t("STATUS"),
                accessor: "status",
                Cell: ({ row }) => {
                    return <span>{row.original.claimStatus == 1 ? <span class="badge badge-secondary badge-green">{t("CLAIMED")}<br />({row.original.claimTime})</span> : <span class="badge badge-secondary badge-danger">{t("PENDING_CLAIM")}</span>}</span>
                },
            },
            {
                Header: t("ACTION"),
                id: "action",
                Cell: ({ row }) =>
                    (checkIfPermissionExist(AccessRight._PROMOTION_CONTROL_PERMISSION, true) && _NOT_BROADCAST_ARR.includes(broadcastStatus)) && (
                        <div className="btn-group m-r-5 m-b-5">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle caret color="default">
                                    <i className="fas fa-cog"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        onClick={() => {
                                            setAlertText(t('DELETE_CURRENT_PRIZE_CONFIRMATION'));
                                            setAlertPrizeId(row.original.id);
                                            setAlertMemberId(row.original.memberId);
                                            setCustomAlert(_CUSTOM_ALERT.DELETE_MEMBER_PRIZE);
                                            setAlertVisible(true);
                                        }}>
                                        <span className="text-warning">
                                            {t("DELETE")}
                                        </span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    ),
            },
        ],
        [broadcastStatus]
    );

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        /// used for modify the table column of prize after broadcast to show the detail of claim
        let prizeTableColumns = _prizeTableColumns;

        if (!_NOT_BROADCAST_ARR.includes(broadcastStatus)) {
            //Remove the action column of table
            prizeTableColumns.splice(-1, 1);
            prizeTableColumns.push(
                {
                    Header: t("TOTAL_SENT"),
                    accessor: "totalSentCount",
                    Cell: ({ row }) => {
                        if (countData.length > 0) {
                            let rowData = countData[0]['prizeList'].find(x => x.promotionPrizeId == row.original.id);

                            return (
                                <div>{rowData.totalSentCount}</div>
                            );
                        }
                        else {
                            return <ClipLoader size={_LOADER_SIZE} color={"#2554C7"} />
                        }
                    },
                    Footer: () => {
                        return <span><b>{countData[0]?.totalSentCount?.toFixed(2)}</b></span>
                    }
                },
                {
                    Header: t("TOTAL_CLAIM"),
                    accessor: "totalClaimCount",
                    Cell: ({ row }) => {
                        if (countData.length > 0) {
                            let rowData = countData[0]['prizeList'].find(x => x.promotionPrizeId == row.original.id);

                            return (
                                <div>{rowData.totalClaimCount}</div>
                            );
                        }
                        else {
                            return <ClipLoader size={_LOADER_SIZE} color={"#2554C7"} />
                        }
                    },
                    Footer: () => {
                        return <span><b>{countData[0]?.totalClaimCount?.toFixed(2)}</b></span>
                    }
                },
                {
                    Header: t("TOTAL_CLAIM_AMOUNT"),
                    accessor: "totalClaimAmount",
                    Cell: ({ row }) => {
                        if (countData.length > 0) {
                            let rowData = countData[0]['prizeList'].find(x => x.promotionPrizeId == row.original.id);

                            return (
                                <div>{rowData.totalClaimAmount.toFixed(2)}</div>
                            );
                        }
                        else {
                            return <ClipLoader size={_LOADER_SIZE} color={"#2554C7"} />
                        }
                    },
                    Footer: () => {
                        return <span><b>{countData[0]?.totalClaimAmount?.toFixed(2)}</b></span>
                    }
                }
            );
        }

        setPrizeColumns(prizeTableColumns);
    }, [broadcastStatus, memberPrize, countData])

    /// <summary>
    /// Author : YJ
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setPrizeLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewLogo(true);
                setPrizeLogoImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                if (prizes.length > 0) {
                    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PROMOTION_DETAILS_COUNT + '?idString=' +
                        _location.state.id);

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setCountData(responseJson[ApiKey._API_DATA_KEY]);
                    }
                }
            }
            catch (err) {
                /// do nothing
            }
        })();
    }, [prizes]);

    /// <summary>
    /// Author : Lewis
    /// Nelson - Added in relevant data to be initialized
    /// </summary>
    async function init() {
        promotionRegister({ name: 'targetShops' }, { required: 'PLEASE_SELECT_AT_LEAST_ONE_SHOP' });

        var regionJsonResult = await ApiEngine.get(ApiUrl._API_GET_REGION);

        var prizeTypeJsonResult = await ApiEngine.get(ApiUrl._API_GET_PRIZE_TYPES);

        var prizeRankJsonResult = await ApiEngine.get(ApiUrl._API_GET_PRIZE_RANK);

        if (!regionJsonResult[ApiKey._API_SUCCESS_KEY] || !prizeTypeJsonResult[ApiKey._API_SUCCESS_KEY] || !prizeRankJsonResult[ApiKey._API_SUCCESS_KEY]) {
            var errorMessage = regionJsonResult[ApiKey._API_MESSAGE_KEY] || prizeTypeJsonResult[ApiKey._API_MESSAGE_KEY] || prizeRankJsonResult[ApiKey._API_MESSAGE_KEY];
            _dispatch(showResponseMessage(false, t(errorMessage)));
        }
        else {
            const MAX_DURATIONS = promotionMethod ? 15 : 60;
            const DURATION_INCREMENT = promotionMethod ? 1 : 15;

            var durationList = coolDownDurationList;
            var availableRegions = [];
            var availablePrizeTypes = [];
            let prizeRankList = [{ label: "No Rank", value: "" }];

            for (var i = 0; i <= MAX_DURATIONS; i = i + DURATION_INCREMENT) {
                durationList.push({
                    value: i,
                    label: i == 0 ? 'Unlimited' : (i + ' Days')
                });
            }

            setCoolDownDurationList(durationList);

            regionJsonResult[ApiKey._API_DATA_KEY].map((region) => {
                availableRegions.push({
                    label: region['name'],
                    value: region['id']
                });
            });

            prizeTypeJsonResult[ApiKey._API_DATA_KEY].map((type) => {
                availablePrizeTypes.push({
                    label: type['name'],
                    value: type['id'],
                    isSpin: type['isSpin'],
                    isPromotion: type['isPromotion'],
                });
            });

            prizeRankJsonResult[ApiKey._API_DATA_KEY].map((rank) => {
                prizeRankList.push({
                    label: rank['name'],
                    value: rank['id'],
                });
            });

            if (regionJsonResult[ApiKey._API_DATA_KEY].length > 0) {
                getShopsByRegion(availableRegions[0]['value']);
            }

            setPrizeTypes(availablePrizeTypes);
            setRegions(availableRegions);
            setPrizeRanks(prizeRankList);
        }

        if (_location.state) {
            await getLatestPromotionDetails();
        }
        else {
            onChangePromotionMethod(promotionMethod.toString());
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function getLatestPromotionDetails() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_PROMOTIONS + "?Id=" + _location.state.id + "&isSpinPromotion=" + props.isSpinPromotion);

        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
        else {
            setShowPrizeDetails(true);
            setTitle(responseJson[ApiKey._API_DATA_KEY][0]['title']);
            setPromotionId(responseJson[ApiKey._API_DATA_KEY][0]['id']);
            setMessage(responseJson[ApiKey._API_DATA_KEY][0]['content']);
            setEndDate(responseJson[ApiKey._API_DATA_KEY][0]['endDate']);
            setRegionId(responseJson[ApiKey._API_DATA_KEY][0]['regionId']);
            getShopsByRegion(responseJson[ApiKey._API_DATA_KEY][0]['regionId']);
            setIsAppOnly(responseJson[ApiKey._API_DATA_KEY][0]['appOnly']);
            setStartDate(responseJson[ApiKey._API_DATA_KEY][0]['startDate']);
            setPromotionStatus(responseJson[ApiKey._API_DATA_KEY][0]['status']);
            setTargetShops(responseJson[ApiKey._API_DATA_KEY][0]['assignedShopIds']);
            setIsOrderByReward(responseJson[ApiKey._API_DATA_KEY][0]['orderByReward']);
            setCoolDownDuration(responseJson[ApiKey._API_DATA_KEY][0]['coolDownDuration']);
            setActiveDays(responseJson[ApiKey._API_DATA_KEY][0]['minimumMemberActiveDuration']);
            setPrizes(responseJson[ApiKey._API_DATA_KEY][0]['promotionPrizeModels']);
            setPrizeAccumulate(responseJson[ApiKey._API_DATA_KEY][0]['isPrizeAccumulate']);
            setMaxWinCount(responseJson[ApiKey._API_DATA_KEY][0]['memberMaxWinCount']);
            setMinRewardPoint(responseJson[ApiKey._API_DATA_KEY][0]['minRewardAmount']);

            setLoginCountRequired(responseJson[ApiKey._API_DATA_KEY][0]['loginCountRequired']);
            setFreeSpinCount(responseJson[ApiKey._API_DATA_KEY][0]['freeSpinCount']);
            setSpinCountGrant(responseJson[ApiKey._API_DATA_KEY][0]['spinCountGrant']);
            onChangePromotionMethod(responseJson[ApiKey._API_DATA_KEY][0]['isSpinPromotion'].toString(), responseJson[ApiKey._API_DATA_KEY][0]);
            setPromotionLogoImage(responseJson[ApiKey._API_DATA_KEY][0]['backgroundImage']);
            promotionSetValue('targetShops', responseJson[ApiKey._API_DATA_KEY][0]['assignedShopIds']);
        }

        await getMemberPrize();
        await getPromotionStatus();
    }

    /// <summary>
    /// Author : Wind
    /// </summary> 
    const bgImageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setPromotionLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPromotionLogoImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : Lewis
    /// Nelson - apply submission of promotion
    /// YJ - Added checking of member prize
    /// </summary>
    async function createOrUpdatePromotionForm(data) {
        if (memberPrize.length > 0) {
            _dispatch(showResponseMessage(false, t("PLEASE_DELETE_EXISTING_JACKPOT_FIRST")));
            return;
        }

        _dispatch(setBusy());

        var form = {
            title: data.title,
            content: data.message,
            startDate: startDate,
            endDate: endDate,
            appOnly: isAppOnly,
            orderByReward: isOrderbyReward,
            status: promotionStatus,
            spinCountGrant: !stringIsNullOrEmpty(data.spinCountGrant) ? data.spinCountGrant : 0,
            freeSpinCount: !stringIsNullOrEmpty(data.freeSpinCount) ? data.freeSpinCount : 0,
            loginCountRequired: !stringIsNullOrEmpty(data.loginCountRequired) ? data.loginCountRequired : 0,
            targetShops: targetShops.toString(),
            minimumMemberActiveDuration: !stringIsNullOrEmpty(activeDays) ? activeDays : 0,
            coolDownDuration: coolDownDuration,
            isSpinPromotion: promotionMethod,
            regionId: regionId,
            isPrizeAccumulate: prizeAccumulate,
            minRewardAmount: !stringIsNullOrEmpty(data.minRewardPoint) ? data.minRewardPoint : 0,
            memberMaxWinCount: !stringIsNullOrEmpty(data.maxWinCount) ? data.maxWinCount : 0,
        };

        if (!stringIsNullOrEmpty(promotionLogoFile)) {
            form["backgroundImage"] = promotionLogoFile;
        }

        if (!stringIsNullOrEmpty(promotionId)) {
            form.id = promotionId;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_PROMOTION, createMultiPartFormBody(form));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPromotionId(responseJson[ApiKey._API_DATA_KEY]['id']);
            _history.replace({
                pathname: _location.pathname,
                state: {
                    id: responseJson[ApiKey._API_DATA_KEY]["id"]
                }
            })
        }

        setShowPrizeDetails(responseJson[ApiKey._API_SUCCESS_KEY]);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        _dispatch(setIdle());
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function getShopsByRegion(newSelectedRegionId) {
        setTargetShops([]);
        promotionSetValue('targetShops', '');
        setRegionId(newSelectedRegionId);
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP + "&RegionId=" + newSelectedRegionId);

        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
        else {
            var availableShops = [];

            responseJson[ApiKey._API_DATA_KEY].map((shop) => {
                availableShops.push({
                    value: shop['id'],
                    label: shop['username'] + " (" + shop.shopName + ")"
                });
            });

            setShops(availableShops);
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function onChangePromotionMethod(method, data = null) {
        setPromotionMethod(method == 'true');
        if (method.toString() == 'false') {
            promotionUnregister('freeSpinCount');
            promotionUnregister('spinCountGrant');
            promotionUnregister('loginCountRequired');

            setFreeSpinCount('');
            setSpinCountGrant('');
            setLoginCountRequired('');
            setMaxWinCount('');
            setMinRewardPoint('');

            promotionSetValue('freeSpinCount', '');
            promotionSetValue('spinCountGrant', '');
            promotionSetValue('loginCountRequired', '');
            promotionSetValue('maxWinCount', '');
            promotionSetValue('minRewardPoint', '');

            if (data) {
                promotionSetValue('activeDays', data['minimumMemberActiveDuration']);
                setActiveDays(data['minimumMemberActiveDuration']);
            }
        }
        else {
            promotionUnregister('activeDays');
            setActiveDays('');
            promotionSetValue('activeDays', '');

            if (data) {
                promotionSetValue('loginCountRequired', data['loginCountRequired']);
                setLoginCountRequired(data['loginCountRequired']);
                promotionSetValue('spinCountGrant', data['spinCountGrant']);
                setSpinCountGrant(data['spinCountGrant']);
                promotionSetValue('freeSpinCount', data['freeSpinCount']);
                setFreeSpinCount(data['freeSpinCount']);
                promotionSetValue('maxWinCount', data['memberMaxWinCount']);
                setMaxWinCount(data['memberMaxWinCount']);
                promotionSetValue('minRewardPoint', data['minRewardAmount']);
                setMinRewardPoint(data['minRewardAmount']);
            }
        }
    }

    /// <summary>
    /// Author: Nelson
    /// Editted: YJ(Added checking of exist of member prize)
    /// </summary>
    async function createOrUpdatePrizeForm(data) {
        if (memberPrize.length > 0) {
            _dispatch(showResponseMessage(false, t("PLEASE_DELETE_EXISTING_JACKPOT_FIRST")));
            return;
        }

        if (promotionMethod) {
            let editPercentage = 0;
            if (!stringIsNullOrEmpty(prizeId)) {
                var selectedPrize = prizes.find((prize) => {
                    return prize.id == prizeId;
                });
                editPercentage = selectedPrize.winPercentage;
            }
            let totalPercentage = prizes.reduce((sum, currentValue) => {
                return sum + parseFloat(currentValue.winPercentage);
            }, 0);

            if ((totalPercentage - editPercentage + parseFloat(data.winPercentage)) > _MAX_PERCENTAGE) {
                _dispatch(showResponseMessage(false, t("TOTAL_PERCENTAGE_EXCEED_100")));
                return;
            }
        }
        else {
            if (data.upperBound < data.lowerBound) {
                _dispatch(showResponseMessage(false, t("PLEASE_ENSURE_UPPER_NOT_LOWER_THAN_LOWER")));
                return;
            }
            else if ((data.upperBound - data.lowerBound + 1) < data.actualPrizeCount) {
                _dispatch(showResponseMessage(false, t("PLEASE_ENSURE_BOUND_RANGE_NOT_LESSER_THAN_ACTUAL_COUNT")));
                return;
            }
        }

        let params = {
            promotionId: promotionId,
            prizeName: data.prizeName,
            prizeAmount: data.prizeAmount,
            actualPrizeCount: data.actualPrizeCount,
            displayPrizeCount: data.displayPrizeCount,
            winPercentage: data.winPercentage ?? 0,
            spinCountToWin: data.spinCountToWin ?? 0,
            prizeTypeId: prizeTypeId,
            id: !stringIsNullOrEmpty(prizeId) ? prizeId : '',
            lowerBound: data.lowerBound ?? 0,
            upperBound: data.upperBound ?? 0,
            isNewLogo: isNewLogo,
            memberMaxWinCount: data.prizeMaxWinCount ?? -1,
            prizeRankId: !stringIsNullOrEmpty(prizeRankId) ? prizeRankId : "",
            rankCoolDown: !stringIsNullOrEmpty(data.rankCoolDown) ? data.rankCoolDown : "",
        }

        if (!stringIsNullOrEmpty(prizeLogoFile)) {
            params["prizeLogoFile"] = prizeLogoFile;
        }

        if (stringIsNullOrEmpty(prizeLogoImage)) {
            params["prizeLogoImageRemoved"] = true;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_PRIZE,
            createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            initializePrizeForm(false);
            await getLatestPromotionDetails();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function initializePrizeForm(isModalVisible, prizeData = {}) {
        if (Object.keys(prizeData)['length'] == 0) {
            setPrizeId('');
            setDisplayPrizeCount('');
            setSpinCountToWin('');
            setPrizeAmount('');
            setActualPrizeCount('');
            setWinPercentage('');
            setLowerBound('');
            setUpperBound('');
            setPrizeLogoImage('');
            setPrizeLogoFile();
            setIsNewLogo(false);
            setPrizeMaxWinCount('-1');
            setPrizeRankId('');
            setRankCoolDown('');

            onChangePrizeType(prizeTypes.filter(i => i.isSpin == promotionMethod || !promotionMethod == i.isPromotion)[0].value);

            prizeSetValue('winPercentage', '');
            prizeSetValue('displayPrizeCount', '');
            prizeSetValue('spinCountToWin', '');
            prizeSetValue('prizeAmount', '');
            prizeSetValue('actualPrizeCount', '');
            prizeSetValue('lowerBound', '');
            prizeSetValue('upperBound', '');
            prizeSetValue('prizeMaxWinCount', '-1');
            prizeSetValue('prizeRankId', '');
            prizeSetValue('rankCoolDown', '');
        }
        else {
            setPrizeId(prizeData['id']);
            setWinPercentage(prizeData["winPercentage"]);
            setDisplayPrizeCount(prizeData['displayPrizeCount']);
            setSpinCountToWin(prizeData['spinCountToWin']);
            setPrizeAmount(prizeData['prizeAmount']);
            setActualPrizeCount(prizeData['actualPrizeCount']);
            setLowerBound(prizeData['lowerBound']);
            setUpperBound(prizeData['upperBound']);
            setPrizeLogoImage(prizeData['prizeLogo']);
            setPrizeMaxWinCount(prizeData['memberMaxWinCount']);
            let imageFile = (!stringIsNullOrEmpty(prizeData['prizeLogo'])) ? await imagePathToFileObject(prizeData['prizeLogo']) : '';
            setPrizeLogoFile(imageFile);
            setIsNewLogo(false);
            setPrizeRankId(prizeData['prizeRankId']);
            setRankCoolDown(prizeData["rankCoolDown"]);

            onChangePrizeType(prizeData['prizeTypeId'], prizeData['prizeName']);

            prizeSetValue('spinCountToWin', prizeData['spinCountToWin']);
            prizeSetValue('displayPrizeCount', prizeData['displayPrizeCount']);
            prizeSetValue('prizeAmount', prizeData['prizeAmount']);
            prizeSetValue('actualPrizeCount', prizeData['actualPrizeCount']);
            prizeSetValue('winPercentage', prizeData['winPercentage']);
            prizeSetValue('lowerBound', prizeData['lowerBound']);
            prizeSetValue('upperBound', prizeData['upperBound']);
            prizeSetValue("prizeMaxWinCount", prizeData['memberMaxWinCount']);
            prizeSetValue("prizeRankId", prizeData['prizeRankId']);
            prizeSetValue("rankCoolDown", prizeData['rankCoolDown']);
        }
        setPrizeModalVisibility(isModalVisible);
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function onChangePrizeType(typeId, customPrizeName = '') {
        if (stringIsNullOrEmpty(customPrizeName)) {
            switch (parseInt(typeId)) {
                case PrizeType._PRIZE_TYPE_REBATE:
                    customPrizeName = 'REBATE';
                    break;
                case PrizeType._PRIZE_TYPE_CASH:
                    customPrizeName = 'CASH';
                    break;
                case PrizeType._PRIZE_TYPE_REWARD:
                    customPrizeName = 'REWARD';
                    break;
            }
        }

        if (typeId != PrizeType._PRIZE_TYPE_PRIZE) {
            setPrizeRankId("");
            setRankCoolDown("");
        }

        setPrizeTypeId(typeId);
        setPrizeName(customPrizeName);
        prizeClearError('prizeName');
        prizeSetValue('prizeName', customPrizeName);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function getMemberPrize() {
        var getPrizeResponseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PRIZE + "?id=" + _location.state.id,
            { headers: { "skipLoading": true } });
        if (getPrizeResponseJson[ApiKey._API_SUCCESS_KEY]) {
            setMemberPrizeLoading(false);
            setMemberPrize(getPrizeResponseJson[ApiKey._API_DATA_KEY]);
            setDbJackpotExist(getPrizeResponseJson[ApiKey._API_DATA_KEY].length > 0);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function getPrizesNamelist() {
        var responseJson = await ApiEngine.post(ApiUrl._API_GENERATE_MEMBER_PRIZE + "?promotionId=" + _location.state.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setMemberPrize(responseJson[ApiKey._API_DATA_KEY]);
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: Nelson
    /// Editted: YJ(Added checking of exist of member prize)
    /// </summary>
    async function deletePrize() {
        if (memberPrize.length > 0) {
            _dispatch(showResponseMessage(false, t("PLEASE_DELETE_EXISTING_JACKPOT_FIRST")));
            return;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_DELETE_PRIZE + "?prizeId=" + alertPrizeId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await getLatestPromotionDetails();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function deleteMemberPrize() {
        /// this is the model Id which is not yet saved into the db
        const UNSAVED_ID = 0;
        if (alertPrizeId == UNSAVED_ID) {
            setMemberPrize(memberPrize => memberPrize.filter(prize => prize.memberId != alertMemberId));
        }
        else {
            var responseJson = await ApiEngine.post(ApiUrl._API_DELETE_MEMBER_PRIZE + "?id=" + alertPrizeId);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                await getMemberPrize();
            }

            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function saveJackpotList() {
        if (memberPrize.length == 0) {
            _dispatch(showResponseMessage(false, t("NO_JACKPOT_FOUND")));
            return;
        }
        let params = {
            promotionId: promotionId,
            prizes: memberPrize
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MEMBER_PRIZE, params);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await getMemberPrize();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function broadcastJackpotList() {
        var responseJson = await ApiEngine.post(ApiUrl._API_BROADCAST_MEMBER_PRIZE + "?promotionId=" + _location.state.id);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_PROMOTION_MANAGE_PROMOTION)
        }));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function bulkDeleteMemberPrize() {
        var responseJson = await ApiEngine.post(ApiUrl._API_DELETE_MEMBER_PRIZE + "?id=" + _location.state.id + "&isAll=true");

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await getMemberPrize();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function alertHandler() {
        if (customAlert == _CUSTOM_ALERT.DELETE_PRIZE) {
            deletePrize();
        }
        else if (customAlert == _CUSTOM_ALERT.BROADCAST) {
            broadcastJackpotList();
        }
        else if (customAlert == _CUSTOM_ALERT.DELETE_MEMBER_PRIZE) {
            deleteMemberPrize();
        }
        else {
            bulkDeleteMemberPrize();
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function getPromotionStatus() {
        var getPrizeResponseJson = await ApiEngine.get(ApiUrl._API_GET_PROMOTION_STATUS + "?promotionId=" + _location.state.id);
        if (getPrizeResponseJson[ApiKey._API_SUCCESS_KEY]) {
            setBroadcastStatus(getPrizeResponseJson[ApiKey._API_DATA_KEY]["broadcastStatus"]);
        }
    }

    /// <summary>
    /// Author : YJ
    /// used to auto set the bound range for non spin promotion
    /// </summary>
    useEffect(() => {
        if (!promotionMethod && prizeModalVisibility && !stringIsNullOrEmpty(displayPrizeCount)) {
            prizeSetValue('lowerBound', 1);
            prizeSetValue('upperBound', displayPrizeCount);
        }
    }, [displayPrizeCount]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(prizeRankId)) {
            setRankCoolDown("");
        }
    }, [prizeRankId])

    return (
        <div className="bonus-spin">
            {alertVisible && <SweetAlert
                title={t(alertText)}
                type={AlertTypes._WARNING}
                onConfirm={() => { alertHandler(); setAlertVisible(false); }}
                showCancel={true}
                onCancel={() => { setAlertVisible(false); }}
                confirmBtnText={t("CONFIRM")}
                reverseButtons={true}
                cancelBtnCssClass={"btn btn-lg btn-danger"}
                cancelBtnStyle={{ textDecoration: "none" }}>
            </SweetAlert>}
            <div className="row">
                <div className="col-xl-12">
                    <h1 className="page-header">
                        {promotionId ? t("EDIT_BONUS_DETAILS") : t("CREATE_BONUS")}
                        {" "}<span style={{ fontSize: "14px" }}>{broadcastStatus == ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_COMPLETED ?
                            (t("ALL_JACKPOT_BROADCAST_SUCCESSFULLY")) : broadcastStatus == ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_IN_PROGRESS
                                ? (t("JACKPOT_PARTIALLY_BROADCAST")) : broadcastStatus == ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_BROADCAST_CANCELLED ?
                                    (t("ACTIVATE_TO_BROADCAST")) : (memberPrize.length > 0) && (t("PLEASE_REMOVE_JACKPOT_TO_EDIT_PRIZE_AND_PROMOTION"))}</span>
                        <NavigationButton history={_history} />
                    </h1>
                    <Panel>
                        <form onSubmit={promotionHandleSubmit(createOrUpdatePromotionForm)}
                            style={{ padding: "10px", pointerEvents: (memberPrize.length > 0) ? "none" : "unset" }}>
                            <PanelBody>
                                <div className="row m-b-10">
                                    <div className="col-lg-12">
                                        <div className="form-group row">
                                            <div className="col-lg-3">
                                                <label>{t("TITLE")}</label>
                                                <input
                                                    disabled={memberPrize.length > 0 || (promotionMethod && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)}
                                                    type="text"
                                                    defaultValue={title}
                                                    name="title"
                                                    ref={promotionRegister({ required: 'PLEASE_ENTER_PROMOTION_TITLE' })}
                                                    placeholder={t("ENTER_PROMOTION_TITLE")}
                                                    onInput={(e) => { setTitle(e.target.value); }}
                                                    className="form-control form-control-lg" />
                                                {promotionErrors.title && <div className="invalid-feedback">{t(promotionErrors.title.message)}</div>}
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="w-100">{t("START_DATE")}</label>
                                                <DateTime
                                                    value={startDate}
                                                    dateFormat="YYYY-MM-DD"
                                                    closeOnSelect={true}
                                                    timeFormat="HH:mm:ss"
                                                    inputProps={{
                                                        readOnly: true,
                                                        className: 'form-control form-control-lg',
                                                        style: { backgroundColor: 'transparent', cursor: 'pointer' },
                                                        disabled: (memberPrize.length > 0)
                                                    }}
                                                    isValidDate={(e) => {
                                                        return e.isBefore(moment(endDate).add(1, "day").format("YYYY-MM-DD HH:mm:ss"));
                                                    }}
                                                    onChange={(e) => {
                                                        if (moment(e).diff(moment(endDate), 'seconds') > 0) {
                                                            setEndDate(moment(e).format("YYYY-MM-DD HH:mm:ss"));
                                                        }
                                                        setStartDate(moment(e).format("YYYY-MM-DD HH:mm:ss"))
                                                    }} />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="w-100">{t("END_DATE")}</label>
                                                <DateTime
                                                    value={endDate}
                                                    dateFormat="YYYY-MM-DD"
                                                    timeFormat="HH:mm:ss"
                                                    closeOnSelect={true}
                                                    inputProps={{
                                                        readOnly: true,
                                                        className: 'form-control form-control-lg',
                                                        style: { backgroundColor: 'transparent', cursor: 'pointer' },
                                                        disabled: (memberPrize.length > 0)
                                                    }}
                                                    isValidDate={(e) => {
                                                        return e.isAfter(moment(startDate).subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"));
                                                    }}
                                                    onChange={(e) => {
                                                        if (moment(e).diff(moment(startDate), 'seconds') < 0) {
                                                            setStartDate(moment(e).format("YYYY-MM-DD HH:mm:ss"));
                                                        }
                                                        setEndDate(moment(e).format("YYYY-MM-DD HH:mm:ss"))
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {promotionMethod &&
                                    <div className="row m-b-10">
                                        <div className="col-lg-12">
                                            <div className="form-group row">
                                                <div className="col-lg-3">
                                                    <label>{t("LOGIN_COUNT_REQUIRED")}</label>
                                                    <input
                                                        disabled={memberPrize.length > 0 || (promotionMethod && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)}
                                                        type="number"
                                                        name="loginCountRequired"
                                                        ref={
                                                            promotionRegister({
                                                                required: true,
                                                                min: {
                                                                    value: 0,
                                                                    message: 'PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0'
                                                                }
                                                            })
                                                        }
                                                        className="form-control form-control-lg col-lg-12"
                                                        readOnly={!promotionMethod} />
                                                    {promotionErrors.loginCountRequired && <div className="invalid-feedback">{t(getValidationMessage(promotionErrors.loginCountRequired))}</div>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>{t("FREE_SPIN_COUNT")}</label>
                                                    <input
                                                        disabled={memberPrize.length > 0 || (promotionMethod && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)}
                                                        type="number"
                                                        name="freeSpinCount"
                                                        ref={
                                                            promotionRegister({
                                                                required: true,
                                                                min: {
                                                                    value: 0,
                                                                    message: 'PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0'
                                                                }
                                                            })
                                                        }
                                                        className="form-control form-control-lg col-lg-12"
                                                        readOnly={!promotionMethod} />
                                                    {promotionErrors.freeSpinCount && <div className="invalid-feedback">{t(getValidationMessage(promotionErrors.freeSpinCount))}</div>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>{t("SPIN_COUNT_GRANT")}</label>
                                                    <input
                                                        disabled={memberPrize.length > 0 || (promotionMethod && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)}
                                                        type="number"
                                                        name="spinCountGrant"
                                                        ref={
                                                            promotionRegister({
                                                                required: true,
                                                                min: {
                                                                    value: 0,
                                                                    message: 'PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0'
                                                                }
                                                            })
                                                        }
                                                        className="form-control form-control-lg col-lg-12"
                                                        readOnly={!promotionMethod} />
                                                    {promotionErrors.spinCountGrant && <div className="invalid-feedback">{t(getValidationMessage(promotionErrors.spinCountGrant))}</div>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>{t("PRIZE_ACCUMULATE")}</label>
                                                    <div className="switcher" style={{ display: "block" }}>
                                                        <input type="checkbox" name="prizeAccumulate" id="prizeAccumulate"
                                                            disabled={memberPrize.length > 0 || (promotionMethod && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)}
                                                            onChange={(e) => {
                                                                setPrizeAccumulate(e.target.checked);
                                                            }}
                                                            value={false}
                                                            checked={prizeAccumulate}
                                                            ref={promotionRegister} />
                                                        <label htmlFor="prizeAccumulate"></label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>{t("MEMBER_MAX_WIN_COUNT")} ({t("0_FOR_NO_LIMIT")})</label>
                                                    <input
                                                        disabled={memberPrize.length > 0 || (promotionMethod && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)}
                                                        type="number"
                                                        name="maxWinCount"
                                                        ref={
                                                            promotionRegister({
                                                                required: true,
                                                                min: {
                                                                    value: 0,
                                                                    message: 'PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0'
                                                                }
                                                            })
                                                        }
                                                        className="form-control form-control-lg col-lg-12"
                                                        readOnly={!promotionMethod} />
                                                    {promotionErrors.maxWinCount && <div className="invalid-feedback">{t(getValidationMessage(promotionErrors.maxWinCount))}</div>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="w-50">{t("COOL_DOWN")}</label>
                                                    <select
                                                        disabled={memberPrize.length > 0}
                                                        className="form-control form-control-lg col-lg-12"
                                                        name="coolDownDuration"
                                                        disabled={!promotionMethod || memberPrize.length > 0}
                                                        value={coolDownDuration}
                                                        onChange={(e) => { setCoolDownDuration(e.target.value); }}>
                                                        {
                                                            coolDownDurationList.map((duration, index) => {
                                                                return <option value={duration['value']} key={index}>{duration['label']}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>{t("MINIMUM_REWARD")}</label>
                                                    <input
                                                        type="number"
                                                        name="minRewardPoint"
                                                        ref={
                                                            promotionRegister({
                                                                required: true,
                                                                min: {
                                                                    value: 0,
                                                                    message: 'PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0'
                                                                }
                                                            })
                                                        }
                                                        className="form-control form-control-lg col-lg-12" />
                                                    {promotionErrors.minRewardPoint && <div className="invalid-feedback">{t(getValidationMessage(promotionErrors.minRewardPoint))}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!promotionMethod &&
                                    <div className="row m-b-10">
                                        <div className="col-lg-12">
                                            <div className="form-group row">
                                                <div className="col-lg-3">
                                                    <label>{t("MEMBER_ACTIVE_DAY")}</label>
                                                    <input
                                                        disabled={memberPrize.length > 0}
                                                        name="activeDays"
                                                        ref={promotionRegister({
                                                            required: t('ENTER_MEMBER_ACTIVE_DAYS'),
                                                            min: {
                                                                value: 0,
                                                                message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                                            }
                                                        })}
                                                        type="number"
                                                        placeholder={t("ENTER_MEMBER_ACTIVE_DAYS")}
                                                        readOnly={promotionMethod}
                                                        className="form-control form-control-lg col-lg-12"
                                                        defaultValue={activeDays}
                                                        onInput={(e) => { setActiveDays(e.target.value) }} />
                                                    {promotionErrors.activeDays && <div className="invalid-feedback">{t(promotionErrors.activeDays.message)}</div>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="w-50">{t("COOL_DOWN")}</label>
                                                    <select
                                                        disabled={memberPrize.length > 0}
                                                        className="form-control form-control-lg col-lg-12"
                                                        name="coolDownDuration"
                                                        disabled={promotionMethod || memberPrize.length > 0}
                                                        value={coolDownDuration}
                                                        onChange={(e) => { setCoolDownDuration(e.target.value); }}>
                                                        {
                                                            coolDownDurationList.map((duration, index) => {
                                                                return <option value={duration['value']} key={index}>{duration['label']}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row m-b-10">
                                    <div className="col-lg-12">
                                        <div className="form-group row">
                                            <div className="col-lg-3">
                                                <label className="w-50">{t("REGION")}</label>
                                                <select
                                                    disabled={memberPrize.length > 0 || (promotionMethod && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)}
                                                    className="form-control form-control-lg col-lg-12"
                                                    id="region"
                                                    name="region"
                                                    value={regionId}
                                                    onChange={(e) => { getShopsByRegion(e.target.value); }}>
                                                    {
                                                        regions.map((region, index) => {
                                                            return <option value={region['value']} key={index}>{region['label']}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="w-50">{t("STATUS")}</label>
                                                <select
                                                    className="form-control form-control-lg col-lg-12"
                                                    id="promotionStatus"
                                                    name="promotionStatus"
                                                    value={promotionStatus}
                                                    disabled={promotionMethod || memberPrize.length > 0}
                                                    onChange={(e) => {
                                                        setPromotionStatus(e.target.value);
                                                    }}>
                                                    <option value={Status._DISABLED}>{t("INACTIVE")}</option>
                                                    <option value={Status._ENABLED}>{t("ACTIVE")}</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3">
                                                <label>{t("PROMOTION_BACKGROUND")}</label>
                                                <Dropzone accept={'image/*'} onDrop={acceptedFiles => bgImageDrop(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {stringIsNullOrEmpty(promotionLogoImage) && <h4 style={{ color: "grey" }} >{t("UPLOAD_BACKGROUND_IMAGE")}</h4>}
                                                                {!stringIsNullOrEmpty(promotionLogoImage) && <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={promotionLogoImage}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <button type="button" className="mt-2 btn btn-danger"
                                                    onClick={() => {
                                                        setPromotionLogoImage("");
                                                        setPromotionLogoFile();
                                                    }}>
                                                    {t("REMOVE_IMAGE")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <div className="col-lg-12">
                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <label className="w-100">{t("TARGET_SHOP")}</label>
                                                <DualListBox
                                                    disabled={memberPrize.length > 0}
                                                    canFilter
                                                    options={shops}
                                                    selected={targetShops}
                                                    alignActions={"top"}
                                                    name="targetShops"
                                                    onChange={(e) => { promotionSetValue('targetShops', (e == null || e.length == 0) ? '' : e); setTargetShops(e) }} />
                                                {promotionErrors.targetShops && <div className="invalid-feedback">{t(promotionErrors.targetShops.message)}</div>}
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="w-100">{t("CONTENT")}</label>
                                                <textarea
                                                    disabled={memberPrize.length > 0 || (promotionMethod && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)}
                                                    placeholder={t("PLEASE_ENTER_CONTENT")}
                                                    name="message"
                                                    ref={promotionRegister({ required: 'PLEASE_ENTER_CONTENT' })}
                                                    style={{ resize: "none" }}
                                                    defaultValue={message}
                                                    onChange={(e) => { setMessage(e.target.value); }}
                                                    className="form-control"
                                                    aria-label="With textarea">
                                                </textarea>
                                                {promotionErrors.message && <div className="invalid-feedback">{t(promotionErrors.message.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!promotionMethod &&
                                    <div className="row m-b-10">
                                        <div className="col-lg-12">
                                            <div className="form-group row">
                                                <div className="col-lg-8">
                                                    <div className="checkbox checkbox-css checkbox-inline">
                                                        <input type="checkbox" value={isAppOnly} id="isAppOnly" name="isAppOnly" checked={isAppOnly} onChange={(e) => { setIsAppOnly(e.target.checked); }} />
                                                        <label htmlFor="isAppOnly">{t("SEND_TO_APPS_OWNER")}</label>
                                                    </div>
                                                    <div className="checkbox checkbox-css checkbox-inline">
                                                        <input type="checkbox" value={isOrderbyReward} id="isOrderbyReward" name="isOrderbyReward" checked={isOrderbyReward} onChange={(e) => { setIsOrderByReward(e.target.checked); }} />
                                                        <label htmlFor="isOrderbyReward">{t("RANKED_BASED_ON_REWARD")}</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }
                                {(memberPrize.length == 0 || promotionMethod) &&
                                    <div className="row m-b-10">
                                        <button type="submit" className="btn btn-primary">{t("BTN_SUBMIT")}</button>
                                    </div>
                                }
                            </PanelBody>
                        </form>
                    </Panel>
                </div>
            </div>
            {
                showPrizeDetails &&
                <>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row m-b-5">
                                <div className="col-lg-12">
                                    <h1 style={{ "display": "contents", "width": "50%" }}>{t("PRIZES_DETAIL")}</h1>
                                    {((memberPrize.length == 0 && !promotionMethod) || (promotionMethod && broadcastStatus == ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT)) &&
                                        <button onClick={() => { initializePrizeForm(true); }} className="btn btn-primary btn-block col-md-3 float-right" style={{ marginBottom: '10px' }}>
                                            {t("CREATE_PRIZE")}
                                        </button>
                                    }
                                </div>
                            </div>
                            <Panel>
                                <PanelBody>
                                    <div className="row m-b-10">
                                        <div className="col-lg-12">
                                            <ReactTable data={prizes} columns={prizeColumns} renderFooter={true} />
                                        </div>
                                    </div>
                                    {!promotionMethod &&
                                        <PanelFooter>
                                            {_NOT_BROADCAST_ARR.includes(broadcastStatus) && <div className="row">
                                                <div className="col-lg-2 col-md-6">
                                                    <button
                                                        id="btnFilter"
                                                        onClick={() => { getPrizesNamelist(); }}
                                                        className="btn btn-primary btn-block">
                                                        {t("PRIZES_NAMELIST")}
                                                    </button>
                                                </div>
                                            </div>}
                                        </PanelFooter>
                                    }
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                    {!promotionMethod &&
                        <div className="row">
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelBody>
                                        <div className="row m-b-10">
                                            <div className="col-lg-12">
                                                <ReactTable
                                                    customLoader={memberPrizeLoading ? <ClipLoader size={_LOADER_SIZE} color={"#2554C7"} /> : null}
                                                    data={memberPrize}
                                                    columns={_TABLE_COLUMNS}
                                                    exportRequired={true}
                                                />
                                            </div>
                                        </div>
                                    </PanelBody>
                                    <PanelFooter>
                                        {memberPrize.length > 0 &&
                                            <div className="row">
                                                {_NOT_BROADCAST_ARR.includes(broadcastStatus) &&
                                                    <>
                                                        <button
                                                            id="btnFilter"
                                                            onClick={() => {
                                                                setAlertText(t('DELETE_ALL_JACKPOT_CONFIRMATION'));
                                                                setCustomAlert(_CUSTOM_ALERT.DELETE_ALL);
                                                                setAlertVisible(true);
                                                            }}
                                                            className="btn btn-danger">
                                                            {t("DELETE_ALL_JACKPOT")}
                                                        </button> &nbsp;&nbsp;
                                                        <button
                                                            id="btnFilter"
                                                            onClick={() => { saveJackpotList(); }}
                                                            className="btn btn-primary">
                                                            {t("SAVE_JACKPOT_NAMELIST")}
                                                        </button> &nbsp;&nbsp;
                                                    </>
                                                }
                                                {(dbJackpotExist && promotionStatus == Status._ENABLED && broadcastStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_COMPLETED) &&
                                                    <button
                                                        id="btnFilter"
                                                        onClick={() => {
                                                            setAlertText(t('BROADCAST_JACKPOT_CONFIRMATION'));
                                                            setCustomAlert(_CUSTOM_ALERT.BROADCAST);
                                                            setAlertVisible(true);
                                                        }}
                                                        className="btn btn-primary">
                                                        {t("ANNOUNCE_JACKPOT_NAMELIST")}
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </PanelFooter>
                                </Panel>
                            </div>
                        </div>
                    }
                </>
            }
            <div className="row">
                <div className="promotion-modal">
                    <Modal size="lg" isOpen={prizeModalVisibility} toggle={() => { initializePrizeForm(false); }}>
                        <form onSubmit={prizeHandleSubmit(createOrUpdatePrizeForm)}>
                            <ModalHeader toggle={() => { initializePrizeForm(false); }}>{t("MANAGE_PRIZE_DETAILS")}</ModalHeader>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>{t("PRIZE_TYPE")}</label>
                                        <select
                                            className="form-control form-control-lg"
                                            name="prizeTypeId"
                                            value={prizeTypeId}
                                            onChange={(e) => { onChangePrizeType(e.target.value); }}>
                                            {
                                                prizeTypes.map((type, index) => {
                                                    if (promotionMethod == type["isSpin"] || !promotionMethod == type["isPromotion"]) {
                                                        return <option value={type['value']} key={index}>{type['label']}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>{t("PRIZE_NAME")}</label>
                                        <input
                                            type="text"
                                            id="prizeName"
                                            name="prizeName"
                                            readOnly={prizeTypeId != PrizeType._PRIZE_TYPE_PRIZE}
                                            ref={prizeRegister({ required: 'PLEASE_ENTER_PRIZE_NAME' })}
                                            className="form-control form-control-lg"
                                            placeholder={t("PLEASE_ENTER_PRIZE_NAME")}
                                            defaultValue={prizeName}
                                            onInput={(e) => { setPrizeName(e.target.value); }} />
                                        {prizeErrors.prizeName && <div className="invalid-feedback">{t(prizeErrors.prizeName.message)}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>{t("PRIZE_AMOUNT")}</label>
                                        <input
                                            type="number"
                                            name="prizeAmount"
                                            step=".01"
                                            ref={prizeRegister({
                                                required: t('PLEASE_ENTER_PRIZE_AMOUNT'),
                                                min: {
                                                    value: 0,
                                                    message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                                }
                                            })}
                                            className="form-control form-control-lg"
                                            placeholder={t("PLEASE_ENTER_PRIZE_AMOUNT")}
                                            defaultValue={prizeAmount}
                                            onInput={(e) => { setPrizeAmount(e.target.value); }} />
                                        {prizeErrors.prizeAmount && <div className="invalid-feedback">{t(prizeErrors.prizeAmount.message)}</div>}
                                    </div>
                                    {promotionMethod &&
                                        <div className="col-lg-6">
                                            <label>{t("PERCENTAGE")}</label>
                                            <input
                                                type="number"
                                                name="winPercentage"
                                                ref={prizeRegister({
                                                    required: t('PLEASE_ENTER_NUMBER'),
                                                    min: {
                                                        value: 0,
                                                        message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                                    },
                                                    max: {
                                                        value: 100,
                                                        message: t('PLEASE_ENSURE_VALUE_IS_NOT_MORE_THAN_100')
                                                    }
                                                })}
                                                className="form-control form-control-lg"
                                                defaultValue={winPercentage}
                                                step={0.001}
                                                onInput={(e) => { setWinPercentage(e.target.value); }} />
                                            {prizeErrors.winPercentage && <div className="invalid-feedback">{t(prizeErrors.winPercentage.message)}</div>}
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>{t("ACTUAL_NUMBER_OF_PRIZE")}</label>
                                        <input
                                            type="number"
                                            name="actualPrizeCount"
                                            ref={prizeRegister({
                                                required: t('PLEASE_ENTER_ACTUAL_NUMBER_OF_PRIZE'),
                                                min: {
                                                    value: (promotionMethod) ? -1 : 0,
                                                    message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                                }
                                            })}
                                            className="form-control form-control-lg"
                                            placeholder={t("PLEASE_ENTER_ACTUAL_NUMBER_OF_PRIZE")}
                                            defaultValue={actualPrizeCount}
                                            onInput={(e) => { setActualPrizeCount(e.target.value); }} />
                                        {prizeErrors.actualPrizeCount && <div className="invalid-feedback">{t(prizeErrors.actualPrizeCount.message)}</div>}
                                    </div>
                                    <div className="col-lg-6">
                                        <label>{t("NUMBER_OF_PRIZE_TO_DISPLAY")}</label>
                                        <input
                                            type="number"
                                            name="displayPrizeCount"
                                            ref={prizeRegister({
                                                required: t('PLEASE_ENTER_NUMBER_OF_PRIZE_TO_DISPLAY'),
                                                min: {
                                                    value: (promotionMethod) ? -1 : 0,
                                                    message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                                },
                                                min: {
                                                    value: prizeWatch('actualPrizeCount'),
                                                    message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_ACTUAL_PRIZE')
                                                }
                                            })}
                                            className="form-control form-control-lg"
                                            placeholder={t("PLEASE_ENTER_NUMBER_OF_PRIZE_TO_DISPLAY")}
                                            defaultValue={displayPrizeCount}
                                            onInput={(e) => { setDisplayPrizeCount(e.target.value); }} />
                                        {prizeErrors.displayPrizeCount && <div className="invalid-feedback">{t(prizeErrors.displayPrizeCount.message)}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    {promotionMethod && <>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>{t("LOGO")}</label>
                                                <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {stringIsNullOrEmpty(prizeLogoImage) && <h4 style={{ color: "grey" }} >{t("UPLOAD_LOGO")}</h4>}
                                                                {!stringIsNullOrEmpty(prizeLogoImage) && <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={prizeLogoImage}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <button type="button" className="mt-2 btn btn-danger"
                                                    onClick={() => {
                                                        setPrizeLogoImage("");
                                                        setPrizeLogoFile();
                                                    }}>
                                                    {t("REMOVE_IMAGE")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <label>{t("SPIN_COUNT_TO_WIN")}</label>
                                                    <input
                                                        type="number"
                                                        name="spinCountToWin"
                                                        ref={prizeRegister({
                                                            required: 'PLEASE_ENTER_NUMBER',
                                                            min: {
                                                                value: -1,
                                                                message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                                            },
                                                        })}
                                                        className="form-control form-control-lg"
                                                        defaultValue={spinCountToWin}
                                                        onInput={(e) => { setSpinCountToWin(e.target.value); }} />
                                                    {prizeErrors.spinCountToWin && <div className="invalid-feedback">{t(prizeErrors.spinCountToWin.message)}</div>}
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>{t("MEMBER_MAX_WIN_COUNT")} ({t("-1_FOR_NO_LIMIT")})</label>
                                                    <input
                                                        type="number"
                                                        name="prizeMaxWinCount"
                                                        ref={prizeRegister({
                                                            required: 'PLEASE_ENTER_NUMBER',
                                                            min: {
                                                                value: -1,
                                                                message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                                            },
                                                        })}
                                                        defaultValue={prizeMaxWinCount}
                                                        className="form-control form-control-lg" />
                                                    {prizeErrors.prizeMaxWinCount && <div className="invalid-feedback">{t(prizeErrors.prizeMaxWinCount.message)}</div>}
                                                </div>
                                                {prizeTypeId == PrizeType._PRIZE_TYPE_PRIZE && <><div className="col-lg-12">
                                                    <label>{t("PRIZE_RANK")}</label>
                                                    <select
                                                        className="form-control form-control-lg"
                                                        name="prizeRankId"
                                                        value={prizeRankId}
                                                        onChange={(e) => { setPrizeRankId(e.target.value); }}>
                                                        {
                                                            prizeRanks.map((rank, index) => {
                                                                return <option value={rank['value']} key={index}>{rank['label']}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                    {!stringIsNullOrEmpty(prizeRankId) && <div className="col-lg-12">
                                                        <label>{t("RANK_COOLDOWN_DURATION")}</label>
                                                        <input
                                                            type="number"
                                                            name="rankCoolDown"
                                                            ref={prizeRegister({
                                                                required: 'PLEASE_ENTER_NUMBER',
                                                                min: {
                                                                    value: 0,
                                                                    message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                                                },
                                                            })}
                                                            defaultValue={rankCoolDown}
                                                            className="form-control form-control-lg" />
                                                        {prizeErrors.rankCoolDown && <div className="invalid-feedback">{t(prizeErrors.rankCoolDown.message)}</div>}
                                                    </div>}</>}
                                            </div>

                                        </div>
                                    </>
                                    }
                                </div>
                                {
                                    !promotionMethod && <div className="row">
                                        <div className="col-lg-6">
                                            <label>{t("LOWER_BOUND")}</label>
                                            <input
                                                type="number"
                                                name="lowerBound"
                                                ref={prizeRegister({
                                                    required: t('PLEASE_ENTER_LOWER_BOUND'),
                                                    min: {
                                                        value: 1,
                                                        message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_1')
                                                    },
                                                    max: {
                                                        value: prizeWatch('upperBound'),
                                                        message: t('PLEASE_ENSURE_UPPER_NOT_LOWER_THAN_LOWER')
                                                    },
                                                    validate: {
                                                        rangeExceedActual: value => (parseFloat(prizeWatch('upperBound')) - parseFloat(value) + 1) >= parseFloat(prizeWatch('actualPrizeCount'))
                                                            || "PLEASE_ENSURE_BOUND_RANGE_NOT_LESSER_THAN_ACTUAL_COUNT"
                                                    }
                                                })}
                                                className="form-control form-control-lg"
                                                placeholder={t("PLEASE_ENTER_LOWER_BOUND")}
                                                defaultValue={lowerBound}
                                                onInput={(e) => { setLowerBound(e.target.value); }} />
                                            {prizeErrors.lowerBound && <div className="invalid-feedback">{t(prizeErrors.lowerBound.message)}</div>}
                                        </div>
                                        <div className="col-lg-6">
                                            <label>{t("UPPER_BOUND")}</label>
                                            <input
                                                type="number"
                                                name="upperBound"
                                                ref={prizeRegister({
                                                    required: t('PLEASE_ENTER_UPPER_BOUND'),
                                                    min: {
                                                        value: 1,
                                                        message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_1')
                                                    },
                                                    min: {
                                                        value: prizeWatch('lowerBound'),
                                                        message: t('PLEASE_ENSURE_UPPER_NOT_LOWER_THAN_LOWER')
                                                    },
                                                    validate: {
                                                        rangeExceedActual: value => (parseFloat(value) - parseFloat(prizeWatch('lowerBound')) + 1) >= parseFloat(prizeWatch('actualPrizeCount'))
                                                            || "PLEASE_ENSURE_BOUND_RANGE_NOT_LESSER_THAN_ACTUAL_COUNT",
                                                        exceedDisplay: value => parseFloat(value) <= parseFloat(prizeWatch('displayPrizeCount'))
                                                            || 'PLEASE_ENSURE_UPPER_BOUND_NOT_EXCEED_DISPLAY'
                                                    }
                                                })}
                                                className="form-control form-control-lg"
                                                placeholder={t("PLEASE_ENTER_UPPER_BOUND")}
                                                defaultValue={upperBound}
                                                onInput={(e) => { setUpperBound(e.target.value); }} />
                                            {prizeErrors.upperBound && <div className="invalid-feedback">{t(prizeErrors.upperBound.message)}</div>}
                                        </div>
                                    </div>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                                <button type="button" className="btn btn-danger" onClick={() => { initializePrizeForm(false); }}>{t('BTN_CANCEL')}</button>
                            </ModalFooter>
                        </form>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default ManagePromotionDetail;