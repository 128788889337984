import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import NavigationButton from '../../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import { WebUrl, ApiKey, ApiUrl, AccessRight, Role, AlertTypes, EventStatus, PrizeType, EventCalculationMethod } from '../../../util/Constant';
import DualListBox from 'react-dual-listbox';
import { navigateTo, createMultiPartFormBody, createFormBody, stringIsNullOrEmpty, getValidationMessage, arrayGroupByKey } from "../../../util/Util";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import { useForm, Controller } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import DateTime from 'react-datetime';
import moment from 'moment';
import PrizeModal from '../../../components/custom/PrizeModal';
import Select from 'react-select';
import PrizeDistributionModal from '../../../components/custom/PrizeDistributionModal';

/// <summary>
/// Author: Wind
/// </summary> 
const KogEventDetail = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, setValue, unregister, watch, control } = useForm();
    const [supportedLanguage, setSupportedLanguage] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [filteredShopOption, setFilterShopOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [vendorOption, setVendorOption] = useState([]);
    const [prizeList, setPrizeList] = useState([]);
    const [luckyPrizeList, setLuckyPrizeList] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [stopModalVisibility, setStopModalVisibility] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [defaultStatus, setDefaultStatus] = useState(EventStatus._PENDING);
    const [status, setStatus] = useState(EventStatus._PENDING);
    const [stopRemark, setStopRemark] = useState('');
    const [regionOption, setRegionOption] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isLuckyPrize, setIsLuckyPrize] = useState(false);
    const [claimLuckyPrizeModal, setClaimLuckyPrizeModal] = useState(false);
    const [claimPrizeWinner, setClaimPrizeWinner] = useState(null);

    const {
        register: stopModalRegister,
        handleSubmit: stopModalSubmit,
        errors: stopModalErrors,
        setValue: stopModalSetValue,
        unregister: stopModalUnregister,
        clearError: stopModalClearError,
        watch: stopModalWatch
    } = useForm();

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _prizeColumns = useMemo(() => [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: 50
        },
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true
        },
        {
            Header: "PRIZE_NAME",
            accessor: "prizeName",
            disableSortBy: true
        },
        {
            Header: "PRIZE_TYPE",
            accessor: "prizeTypeId",
            disableSortBy: true,
            Cell: ({ row }) => {
                let prizeName = "";

                switch (row.original.prizeTypeId) {
                    case PrizeType._PRIZE_TYPE_REBATE:
                        prizeName = t("REBATE");
                        break;
                    case PrizeType._PRIZE_TYPE_CASH:
                        prizeName = t("CASH");
                        break;
                    case PrizeType._PRIZE_TYPE_REWARD:
                        prizeName = t("REWARD");
                        break;
                    case PrizeType._PRIZE_TYPE_PRIZE:
                        prizeName = t("PRIZE");
                        break;
                }

                return prizeName;
            }
        },
        {
            Header: "PRIZE_IMAGE",
            accessor: "logo",
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.logo ? <img class="mini-program-logo" src={row.original.logo} /> : <div></div>
            }
        },
        {
            Header: "RANK",
            accessor: "rank",
            disableSortBy: true
        },
        {
            Header: "WORTH_AMOUNT",
            accessor: "amount",
            disableSortBy: true
        },
        {
            Header: "QUANTITY",
            accessor: "quantity",
            disableSortBy: true
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                setIsLuckyPrize(row.original.luckyPrize);
                                setSelectedRowData(row.original);
                                setModalVisibility(true);
                            }}>
                                <span>{t('EDIT')}</span>
                            </DropdownItem>
                            {
                                row.original.luckyPrize &&
                                <DropdownItem onClick={() => {
                                    setClaimLuckyPrizeModal(row.original);
                                }}>
                                    <span className="text-green">{t('CLAIM_PRIZE')}</span>
                                </DropdownItem>
                            }
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO') + t('DELETE') + " " + row.original.name + "?",
                                    onConfirm: () => { deletePrize(row.original.id); }
                                }));
                            }}>
                                <span className="text-danger">{t('DELETE')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [prizeList]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getSuppportedLanguageAndLoadData();
        register({ name: 'startDate' }, { required: "PLEASE_ENTER_START_DATE" });
        register({ name: 'endDate' }, { required: "PLEASE_ENTER_END_DATE" });
        register({ name: 'autoEnroll' });

        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"));
        setValue("autoEnroll", true);
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (!firstLoad) {
            handleSubmit(onSubmit)();
        }
    }, [status]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (!claimLuckyPrizeModal) {
            setClaimPrizeWinner(false);
        }
    }, [claimLuckyPrizeModal]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (shopOption.length > 0) {
            var shopOptionsByRegion = [];

            regionOption.filter(i => selectedRegion.includes(i.value)).map(region => {
                var shopList = [];

                shopOption.filter(s => s.regionId == region.value).map(shop => {
                    shopList.push({ label: shop.username + (shop.shopName ? " (" + shop.shopName + ")" : ""), value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: region.label,
                    value: region.value,
                    options: shopList
                })
            })

            setFilterShopOption(shopOptionsByRegion);

            // First load always get the API selected shop
            if (!firstLoad) {
                setSelectedShop([]);
            }
        }
    }, [selectedRegion.length]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getSuppportedLanguageAndLoadData() {
        try {
            var languageList = [];
            var langResponseJson = await ApiEngine.get(ApiUrl._API_GET_SUPPORTED_LANGUAGE);
            if (langResponseJson[ApiKey._API_SUCCESS_KEY]) {
                languageList = langResponseJson[ApiKey._API_DATA_KEY].map(item => ({ ...item, file: {}, preview: null }));
            }

            var vendorResponse = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

            if (vendorResponse[ApiKey._API_SUCCESS_KEY]) {
                var vendorList = [];

                vendorResponse[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                    vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
                });

                setVendorOption(vendorList);
            }

            var shopResponse = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP);

            if (shopResponse[ApiKey._API_SUCCESS_KEY]) {
                setShopOption(shopResponse[ApiKey._API_DATA_KEY]);
            }

            var regionResponseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);

            if (regionResponseJson[ApiKey._API_SUCCESS_KEY]) {
                var regionList = [];

                regionResponseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                    regionList.push({ label: region.name, value: region.id });
                });

                setRegionOption(regionList);
            }

            if (_location.state && _location.state.id) {
                var responseJson = await ApiEngine.get(ApiUrl._API_EVENT + "/" + _location.state.id);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setValue("startDate", moment(responseJson[ApiKey._API_DATA_KEY].startDate).format("YYYY-MM-DD HH:mm:ss"));
                    setValue("endDate", moment(responseJson[ApiKey._API_DATA_KEY].endDate).format("YYYY-MM-DD HH:mm:ss"));
                    setValue("autoEnroll", responseJson[ApiKey._API_DATA_KEY].autoEnroll);
                    setValue("calculationMethod", responseJson[ApiKey._API_DATA_KEY]["calculationMethod"]);
                    setSelectedVendor(responseJson[ApiKey._API_DATA_KEY].vendorList?.map(v => v.vendorId));
                    setSelectedRegion(responseJson[ApiKey._API_DATA_KEY].regionList?.map(r => r.regionId));
                    setSelectedShop(responseJson[ApiKey._API_DATA_KEY].shopList?.map(s => s.userId));
                    setDefaultStatus(responseJson[ApiKey._API_DATA_KEY].status);
                    setStatus(responseJson[ApiKey._API_DATA_KEY].status);
                    languageList = languageList.map(item => ({
                        ...item, eventName: responseJson[ApiKey._API_DATA_KEY].nameLocalizations.find(l => l.languageId == item.id).value,
                        description: responseJson[ApiKey._API_DATA_KEY].descriptionLocalizations.find(l => l.languageId == item.id).value,
                        preview: responseJson[ApiKey._API_DATA_KEY].bannerLocalizations.find(l => l.languageId == item.id).value,
                        disclaimer: responseJson[ApiKey._API_DATA_KEY].disclaimerLocalizations.find(l => l.languageId == item.id)?.value,
                    }));
                }
                setSelectedEvent(responseJson[ApiKey._API_DATA_KEY]);
                getPrizeList();
            }

            setFirstLoad(false);
            setSupportedLanguage(languageList);
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: err
            }));
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const updateImage = (acceptedFiles, id) => {
        let newItem = [...supportedLanguage];
        let index = newItem.findIndex(i => i.id == id);

        if (acceptedFiles.length != 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();

            newItem[index].file = file;

            reader.onloadend = () => {
                newItem[index].preview = reader.result;
                setSupportedLanguage(newItem);
            }

            reader.readAsDataURL(file);
        }
        else {
            newItem[index].preview = null;
            newItem[index].file = {};
            setSupportedLanguage(newItem);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getPrizeList() {
        let apiUrl = ApiUrl._API_EVENT_PRIZE + '?EventId=' + _location.state.id + "&IsLuckyPrize=false";

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPrizeList(responseJson[ApiKey._API_DATA_KEY].data);
        }

        apiUrl = ApiUrl._API_EVENT_PRIZE + '?EventId=' + _location.state.id + "&IsLuckyPrize=true";

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setLuckyPrizeList(responseJson[ApiKey._API_DATA_KEY].data);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function updateEventConfirmation(action) {
        let actionName = '';
        switch (action) {
            case EventStatus._PAUSE:
                actionName = t('PAUSE');
                break;
            case EventStatus._START:
                actionName = t('KICK_START');
                break;
            case EventStatus._END:
                actionName = t('END');
                break;
        }
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            title: t('ARE_YOU_SURE'),
            showCancel: true,
            content: t('ARE_YOU_SURE_YOU_WANT_TO') + actionName + t('THIS_EVENT'),
            onConfirm: () => { setStatus(action) }
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data) => {
        var responseJson;
        let params = {
            name: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}_eventName`] }))),
            description: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}_description`] }))),
            disclaimer: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}_disclaimer`] }))),
            startDate: data.startDate,
            endDate: data.endDate,
            vendorIds: selectedVendor,
            autoEnroll: data.autoEnroll,
            status: status,
            regionIds: selectedRegion,
            shopIds: selectedShop,
            calculationMethod: data.calculationMethod
        };

        if (status == EventStatus._END) {
            params['remark'] = stopRemark;
        }

        for (var i = 0; i < supportedLanguage.length; i++) {
            if (stringIsNullOrEmpty(supportedLanguage[i]["preview"])) {
                _dispatch(showMessage({
                    type: AlertTypes._ERROR,
                    content: t("PLEASE_UPLOAD_BANNER_IMAGE") + ' (' + supportedLanguage[i]['name'] + ')'
                }));
                return;
            }
            params[`${supportedLanguage[i]["id"]}`] = supportedLanguage[i]["file"];
        }

        if (_location.state && _location.state.id) {
            responseJson = await ApiEngine.put(ApiUrl._API_EVENT + '/' + _location.state.id, createMultiPartFormBody(params));
        }
        else {
            responseJson = await ApiEngine.post(ApiUrl._API_EVENT, createMultiPartFormBody(params));
        }

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _history.replace({
                pathname: _location.pathname,
                state: {
                    id: responseJson[ApiKey._API_DATA_KEY]["id"]
                }
            })
        }
        else {
            setStatus(defaultStatus);
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => { }
        }));
    };

    /// <summary>
    /// Author: Wind 
    /// </summary> 
    const stopEventForm = async (data) => {
        setStopModalVisibility(false);
        setStopRemark(data.stopRemark);
        setStatus(EventStatus._END);
        setSelectedEvent({ ...selectedEvent, status: EventStatus._END });
    };

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function deletePrize(id) {
        var responseJson = await ApiEngine.delete(ApiUrl._API_EVENT_PRIZE + "/" + id);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && getPrizeList()
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function stopEventConfirmation() {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            title: t('ARE_YOU_SURE'),
            showCancel: true,
            content: t('DO_YOU_WISH_TO_STOP_THE_EVENT'),
            onConfirm: () => {
                if (moment(watch('endDate')).isAfter(moment())) {
                    setStopModalVisibility(true);
                }
                else {
                    setSelectedEvent({ ...selectedEvent, status: EventStatus._END });
                    setStatus(EventStatus._END);
                }
            }
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function searchEventPrizeWinner() {
        setClaimPrizeWinner(null);

        var responseJson = await ApiEngine.get(ApiUrl._API_SEARCH_EVENT_PRIZE_WINNER + "?memberUsername=" + watch("searchMemberInput") + "&eventId=" + claimLuckyPrizeModal.eventId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setClaimPrizeWinner(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            }));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function claimPrize() {
        setClaimPrizeWinner(null);

        let params = {
            memberId: claimPrizeWinner.userId,
            prizeId: claimLuckyPrizeModal.id
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_DISTRIBUTE_LUCKY_PRIZE, params);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            responseJson = await ApiEngine.post(`${ApiUrl._API_SINGLE_DISTRIBUTE}?eventId=${claimPrizeWinner.eventId}&memberId=${claimPrizeWinner.userId}&luckyPrize=true`);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(showMessage({
                    type: AlertTypes._SUCCESS,
                    content: t(responseJson[ApiKey._API_MESSAGE_KEY])
                }));

                setClaimLuckyPrizeModal(false);
                setIsLuckyPrize(true);
                setModalData(selectedEvent);
            }
            else {
                _dispatch(showMessage({
                    type: AlertTypes._ERROR,
                    content: t(responseJson[ApiKey._API_MESSAGE_KEY])
                }));
            }
        }
        else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            }));
        }
    }

    return (
        <div>
            <h1 className="page-header">{_location.state ? t("EDIT_KOG_EVENT") : t("CREATE_KOG_EVENT")}<NavigationButton history={_history} /></h1>
            <Panel>
                <PanelHeader noButton>{t("KOG_EVENT_DETAIL")}</PanelHeader>
                <PanelBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {
                                supportedLanguage && supportedLanguage.map((item, index) => {
                                    return (
                                        <div className="col-lg-3" key={index}>
                                            <div className="form-group">
                                                <label>{t("NAME") + " (" + item.name + ")"}</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    id={item.id + '_eventName'}
                                                    name={item.id + '_eventName'}
                                                    defaultValue={item.eventName}
                                                    placeholder={t("ENTER_EVENT_NAME") + " (" + item.name + ")"}
                                                    ref={register({ required: "PLEASE_ENTER_NAME" })} />
                                                {errors[`${item.id + '_eventName'}`] && <div className="invalid-feedback">{t(getValidationMessage(errors[`${item.id + '_eventName'}`]))}</div>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{
                                            className: 'form-control form-control-lg bg-' + (status != EventStatus._PENDING ? 'grey' : 'white'),
                                            readOnly: true,
                                            disabled: (status != EventStatus._PENDING)
                                        }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={watch('startDate')}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={watch('endDate')}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("AUTO_ENROLL")}</label><br />
                                    <div className="switcher">
                                        <input
                                            disabled={status != EventStatus._PENDING}
                                            type="checkbox"
                                            name="autoEnroll"
                                            id="autoEnroll"
                                            onChange={(e) => setValue('autoEnroll', e.target.checked)}
                                            checked={watch('autoEnroll')} />
                                        <label htmlFor="autoEnroll"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("CALCULATION_METHOD")}</label><br />
                                    <select name="calculationMethod" ref={register} disabled={status != EventStatus._PENDING}
                                        className="form-control form-control-lg">
                                            <option value={EventCalculationMethod._NORMAL}>{t("NORMAL")}</option>
                                            <option value={EventCalculationMethod._WIN_DIVIDE_BET}>{t("WIN_DIVIDE_BET")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                supportedLanguage && supportedLanguage.map((item, index) => {
                                    return (
                                        <div className="col-lg-3" key={index}>
                                            <div className="form-group">
                                                <label>{t("DESCRIPTION") + " (" + item.name + ")"}</label>
                                                <textarea
                                                    className="form-control form-control-lg"
                                                    rows="8"
                                                    id={item.id + '_description'}
                                                    name={item.id + '_description'}
                                                    defaultValue={item.description}
                                                    ref={register({ required: "PLEASE_ENTER_CONTENT" })}
                                                    placeholder={t("ENTER_DESCRIPTION") + " (" + item.name + ")"} />
                                                {errors[`${item.id + '_description'}`] && <div className="invalid-feedback">{t(getValidationMessage(errors[`${item.id + '_description'}`]))}</div>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="row">
                            {
                                supportedLanguage && supportedLanguage.map((item, index) => {
                                    return (
                                        <div className="col-lg-3" key={index}>
                                            <div className="form-group">
                                                <label>{t("DISCLAIMER") + " (" + item.name + ")"}</label>
                                                <textarea
                                                    className="form-control form-control-lg"
                                                    rows="8"
                                                    id={item.id + '_disclaimer'}
                                                    name={item.id + '_disclaimer'}
                                                    defaultValue={item.disclaimer}
                                                    ref={register({ required: "PLEASE_ENTER_CONTENT" })}
                                                    placeholder={t("ENTER_DISCLAIMER") + " (" + item.name + ")"} />
                                                {errors[`${item.id + '_disclaimer'}`] && <div className="invalid-feedback">{t(getValidationMessage(errors[`${item.id + '_disclaimer'}`]))}</div>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="row">
                            {
                                supportedLanguage && supportedLanguage.map((item, index) => {
                                    return (
                                        <div className="col-lg-3" key={index}>
                                            <div className="form-group">
                                                <label>{t("BANNER") + " (" + item.name + ")"}</label>
                                                <Dropzone accept={'image/*'} onDrop={acceptedFiles => updateImage(acceptedFiles, item.id)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {!item.preview && <h4 style={{ color: "grey" }}>{t("UPLOAD_BANNER") + " (" + item.name + ")"}</h4>}
                                                                {item.preview && <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={item.preview}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <button type="button" className="mt-2 btn btn-danger"
                                                    onClick={() => {
                                                        updateImage([], item.id);
                                                    }}>
                                                    {t("REMOVE_IMAGE")}
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("REGION")}</label>
                                    <DualListBox
                                        disabled={status != EventStatus._PENDING}
                                        canFilter
                                        options={regionOption}
                                        selected={selectedRegion}
                                        onChange={(e) => {
                                            setSelectedRegion(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("SHOPS")}</label>
                                    <DualListBox
                                        disabled={status != EventStatus._PENDING}
                                        canFilter
                                        name="shops"
                                        selected={selectedShop}
                                        options={filteredShopOption}
                                        onChange={(e) => {
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <DualListBox
                                        disabled={status != EventStatus._PENDING}
                                        canFilter
                                        name="game"
                                        selected={selectedVendor}
                                        options={vendorOption}
                                        onChange={(e) => {
                                            setSelectedVendor(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    status != EventStatus._END &&
                                    <button type="submit" className="btn btn-primary m-r-5">{t("SAVE")}</button>
                                }
                                {
                                    status == EventStatus._PENDING &&
                                    <>
                                        <button type="button" className="btn btn-success m-r-5" onClick={() => updateEventConfirmation(EventStatus._START)}>{t("KICK_START")}</button>
                                    </>
                                }
                                {
                                    _location.state && _location.state.id &&
                                    <>
                                        {
                                            status == EventStatus._START &&
                                            <>
                                                <button type="button" className="btn btn-warning m-r-5" onClick={() => updateEventConfirmation(EventStatus._PAUSE)}>{t("PAUSE")}</button>
                                                <button type="button" className="btn btn-danger m-r-5" onClick={() => { stopEventConfirmation(); }}>{t("STOP")}</button>
                                            </>
                                        }
                                        {
                                            status == EventStatus._PAUSE &&
                                            <>
                                                <button type="button" className="btn btn-success m-r-5" onClick={() => updateEventConfirmation(EventStatus._START)}>{t("KICK_START")}</button>
                                                <button type="button" className="btn btn-danger m-r-5" onClick={() => { stopEventConfirmation(); }}>{t("STOP")}</button>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            {
                _location.state && _location.state.id &&
                <Panel>
                    <PanelHeader noButton>{t('PRIZE')} &nbsp; &nbsp;
                        {
                            status != EventStatus._END &&
                            <button type="button" className="btn btn-primary"
                                onClick={() => {
                                    setIsLuckyPrize(false);
                                    setModalVisibility(true);
                                }}><i className="fa fa-plus-circle m-r-10"></i>
                                {t("ADD_PRIZE")}
                            </button>
                        }
                        <button
                            type="button"
                            className="btn btn-success m-l-5"
                            onClick={() => {
                                setModalData(selectedEvent)
                            }}
                        >
                            {t("VIEW_PRIZE_WINNER")}
                        </button>
                    </PanelHeader>
                    <PanelBody>
                        <ReactTable data={prizeList} columns={_prizeColumns} />
                    </PanelBody>
                </Panel>
            }
            {
                _location.state && _location.state.id &&
                <Panel>
                    <PanelHeader noButton>{t('LUCKY_PRIZE')} &nbsp; &nbsp;
                        {
                            status != EventStatus._END &&
                            <button type="button" className="btn btn-primary"
                                onClick={() => {
                                    setIsLuckyPrize(true);
                                    setModalVisibility(true);
                                }}><i className="fa fa-plus-circle m-r-10"></i>
                                {t("ADD_PRIZE")}
                            </button>
                        }
                        <button
                            type="button"
                            className="btn btn-success m-l-5"
                            onClick={() => {
                                setIsLuckyPrize(true);
                                setModalData(selectedEvent)
                            }}
                        >
                            {t("VIEW_PRIZE_WINNER")}
                        </button>
                    </PanelHeader>
                    <PanelBody>
                        <ReactTable data={luckyPrizeList} columns={_prizeColumns} />
                    </PanelBody>
                </Panel>
            }
            <PrizeModal
                rowData={selectedRowData}
                eventId={_location.state ? _location.state.id : ''}
                isLuckyPrize={isLuckyPrize}
                onFinish={(requiredUpdate, data) => {
                    if (requiredUpdate) {
                        getPrizeList();
                    }

                    setSelectedRowData();
                    setModalVisibility(false);
                }}
                visible={modalVisibility} />
            <Modal isOpen={stopModalVisibility} centered toggle={() => { setStopModalVisibility(false) }} size="lg">
                <form onSubmit={stopModalSubmit(stopEventForm)}>
                    <ModalHeader toggle={() => { setStopModalVisibility(false) }}>{t('DO_YOU_WISH_TO_STOP_THE_EVENT')}</ModalHeader>
                    <ModalBody>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>{t("REMARK") + " (" + t('REASON') + ")"}</label>
                                <textarea
                                    className="form-control form-control-lg"
                                    rows="8"
                                    id={'stopRemark'}
                                    name={'stopRemark'}
                                    defaultValue={''}
                                    ref={stopModalRegister({ required: "PLEASE_ENTER_REMARK" })}
                                    placeholder={t("ENTER_REMARK")} />
                                {stopModalErrors['stopRemark'] && <div className="invalid-feedback">{t(getValidationMessage(stopModalErrors['stopRemark']))}</div>}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setStopModalVisibility(false); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
            <PrizeDistributionModal
                isLuckyPrize={isLuckyPrize}
                selectedEvent={modalData}
                onFinish={(event) => {
                    setIsLuckyPrize(false);
                    setModalData(event);
                }}
            />
            <Modal
                isOpen={claimLuckyPrizeModal}
                centered
                toggle={() => {
                    setClaimLuckyPrizeModal(false)
                }}
                size="lg"
            >
                <ModalHeader toggle={() => {
                    setClaimLuckyPrizeModal(false)
                }}>{t('CLAIM_PRIZE')} - {claimLuckyPrizeModal.prizeName}</ModalHeader>
                <ModalBody>
                    <div className="col-lg-12">
                        <div className="form-group m-b-15 ">
                            <label>{`${t('SEARCH_BY')} ${t('PHONE_NUMBER')}`}</label>
                            <div style={{
                                display: "flex",
                                flexDirection: "row"
                            }}>
                                <input className="input-search form-control form-control-lg m-b-5"
                                    name="searchMemberInput"
                                    type="text"
                                    ref={register}
                                    onInput={(e) => setValue("searchMemberInput", e.target.value)}
                                />
                                <button
                                    type="button"
                                    id="btn_search"
                                    className="btn btn-primary btn-action m-l-5"
                                    onClick={() => searchEventPrizeWinner()}
                                >
                                    {t('SEARCH')}
                                </button>
                            </div>
                        </div>
                        {
                            claimPrizeWinner &&
                            <div className="form-group m-b-15 ">
                                <label>{t('PHONE_NUMBER')}:</label> {`${claimPrizeWinner.username} (${claimPrizeWinner.regionName})`}
                                <button
                                    type="button"
                                    className="btn btn-success btn-action m-l-5"
                                    onClick={() => {
                                        _dispatch(showMessage({
                                            type: AlertTypes._WARNING,
                                            title: t('ARE_YOU_SURE'),
                                            showCancel: true,
                                            content: t('CLAIM_PRIZE'),
                                            onConfirm: () => {
                                                claimPrize();
                                            }
                                        }));
                                    }}
                                >
                                    {t('CLAIM_PRIZE')}
                                </button>
                            </div>
                        }

                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={() => {
                        setClaimLuckyPrizeModal(false);
                    }}>{t('BTN_CANCEL')}</button>
                </ModalFooter>
            </Modal>
        </div >
    )
}

export default KogEventDetail;