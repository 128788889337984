import React, { useRef, useEffect, useState } from 'react';
import { Panel, PanelBody } from 'components/panel/panel.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import { AlertTypes, ApiKey, FilterStatus, WebUrl } from 'util/Constant.js';
import { ApiUrl, Status } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, isObjectEmpty } from "../../util/Util";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import AccountStatusModal from "../../components/custom/AccountStatusModal";
import ReloadDetailModal from 'components/custom/ReloadDetailModal';
import PasswordConfirmationModal from "../../components/custom/PasswordConfirmationModal";
import { getShopCredit } from 'redux/AuthAction';

/// <summary>
/// Author: YJ
/// </summary>
const PlayerReload = props => {
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _history = useHistory();
    const { t } = useTranslation();
    const { register, handleSubmit, errors, unregister, setValue, getValues } = useForm();
    const [gameList, setGameList] = useState([]);
    const [selectedUsername, setSelectedUsername] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(false);
    const [selectedRemark, setSelectedRemark] = useState("");
    const [accountDetail, setAccountDetail] = useState({});
    const [modalVisibility, setModalVisibility] = useState(false);
    const [setScoreUrl, setSetScoreUrl] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState(false);

    const _BTN_TYPE = {
        ADD: "ADD",
        RELOAD: "RELOAD",
        BLOCK: "BLOCK",
        DELETE: "DELETE",
        RESET: "RESET"
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        init();
    }, [_location.state]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function init() {
        if (!stringIsNullOrEmpty(_location.state)) {
            let apiUrl = ApiUrl._API_GET_PLAYER_BY_ID + "?id=" + _location.state.id

            var responseJson = await ApiEngine.get(apiUrl);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let games = [];
                let products = [{
                    value: responseJson[ApiKey._API_DATA_KEY]["vendorCode"],
                    label: responseJson[ApiKey._API_DATA_KEY]["vendorName"],
                    displayName: responseJson[ApiKey._API_DATA_KEY]["vendorShortName"]
                }];
                let mobiles = [{
                    value: responseJson[ApiKey._API_DATA_KEY]["username"],
                    label: responseJson[ApiKey._API_DATA_KEY]["displayUsername"],
                    status: responseJson[ApiKey._API_DATA_KEY]["status"],
                    remark: responseJson[ApiKey._API_DATA_KEY]["remark"]
                }];

                games.push({ index: 1, productOptions: products, mobileOptions: mobiles });
                setGameList(games);
                setSelectedRemark(responseJson[ApiKey._API_DATA_KEY]["remark"]);
                setSelectedStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                setSelectedUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
                setSelectedVendor(responseJson[ApiKey._API_DATA_KEY]["vendorCode"])
            }
            else {
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
            }
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    const btnHandler = (type) => {
        let options = { type: AlertTypes._WARNING, title: t('ARE_YOU_SURE'), showCancel: true };
        let useCustomAlert = false;

        switch (type) {
            case _BTN_TYPE.BLOCK: {
                setAccountDetail({
                    fromStatus: selectedStatus ? Status._ENABLED : Status._DISABLED,
                    toStatus: !selectedStatus ? Status._ENABLED : Status._DISABLED,
                    remark: selectedRemark,
                    btnType: _BTN_TYPE.BLOCK
                });
                useCustomAlert = true;
                break;
            }
            case _BTN_TYPE.DELETE: {
                setAccountDetail({
                    fromStatus: selectedStatus ? Status._ENABLED : Status._DISABLED,
                    toStatus: Status._DELETED,
                    remark: selectedRemark,
                    btnType: _BTN_TYPE.DELETE
                });
                useCustomAlert = true;
                break;
            }
            case _BTN_TYPE.RESET:
                options = {
                    ...options, content: t('PLEASE_CONFIRM_PLAYER_ACTION', { action: t(type) }),
                    showCancel: true,
                    onConfirm: () => playerOperation(type)
                }
                break;
        }

        if (!useCustomAlert) {
            _dispatch(showMessage(options));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function playerOperation(type, remark = "") {
        let apiUrl = "";

        if (type == _BTN_TYPE.RESET) {
            apiUrl = ApiUrl._API_SHOP_RESET_PASSWORD + "?code=" + selectedVendor + "&playerUsername=" + selectedUsername;
        }
        else if (type == _BTN_TYPE.BLOCK) {
            apiUrl = ApiUrl._API_SHOP_SET_PLAYER_STATUS + "?code=" + selectedVendor + "&playerUsername=" + selectedUsername + "&status=" + (selectedStatus ? "false" : "true") + "&remark=" + remark;
        }
        else if (type == _BTN_TYPE.DELETE) {
            apiUrl = ApiUrl._API_SHOP_DELETE_PLAYER + "?code=" + selectedVendor + "&playerUsername=" + selectedUsername + "&remark=" + remark;
        }
        else {
            return;
        }

        var responseJson = await ApiEngine.post(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (type != _BTN_TYPE.DELETE) {
                _dispatch(showResponseMessage(true, t(responseJson[ApiKey._API_MESSAGE_KEY])));
                init();
            }
            else {
                _dispatch(showMessage({
                    type: AlertTypes._SUCCESS,
                    content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                    onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_SHOP_SEARCH)
                }));
            }
        }
        else {
            _dispatch(showResponseMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function setScoreConfirmation(currentSetScoreUrl) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            title: t('ARE_YOU_SURE'),
            showCancel: true,
            content: t('PLEASE_CONFIRM_SET_SCORE'),
            onConfirm: () => {
                setScoreHandler(currentSetScoreUrl);
            },
            onCancel: () => passwordConfirmationCleanup()
        }));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function passwordConfirmationCleanup() {
        setPasswordConfirmation(false);
        setSetScoreUrl("");
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function setScoreHandler(setScoreUrl) {
        var responseJson = await ApiEngine.post(setScoreUrl);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        passwordConfirmationCleanup();
    }

    return <>
        <h1 class="page-header">{t('RELOAD')} {selectedUsername}</h1>
        <AccountStatusModal
            visible={!isObjectEmpty(accountDetail)}
            detail={accountDetail}
            onFinish={(isConfirm, remark) => {
                if (isConfirm) {
                    playerOperation(accountDetail.btnType, remark)
                }
                setAccountDetail({});
            }} />
        <Panel className="reload-panel">
            <PanelBody>
                <div class="reload-row">
                    <div class="index"><b>#</b></div>
                    <div class="product"><b>{t('PRODUCT')}</b></div>
                    {/* <div class="amount"><b>{t('AMOUNT')}</b></div> */}
                    <div class="mobile"><b>{t('RELOAD_MOBILE')}</b></div>
                </div>
                <form onSubmit={handleSubmit()}>
                    {
                        gameList.map((game, key) => {
                            return (
                                <div class="reload-row">
                                    <div class="index">{key + 1}</div>
                                    <div class="product">
                                        <select class="form-control form-control-lg" name={"product[" + key + "]"} ref={register}>
                                            {
                                                game.productOptions.map((option, key) => {
                                                    return <option key={key} value={option.value}>{option.label}</option>
                                                })
                                            }
                                        </select>
                                        <input type="hidden" id={"status[" + key + "]"} name={"status[" + key + "]"} ref={register}
                                            defaultValue={game.mobileOptions.length > 0 ? game.mobileOptions[0].status : FilterStatus._ACTIVE} />
                                    </div>
                                    {/* <div class="amount">
                                        <input type="text" name={"amount[" + key + "]"} ref={register} class="form-control form-control-lg" placeholder="0" />
                                    </div> */}
                                    <div class="mobile">
                                        <select class="form-control form-control-lg" name={"player[" + key + "]"} ref={register} onChange={(e) => {
                                            setValue("status[" + key + "]", game.mobileOptions.filter(i => i.value == e.target.value)[0].status)
                                        }}>
                                            {
                                                game.mobileOptions.map((option, key) => {
                                                    return <option key={key} value={option.value}>{option.label}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div class="options">
                                        <button type="button" style={{ marginRight: "5px" }} class="btn btn-lg btn-primary" onClick={() => {
                                            setModalVisibility(true);
                                        }}><i class="fas fa-eye"></i></button>
                                        <button type="button" class="btn btn-lg btn-primary" onClick={() => {
                                            btnHandler(_BTN_TYPE.DELETE)
                                        }}><i class="fas fa-trash"></i></button>
                                        <button type="button" class="btn btn-lg btn-primary" onClick={() => {
                                            btnHandler(_BTN_TYPE.BLOCK)
                                        }}><i class="fas fa-ban"></i></button>
                                        <button type="button" class="btn btn-lg btn-primary" onClick={() => {
                                            btnHandler(_BTN_TYPE.RESET)
                                        }}><i class="fas fa-key"></i></button>
                                    </div>
                                </div>
                            );
                        })
                    }
                </form>
            </PanelBody>
        </Panel>
        <ReloadDetailModal
            status={selectedStatus}
            vendorCode={selectedVendor}
            username={selectedUsername}
            remark={selectedRemark}
            onFinish={(refreshRequired, setScoreUrl = null, score = null, details = {}, rolloverAction = false) => {
                setModalVisibility(false);

                if (setScoreUrl && !stringIsNullOrEmpty(score)) {
                    if (score < 0) {
                        setSetScoreUrl(setScoreUrl);
                        setPasswordConfirmation(true);
                    }
                    else {
                        setScoreConfirmation(setScoreUrl);
                    }
                }
            }}
            visible={modalVisibility} />
        {passwordConfirmation &&
            <PasswordConfirmationModal
                skipPasswordRequiredCheck={true}
                skipPasswordCheck={true}
                onConfirm={(password) => {
                    setScoreConfirmation(setScoreUrl + "&pin=" + password);
                }}
                onCancel={() => { passwordConfirmationCleanup(); }} />
        }
    </>;
}

export default withRouter(PlayerReload);