import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, _SYSTEM_SUPPORT_ROLE_NAME, Role, ReportType } from "../../../util/Constant";
import { numberWithCurrencyFormat, getValidationMessage } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage } from '../../../redux/AppAction';

/// <summary>
/// Author : Wind
/// </summary>
const ManagerLimitReport = props => {
    let _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [regionOption, setRegionOption] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [defaultManagerOption, setDefaultManagerOption] = useState([]);
    const [managerOption, setManagerOption] = useState([]);
    const [selectedManager, setSelectedManager] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [postData, setPostData] = useState({});
    const [csData, setCsData] = useState([]);
    var _userData = useSelector(state => state.authState.userData);

    let _tableColumns = [
        {
            Header: "MANAGER_USERNAME",
            accessor: "userId",
            Footer: () => {
                return <span style={{ float: 'right' }}><b>TOTAL: </b></span>
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_LIMIT_IN",
            accessor: "totalLimitIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.totalLimitIn)
            },
            Footer: ({ page }) => {
                const totalLimitIn = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.totalLimitIn);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(totalLimitIn)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_LIMIT_OUT",
            accessor: "totalLimitOut",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.totalLimitOut)
            },
            Footer: ({ page }) => {
                const totalLimitOut = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.totalLimitOut);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(totalLimitOut)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL",
            accessor: "total",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.total)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.total);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        }
    ]

    let _csColumns = [
        {
            Header: "OPERATED_BY",
            accessor: "operator",
            Footer: () => {
                return <span style={{ float: 'right' }}><b>TOTAL: </b></span>
            },
            disableSortBy: true
        },
        {
            Header: "SHOP",
            accessor: "shop",
            disableSortBy: true
        },
        {
            Header: "SHOP_LIMIT_IN",
            accessor: "shopLimitIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.shopLimitIn)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.shopLimitIn);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "SHOP_LIMIT_OUT",
            accessor: "shopLimitOut",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.shopLimitOut)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.shopLimitOut);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PLAYER_STRIKE",
            accessor: "playerStrike",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.playerStrike)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.playerStrike);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PLAYER_SCORE_IN",
            accessor: "playerScoreIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.playerScoreIn)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.playerScoreIn);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PLAYER_SCORE_OUT",
            accessor: "playerScoreOut",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.playerScoreOut)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.playerScoreOut);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "MEMBER_WALLET_IN",
            accessor: "memberWalletIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.memberWalletIn)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.memberWalletIn);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "MEMBER_WALLET_OUT",
            accessor: "memberWalletOut",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.memberWalletOut)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.memberWalletOut);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL",
            accessor: "total",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.total)
            },
            Footer: ({ page }) => {
                const total = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.total);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(total)}</b></span>
            },
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'manager' });
        register({ name: 'endDate' }, { required: true });

        setValue('manager', []);
        setValue('startDate', startDate);
        setValue('endDate', endDate);
        init();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        var filteredManagerOptions = defaultManagerOption.filter(manager => selectedRegion.includes(manager.regionId));

        var filterSelectedManager = selectedManager.filter(manager => {
            var managerDetail = defaultManagerOption.find(managerOptions => manager == managerOptions.value);
            if (selectedRegion.includes(managerDetail.regionId)) {
                return manager;
            }
        });

        setManagerOption(filteredManagerOptions);
        setSelectedManager(filterSelectedManager);
        setValue('manager', filterSelectedManager);
    }, [selectedRegion]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var regionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.prefix, value: region.id });
            });

            setRegionOption(regionList);
        }

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?roleId=" + Role._MANAGER);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var managerOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                managerOptionList.push({ label: user.username, value: user.id, regionId: user.regionId });
            });

            setDefaultManagerOption(managerOptionList);
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            setPostData({
                "TargetManager": data.manager,
                "StartDate": data.startDate,
                "EndDate": data.endDate,
                "ReportRoleId": parseInt(data.reportType)
            });
    
            setApiUrl(ApiUrl._API_GET_MANAGER_LIMIT_REPORT);

            if (_userData.userRoleId == Role._SUPER_ADMIN) {
                let responseJson = await ApiEngine.post(ApiUrl._API_GET_CS_USED_LIMIT, {
                    "StartDate": data.startDate,
                    "EndDate": data.endDate,
                    "RoleName": _SYSTEM_SUPPORT_ROLE_NAME
                });
    
                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }
    
                setCsData(responseJson[ApiKey._API_DATA_KEY]);
            }
        }
        catch (err) {
            _dispatch(showResponseMessage(false, t(err)));
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable
        columns={_tableColumns}
        fetchUrl={apiUrl}
        renderFooter={true}
        postData={postData}
        exportRequired={true} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t('MANAGER_LIMIT_REPORT')}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("REGION")}</label>
                                    <DualListBox
                                        canFilter
                                        options={regionOption}
                                        selected={selectedRegion}
                                        onChange={(e) => {
                                            setSelectedRegion(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("MANAGER_USERNAME")}</label>
                                    <DualListBox
                                        canFilter
                                        name="manager"
                                        options={managerOption}
                                        selected={selectedManager}
                                        onChange={(e) => {
                                            setValue('manager', e);
                                            setSelectedManager(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select ref={register} name="reportType" className='form-control form-control-lg'>
                                        <option value={ReportType._ALL}>{t("ALL")}</option>
                                        <option value={ReportType._SHOP}>{t("SHOP")}</option>
                                        <option value={ReportType._AGENT}>{t("AGENT")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            {_userData.userRoleId == Role._SUPER_ADMIN && <Panel>
                <PanelHeader>{t("CS_USED_LIMIT")}</PanelHeader>
                <PanelBody>
                    <ReactTable columns={_csColumns} data={csData} renderFooter={true} globalFilterable={false} isHidePagination={true} />
                </PanelBody>
            </Panel>}
        </div>
    );
};

export default ManagerLimitReport;