import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, WebUrl, AccessRight, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, getValidationMessage, numberWithCurrencyFormat } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';

/// <summary>
/// Author : Yong Sheng Chuan
/// Modified: Dorset, Added 3 more fields for the sales report: Total In, Total Out, TotalInOut
/// </summary>
const SalesReport = props => {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [selectedShop, setSelectedShop] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [regionOption, setRegionOption] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([])
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [shopAvailableList, setShopAvailableList] = useState([]);
    const [postData, setPostData] = useState({});

    let _tableColumns = [
        {
            Header: "SHOP_USERNAME",
            accessor: "shopUsername",
            disableSortBy: true
        },
        {
            Header: "SHOP_NAME",
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: "SET_SCORE_IN",
            accessor: "setScoreIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.setScoreIn)
            },
            disableSortBy: true
        },
        {
            Header: "SET_SCORE_OUT",
            accessor: "setScoreOut",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.setScoreOut)
            },
            disableSortBy: true
        },
        {
            Header: "NET_SET_SCORE",
            accessor: "netSetScore",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.netSetScore)
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_IN_COUNT",
            accessor: "totalScoreIn",
            Cell: ({ row }) => {
                return row.original.totalScoreIn
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_OUT_COUNT",
            accessor: "totalScoreOut",
            Cell: ({ row }) => {
                return row.original.totalScoreOut
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL",
            accessor: "netScoreInOut",
            Cell: ({ row }) => {
                return row.original.netScoreInOut
            },
            disableSortBy: true
        },
        {
            Header: "USER_SET_SCORE_IN",
            accessor: "userSetScoreIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.userSetScoreIn)
            },
            disableSortBy: true
        },
        {
            Header: "USER_SET_SCORE_OUT",
            accessor: "userSetScoreOut",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.userSetScoreOut)
            },
            disableSortBy: true
        },
        {
            Header: "USER_NET_SET_SCORE",
            accessor: "userNetSetScore",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.userNetSetScore)
            },
            disableSortBy: true
        },
        {
            Header: "NET_SCORE",
            accessor: "netScore",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.userNetSetScore + row.original.netSetScore)
            },
            disableSortBy: true
        },
        {
            Header: "WALLET_IN",
            accessor: "walletIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.walletIn)
            },
            disableSortBy: true
        },
        {
            Header: "WALLET_OUT",
            accessor: "walletOut",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.walletOut)
            },
            disableSortBy: true
        },
        {
            Header: "WALLET_TOTAL_IN_COUNT",
            accessor: "totalWalletIn",
            Cell: ({ row }) => {
                return row.original.totalWalletIn
            },
            disableSortBy: true
        },
        {
            Header: "WALLET_TOTAL_OUT_COUNT",
            accessor: "totalWalletOut",
            Cell: ({ row }) => {
                return row.original.totalWalletOut
            },
            disableSortBy: true
        },
        {
            Header: "WALLET_TOTAL",
            accessor: "netWalletInOut",
            Cell: ({ row }) => {
                return row.original.netWalletInOut
            },
            disableSortBy: true
        },
        {
            Header: "GPAY_WALLET_IN",
            accessor: "gpayWalletIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.gpayWalletIn)
            },
            disableSortBy: true
        },
        {
            Header: "NET_WALLET",
            accessor: "netWallet",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.netWallet)
            },
            disableSortBy: true
        },
        {
            Header: "FREE_SPIN_WIN_TOTAL",
            accessor: "freeSpinWinTotal",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.freeSpinWinTotal)
            },
            disableSortBy: true
        },
        {
            Header: "WINLOSE_REWARD",
            accessor: "winLoseRewardGranted",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.winLoseRewardGranted)
            },
            disableSortBy: true
        },
        {
            Header: "REWARD_CLAIMED",
            accessor: "rewardClaimed",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.rewardClaimed)
            },
            disableSortBy: true
        },
        {
            Header: "EGHL_SERVICE",
            accessor: "eghlService",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.eghlService)
            },
            disableSortBy: true
        },
        {
            Header: "ONLINE_WITHDRAWAL",
            accessor: "onlineWithdrawal",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.onlineWithdrawal)
            },
            disableSortBy: true
        },
        {
            Header: "LIMIT_IN",
            accessor: "limitIn",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.limitIn)
            },
            disableSortBy: true
        },
        {
            Header: "LIMIT_OUT",
            accessor: "limitOut",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.limitOut)
            },
            disableSortBy: true
        },
        {
            Header: "NET_LIMIT",
            accessor: "netLimit",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.netLimit)
            },
            disableSortBy: true
        },
    ]

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'shops' });
        register({ name: 'endDate' }, { required: true });

        setValue('shops', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION)

        var regionList = [];
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {

            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });
            setRegionOption(regionList);
        }

        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;

        responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let shopOptionsByRegion = [];
            regionList.map(region => {
                let shopsByRegion = responseJson[ApiKey._API_DATA_KEY].filter(shop => shop.regionId == region.value);

                if (shopsByRegion.length) {
                    let options = [];

                    shopsByRegion.map(shop => {
                        options.push({
                            label: shop.username + " (" + shop.shopName+")",
                            value: shop.id,
                            regionId: shop.regionId
                        });
                    });

                    shopOptionsByRegion.push({
                        label: region.label,
                        value: region.value,
                        options: options
                    })
                }
            })

            setShopOption(shopOptionsByRegion);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function onSelectedRegionChange(e) {
        if (selectedRegions.filter(selectedRegion => selectedRegion == e.target.value).length) {
            setSelectedRegions(selectedRegions.filter(selectedRegion => selectedRegion != e.target.value));
        }
        else {
            setSelectedRegions([...selectedRegions, e.target.value]);
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (shopOption.length) {
            let tempShopAvailableList = [];
            selectedRegions.map(selectedRegion => {
                let filteredShops = shopOption.filter(shopOption => shopOption.value == selectedRegion);

                if (filteredShops.length && filteredShops[0].options.length) {
                    filteredShops[0].options.map(filteredShop => {
                        tempShopAvailableList.push(filteredShop.value);
                    })
                }
            })

            setShopAvailableList(tempShopAvailableList);
        }
    }, [selectedRegions, shopOption]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        setPostData({
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "TargetShop": data.shops,
        });

        setApiUrl(ApiUrl._API_GET_SALES_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} />, [postData, apiUrl])

    return (
        <div>
            <h1 className="page-header">{t("SALES_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    {
                                        regionOption.map((region, index) => {
                                            return (
                                                <div className="checkbox checkbox-css checkbox-inline">
                                                    <input id={region.label + region.value} type="checkbox" name={region.label + region.value}
                                                        checked={selectedRegions.filter(selectedRegion => selectedRegion == region.value).length}
                                                        value={region.value}
                                                        ref={register}
                                                        onChange={onSelectedRegionChange} />
                                                    <label htmlFor={region.label + region.value}>{region.label}</label>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <DualListBox
                                        canFilter
                                        name="shops"
                                        options={shopOption}
                                        selected={selectedShop}
                                        available={shopAvailableList}
                                        onChange={(e) => {
                                            setValue('shops', e);
                                            setSelectedShop(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
}

export default SalesReport;