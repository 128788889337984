import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactTable from "../../../components/custom/ReactTable";
import { setBusy, setIdle, showResponseMessage } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, Role, Status, Theme } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch } from "react-redux";

/// <summary>
/// Author : Wind
/// </summary>
const CreditTransferReport = props => {
    let _dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [postData, setPostData] = useState();
    const [vendorOption, setVendorOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));

    const _TRANSFER_TYPE_ID = {
        ALL: -1,
        TO_CREDIT: 1,
        TO_SCORE: 2,
        TRANSFER_SCORE: 3
    };

    let _tableColumns = [
        {
            Header: t("DATE"),
            accessor: "date",
            disableSortBy: true
        },
        {
            Header: t("MEMBER_ID"),
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: t("INITIAL_VENDOR"),
            accessor: "initialVendor",
            Cell: ({ row }) => {
                return parseInt(row.original.transferType) == _TRANSFER_TYPE_ID.TO_SCORE ? ((process.env.REACT_APP_NAME) + ' User App') : row.original.initialVendor;
            },
            disableSortBy: true
        },
        {
            Header: t("TRANSFER_TYPE"),
            accessor: "transferType",
            Cell: ({ row }) => {
                return renderTransferType(row.original.transferType);
            },
            disableSortBy: true
        },
        {
            Header: t("TARGET_VENDOR"),
            accessor: "targetVendor",
            Cell: ({ row }) => {
                return parseInt(row.original.transferType) == _TRANSFER_TYPE_ID.TO_CREDIT ? ((process.env.REACT_APP_NAME == Theme._EPAY ? 'Epay' : 'Oren') + ' User App') :
                    parseInt(row.original.transferType) == _TRANSFER_TYPE_ID.TO_SCORE ? row.original.initialVendor : row.original.targetVendor;
            },
            disableSortBy: true
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("SUCCESS")}</span> : <span className="badge badge-danger">{t("FAILED")}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "MEMBER_INITIAL_CREDIT",
            accessor: "memberInitialCredit",
            disableSortBy: true
        },
        {
            Header: t("AMOUNT"),
            accessor: "amount",
            disableSortBy: true
        },
        {
            Header: "MEMBER_FINAL_CREDIT",
            accessor: "memberFinalCredit",
            disableSortBy: true
        },
        {
            Header: t("PLAYER_ID"),
            accessor: "initialPlayerGameId",
            disableSortBy: true
        },
        {
            Header: t("TARGET_PLAYER_ID"),
            accessor: "targetPlayerGameId",
            disableSortBy: true
        },
    ];

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'vendor' });
        register({ name: 'member' });
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('vendor', []);
        setValue('member', "");
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    function renderTransferType(transferType) {
        let transferFromString = t("SCORE");
        let transferToString = t("SCORE")

        if (parseInt(transferType) == _TRANSFER_TYPE_ID.TO_CREDIT) {
            transferToString = t("CREDIT");
        }
        else if (parseInt(transferType) == _TRANSFER_TYPE_ID.TO_SCORE) {
            transferFromString = t("CREDIT");
        }

        return <span className="badge badge-primary">{transferFromString} <i className="fa fa-arrow-right" style={{ fontSize: '10px', margin: '0px 5px' }}></i> {transferToString}</span>
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var vendorList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
            });

            setVendorOption(vendorList);
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "Status": parseInt(data.status),
            "Type": parseInt(data.transferType),
            "Vendor": data.vendor
        };

        if (!stringIsNullOrEmpty(data.member)) {
            params["MemberId"] = data.member;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_CREDIT_TRANSFER_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable
        className="col-nowrap"
        columns={_tableColumns}
        fetchUrl={apiUrl}
        postData={postData}
        exportRequired={true} />, [apiUrl, postData]);

    return (
        <div>
            <h1 className="page-header">{t("CREDIT_TRANSFER_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("MEMBER_ID")}</label>
                                    <input type="text" name="member" ref={register}
                                        className="form-control form-control-lg" placeholder={t("ENTER_MEMBER_ID")} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_TRANSFER_TYPE_ID.ALL}>{t("ALL")}</option>
                                        <option value={Status._ENABLED}>{t("SUCCESS")}</option>
                                        <option value={Status._DISABLED}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TRANSFER_TYPE")}</label>
                                    <select name="transferType" ref={register} className="form-control form-control-lg">
                                        <option value={_TRANSFER_TYPE_ID.ALL}>{t("ALL")}</option>
                                        <option value={_TRANSFER_TYPE_ID.TO_CREDIT}>{t("TO_CREDIT")}</option>
                                        <option value={_TRANSFER_TYPE_ID.TO_SCORE}>{t("TO_SCORE")}</option>
                                        <option value={_TRANSFER_TYPE_ID.TRANSFER_SCORE}>{t("TRANSFER_SCORE")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <DualListBox
                                        canFilter
                                        name="vendor"
                                        ref={register}
                                        options={vendorOption}
                                        selected={selectedVendor}
                                        onChange={(e) => {
                                            setValue('vendor', e);
                                            setSelectedVendor(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default CreditTransferReport;