import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ReactTable from "../../../components/custom/ReactTable";
import { setBusy, setIdle, showResponseMessage, showMessage } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, AlertTypes, AccessRight, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey, isObjectEmpty } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch, useSelector } from "react-redux";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import RegionFilterDualListBox from '../../../components/custom/RegionFilterDualListBox';

/// <summary>
/// Author : CK
/// </summary>
const StrikeReport = props => {
    let _dispatch = useDispatch();
    const [postData, setPostData] = useState({});
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [vendorOption, setVendorOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [confirmStartDate, setConfirmStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD"));
    const [confirmEndDate, setConfirmEndDate] = useState(moment().format("YYYY-MM-DD"));
    var _userId = useSelector(state => state['authState']['userData']['userId']);
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [strikeDetailList, setStrikeDetailList] = useState([]);
    const [showStrikeDetailModal, setShowStrikeDetailModal] = useState(false);
    const [selectedStrikeRecordData, setSelectedStrikeRecordData] = useState({});
    const [regions, setRegions] = useState([]);

    const _STATUS_OPTIONS = {
        ALL: -1,
        PENDING: 0,
        SUCCESS: 1,
        FAILED: 2,
    };

    /// <summary>
    /// Author : CK
    /// </summary>
    function renderStatus(status) {
        let statusString = "";
        let spanClass = "";

        switch (status) {
            case _STATUS_OPTIONS.SUCCESS:
                spanClass = "badge-green";
                statusString = t("SUCCESS");
                break;
            case _STATUS_OPTIONS.FAILED:
                spanClass = "badge-red";
                statusString = t("FAILED");
                break;
            default:
                spanClass = "badge-default";
                statusString = t("PENDING");
        }

        return <span className={"badge " + spanClass}> {statusString}</span>
    }

    // <summary>
    /// Author: CK
    /// </summary>
    async function verifyPassword(password, transactionData, isApproved, isBulkUpdate = false) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_VERIFY_PASSWORD}?userId=${_userId}&password=${password}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var apiUrl;

            if (isBulkUpdate) {
                apiUrl = ApiUrl._API_CONFIRM_STRIKE + "?startDate=" + confirmStartDate + "&endDate=" + confirmEndDate;
            }
            else {
                apiUrl = ApiUrl._API_UPDATE_BET_RESULT_HISTORY + "?betResultHistoryId=" + transactionData.id + "&status=" + (isApproved ? _STATUS_OPTIONS.SUCCESS : _STATUS_OPTIONS.FAILED);
            }

            var responseJson = await ApiEngine.post(apiUrl);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                handleSubmit(submitForm)();
            }

            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "CreatedTime",
            accessor: "createdTime",
            Footer: () => {
                return <span style={{ float: 'right' }}><b>TOTAL: </b></span>
            },
            disableSortBy: true,
        },
        {
            Header: "SHOP",
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phoneNumber",
            disableSortBy: true
        },
        {
            Header: "PLAYER_ID",
            accessor: "playerId",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "vendor",
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return renderStatus(row.original.status);
            },
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{row.original.amount.toFixed(2)}</span> : <span className="text-danger">{row.original.amount.toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const scoreTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <span><b>{scoreTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "REMARK",
            accessor: "remark",
            disableSortBy: true
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                (checkIfPermissionExist(AccessRight._STRIKE_REPORT_PERMISSION)) &&
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={async () => { setSelectedStrikeRecordData(row.original); await getStrikeDetailList(row.original.id); }}>
                                <span className="text-warning">{t("VIEW_STRIKE_DETAILS")}</span>
                            </DropdownItem>
                            {
                                ((row.original.status == _STATUS_OPTIONS.PENDING || row.original.status == _STATUS_OPTIONS.FAILED) && checkIfPermissionExist(AccessRight._STRIKE_REPORT_PERMISSION, true)) &&
                                <>
                                    <DropdownItem onClick={() => {
                                        _dispatch(showMessage({
                                            content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                                            showCancel: true,
                                            required: true,
                                            inputType: 'password',
                                            validationMsg: t('INVALID_PASSWORD'),
                                            confirmBtnText: t("CONFIRM"),
                                            type: AlertTypes._INPUT,
                                            onConfirm: async (value) => {
                                                await verifyPassword(value, row.original, true);
                                            }
                                        }));
                                    }}>
                                        <span className="text-warning">{t("APPROVE")}</span>
                                    </DropdownItem>
                                    {
                                        row.original.status == _STATUS_OPTIONS.PENDING &&
                                        <DropdownItem onClick={() => {
                                            _dispatch(showMessage({
                                                content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                                                showCancel: true,
                                                required: true,
                                                inputType: 'password',
                                                validationMsg: t('INVALID_PASSWORD'),
                                                confirmBtnText: t("CONFIRM"),
                                                type: AlertTypes._INPUT,
                                                onConfirm: async (value) => {
                                                    await verifyPassword(value, row.original, false);
                                                }
                                            }));
                                        }}>
                                            <span className="text-warning">{t("REJECT")}</span>
                                        </DropdownItem>
                                    }
                                </>
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]

    let _tableStrikeDetailsColumn = [
        {
            Header: "BET_DATE",
            accessor: "betDateTime",
            disableSortBy: true
        },
        {
            Header: "POOL_NAME",
            accessor: "poolName",
            disableSortBy: true
        },
        {
            Header: "NUMBER",
            accessor: "betNumber",
            disableSortBy: true
        },
        {
            Header: "BET_TYPE",
            accessor: "betType",
            disableSortBy: true
        },
        {
            Header: "BET_AMOUNT",
            accessor: "betAmount",
            disableSortBy: true,
            Cell: ({ row }) => (
                <span>{parseFloat(row.original.betAmount).toFixed(2)}</span>
            )
        },
        {
            Header: "DRAW_DATE",
            accessor: "drawDate",
            disableSortBy: true
        },
        {
            Header: "PRIZE_RATE",
            accessor: "prizeRate",
            disableSortBy: true
        },
        {
            Header: "WIN_AMOUNT",
            accessor: "winAmount",
            disableSortBy: true,
            Cell: ({ row }) => (
                <span className="text-success">{parseFloat(row.original.winAmount).toFixed(2)}</span>
            )
        }
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        register({ name: 'vendor' });
        register({ name: 'operatedShop' });
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('vendor', []);
        setValue('operatedShop', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD"));
        setValue('endDate', moment().format("YYYY-MM-DD"));
        init();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        await getShopOption();
        await getVendorOption();
        _dispatch(setIdle());
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getVendorOption() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var vendorList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
            });

            setVendorOption(vendorList);
        }
    }

    /// <summary>
    /// Author : Nelson
    /// </summary>
    async function getShopOption() {
        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];
            var regionOptions = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })

                regionOptions.push({
                    label: key,
                    value: key
                });
            }

            setRegions(regionOptions);
            setShopOption(shopOptionsByRegion);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "Vendor": data.vendor,
            "Status": parseInt(data.status),
            "TargetShop": data.operatedShop
        };

        if (!stringIsNullOrEmpty(data.phoneNumber)) {
            params["PhoneNumber"] = data.phoneNumber;
        }

        if (!stringIsNullOrEmpty(data.playerId)) {
            params["PlayerId"] = data.playerId;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_STRIKE_REPORT);
        setConfirmStartDate(startDate);
        setConfirmEndDate(endDate);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            className="col-nowrap"
            columns={_tableColumns}
            fetchUrl={apiUrl}
            postData={postData}
            renderFooter={true}
            exportRequired={true} />
        , [apiUrl, postData])

    /// <summary>
    /// Author : Nelson
    /// </summary>
    async function getStrikeDetailList(id) {
        setShowStrikeDetailModal(true);
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_STRIKE_DETAILS}?id=${id}`);

        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        else {
            setStrikeDetailList(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    return (
        <div>
            <h1 className="page-header">{t("STRIKE_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD"));
                                                setValue("startDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD"));
                                                setValue("endDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PHONE_NUMBER")}</label>
                                    <input type="text" name="phoneNumber" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PLAYER_ID")}</label>
                                    <input type="text" name="playerId" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_STATUS_OPTIONS.ALL}>{t("ALL")}</option>
                                        <option value={_STATUS_OPTIONS.PENDING}>{t("PENDING")}</option>
                                        <option value={_STATUS_OPTIONS.SUCCESS}>{t("SUCCESS")}</option>
                                        <option value={_STATUS_OPTIONS.FAILED}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <DualListBox
                                        canFilter
                                        name="vendor"
                                        ref={register}
                                        options={vendorOption}
                                        selected={selectedVendor}
                                        onChange={(e) => {
                                            setValue('vendor', e);
                                            setSelectedVendor(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("OPERATED_SHOP")}</label>
                                    <RegionFilterDualListBox
                                        name={"operatedShop"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('operatedShop', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <div className="col-lg-3">
                        <button onClick={() => _dispatch(showMessage({
                            content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                            showCancel: true,
                            required: true,
                            inputType: 'password',
                            validationMsg: t('INVALID_PASSWORD'),
                            confirmBtnText: t("CONFIRM"),
                            type: AlertTypes._INPUT,
                            onConfirm: async (value) => {
                                await verifyPassword(value, null, true, true);
                            }
                        }))} className="btn btn-primary">{t('CONFIRM_STRIKE')}</button>
                    </div>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            <Modal size={'lg'} isOpen={showStrikeDetailModal} toggle={() => { setShowStrikeDetailModal(false) }}>
                <ModalHeader toggle={() => { setShowStrikeDetailModal(false) }}><h4>{(t('STRIKE_DETAILS') + " - " + (selectedStrikeRecordData['playerId']))}</h4></ModalHeader>
                <ModalBody>
                    <ReactTable columns={_tableStrikeDetailsColumn} data={strikeDetailList} />
                </ModalBody>
            </Modal>
        </div >
    );
};

export default StrikeReport;