import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, Status, Role, WebUrl, AlertTypes } from "../../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, validatePhoneNumber } from "../../../util/Util";
import { useLocation } from "react-router-dom";
import { showResponseMessage } from '../../../redux/AppAction';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../../util/ApiEngine.js';
import NavigationButton from '../../../components/custom/NavigationButton';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DualListBox from 'react-dual-listbox';
import { showMessage } from 'redux/AppAction';

/// <summary>
/// Author : Andrew
/// </summary>
const ManagerDetail = props => {
    const { t } = useTranslation();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _history = useHistory();
    const { register, unregister, handleSubmit, errors, setValue, triggerValidation } = useForm();
    const [regionOptions, setRegionOptions] = useState([]);

    const [status, setStatus] = useState(true);
    const [username, setUsername] = useState('');
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState('');
    const [regionId, setRegionId] = useState('');
    const [viewOwnActionOnly, setViewOwnActionOnly] = useState(false);
    const [ableEditUsername, setAbleEditUsername] = useState(false);
    const [isAbleViewLastMessage, setIsAbleViewLastMessage] = useState(false);

    const [shopOptions, setShopOptions] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [defaultSelectedShop, setDefaultSelectedShop] = useState([]);
    const [defaultRegionId, setDefaultRegionId] = useState([]);
    const [defaultOptions, setDefaultOptions] = useState([]);

    const [renderPhone, setRenderPhone] = useState(false);
    const _phoneFieldRef = useRef(null);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    const submitForm = async (data, e) => {
        if (data.password != data.confirmPassword) {
            _dispatch(showResponseMessage(false, t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY")));
            return;
        }

        let params = {
            username: data.username,
            phoneNumber: data.phone,
            userFullName: data.fullName,
            roleId: Role._MANAGER,
            regionId: regionId,
            status: data.status ? Status._ENABLED : Status._DISABLED,
            shopManagerList: selectedShop.join(","),
            viewOwnActionOnly: viewOwnActionOnly,
            isAbleViewLastMessage: isAbleViewLastMessage,
            ableEditUsername: ableEditUsername
        };
      
        if (!stringIsNullOrEmpty(userId)) {
            params["id"] = userId;
        }
        else {
            params["password"] = data.password;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setDefaultSelectedShop(selectedShop);
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_MANAGER),
        }));
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        register({ name: 'regionId' }, { required: t("PLEASE_SELECT_REGION") });
        register({ name: 'phone' });
        setValue("phone", "");
        init();
    }, []);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        if (regionOptions.length != 0) {
            let filteredShopOptions = [];

            regionOptions.map(region => {
                let shopsByRegion = defaultOptions.filter(shop => shop.regionId == region.value);

                if (shopsByRegion.length) {
                    let options = [];
                    shopsByRegion.map(shop => {
                        options.push({
                            label: shop.label,
                            value: shop.value,
                            regionId: shop.regionId
                        });
                    });

                    filteredShopOptions.push({
                        label: region.label,
                        value: region.value,
                        options: options
                    })
                }
            });

            setShopOptions(filteredShopOptions);
            setSelectedShop(regionId == defaultRegionId ? defaultSelectedShop : []);
        }
    }, [regionId, regionOptions]);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?roleId=" + Role._SHOP);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                shopOptionList.push({ label: user.username + " (" + user.shopName + ")", value: user.id, regionId: user.regionId });
            });

            setDefaultOptions(shopOptionList);
        }

        if (_location.state) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + _location.state["id"]);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setStatus(responseJson[ApiKey._API_DATA_KEY]["status"] == Status._ENABLED);
                setViewOwnActionOnly(responseJson[ApiKey._API_DATA_KEY]["viewOwnActionOnly"] == Status._ENABLED);
                setIsAbleViewLastMessage(responseJson[ApiKey._API_DATA_KEY]["isAbleViewLastMessage"] == Status._ENABLED);
                setAbleEditUsername(responseJson[ApiKey._API_DATA_KEY]["ableEditUsername"] == Status._ENABLED);
                setUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
                setFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
                if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"])) {
                    setPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                    setValue("phone", responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                }
                setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setRegionId(responseJson[ApiKey._API_DATA_KEY]["regionId"]);
                setDefaultRegionId(responseJson[ApiKey._API_DATA_KEY]["regionId"]);

                let selectedShops = [];
                let shops = responseJson[ApiKey._API_DATA_KEY]["ownedShops"];
                if (shops != null) {
                    shops.forEach(function (shop) {
                        selectedShops.push(shop.shopUserId);
                    });
                    setSelectedShop(selectedShops);
                    setDefaultSelectedShop(selectedShops);
                }
                unregister('regionId');
            }
        }

        setRenderPhone(true);

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let regionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });
            setRegionOptions(regionList);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onChangePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        setPhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");
        register({ name: "phone" }, {
            validate: (value) => (validatePhoneNumber(fullNumber, countryData["iso2"]) && validatePhoneNumber(newNumber, countryData["iso2"])) || stringIsNullOrEmpty(newNumber) || "REQUIRED_VALID_PHONE_NUMBER"
        });
        setValue("phone", stringIsNullOrEmpty(newNumber) ? '' : formattedNumber);
        triggerValidation("phone")
    }

    return (
        <div>
            <h1 className="page-header">{!stringIsNullOrEmpty(userId) ? t("EDIT_MANAGER") : t("ADD_NEW_MANAGER")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Panel>
                            <PanelHeader children={t('MANAGER_INFO')} noButton="true"></PanelHeader>
                            <PanelBody>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("FULLNAME")}</b></label>
                                            <input type="text" class="form-control form-control-lg" name="fullName" defaultValue={fullName} ref={register({ required: "PLEASE_ENTER_FULL_NAME" })} placeholder={t("ENTER_FULL_NAME")} />
                                            {errors.fullName && <div className="invalid-feedback">{t(errors.fullName.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("PHONE")}</b></label>
                                            {renderPhone && <IntlTelInput
                                                fieldName={"phone"}
                                                preferredCountries={['my']}
                                                style={{ display: "block", width: "100%" }}
                                                containerClassName="intl-tel-input"
                                                inputClassName="form-control form-control-lg"
                                                ref={_phoneFieldRef}
                                                onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                                    onChangePhoneNumber(_phoneFieldRef.current.isValidNumber(phone), newNumber, countryData, fullNumber);
                                                }}
                                                onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                                    onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                                }}
                                                onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                                    onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                                }}
                                                customPlaceholder={(placeholder, countryData) => {
                                                    return placeholder.split(/[- ]+/).join('');
                                                }}
                                                defaultValue={phone}
                                                value={phone}
                                                useMobileFullscreenDropdown={false}
                                                separateDialCode
                                            />}
                                            {(errors.phone) && <div className="invalid-feedback">{t(errors.phone.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("USERNAME")}</b></label>
                                            <input type="text" class="form-control form-control-lg" name="username" defaultValue={username} ref={register({ required: "PLEASE_ENTER_USERNAME" })} placeholder={t("ENTER_USERNAME")} />
                                            {errors.username && <div className="invalid-feedback">{t(errors.username.message)}</div>}
                                        </div>
                                    </div>
                                    {!_location.state &&
                                        <>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label><b>{t("PASSWORD")}</b></label>
                                                    <input type="password" name="password" className="form-control form-control-lg" ref={register({ required: "PLEASE_ENTER_PASSWORD" })} placeholder={t("ENTER_PASSWORD")} />
                                                    {errors.password && <div className="invalid-feedback">{t(errors.password.message)}</div>}
                                                </div>
                                            </div>


                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label><b>{t("CONFIRM_PASSWORD")}</b></label>
                                                    <input type="password" name="confirmPassword" className="form-control form-control-lg" ref={register({ required: "PLEASE_ENTER_CONFIRM_PASSWORD" })} placeholder={t("ENTER_CONFIRM_PASSWORD")} />
                                                    {errors.confirmPassword && <div className="invalid-feedback">{t(errors.confirmPassword.message)}</div>}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("REGION")}</b></label>
                                            <Select name="regionId" options={regionOptions}
                                                placeholder={(regionOptions.filter(option => option.value == regionId)[0] !== undefined) ? (regionOptions.filter(option => option.value == regionId)[0].label) : ""}
                                                value={regionOptions.filter(option => option.value == regionId)}
                                                onChange={(e) => {
                                                    unregister('regionId');
                                                    setRegionId(e.value);
                                                }} />
                                            {errors.regionId && <div className="invalid-feedback">{t(errors.regionId.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("STATUS")}</b></label>
                                            <br />
                                            <div className="switcher ">
                                                <input type="checkbox" name="status" id="status"
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                    value={true}
                                                    checked={status}
                                                    ref={register} />
                                                <label htmlFor="status"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("SELF_MANAGEMENT")}</b></label>
                                            <br />
                                            <div className="switcher ">
                                                <input type="checkbox" name="viewOwnActionOnly" id="viewOwnActionOnly"
                                                    onChange={(e) => setViewOwnActionOnly(e.target.checked)}
                                                    value={false}
                                                    checked={viewOwnActionOnly}
                                                    ref={register} />
                                                <label htmlFor="viewOwnActionOnly"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("IS_ABLE_TO_VIEW_LAST_MESSAGE")}</b></label>
                                            <br />
                                            <div className="switcher ">
                                                <input type="checkbox" name="isAbleViewLastMessage" id="isAbleViewLastMessage"
                                                    onChange={(e) => setIsAbleViewLastMessage(e.target.checked)}
                                                    value={false}
                                                    checked={isAbleViewLastMessage}
                                                    ref={register} />
                                                <label htmlFor="isAbleViewLastMessage"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("IS_ABLE_TO_EDIT_USERNAME")}</b></label>
                                            <br />
                                            <div className="switcher ">
                                                <input type="checkbox" name="ableEditUsername" id="ableEditUsername"
                                                    onChange={(e) => setAbleEditUsername(e.target.checked)}
                                                    value={false}
                                                    checked={ableEditUsername}
                                                    ref={register} />
                                                <label htmlFor="ableEditUsername"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>
                            <PanelHeader noButton>{t('ASSIGN_SHOPS')}</PanelHeader>
                            <PanelBody>
                                <DualListBox
                                    canFilter
                                    selected={selectedShop}
                                    options={shopOptions}
                                    onChange={(e) => { setSelectedShop(e) }}
                                />
                                <button type="submit" className="btn btn-primary mt-3">{t("SUBMIT")}</button>
                            </PanelBody>
                        </Panel>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ManagerDetail;