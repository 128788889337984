import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo } from "../../../util/Util";
import { ApiKey, ApiUrl, Role, AnnouncementType, WebUrl, AlertTypes } from "../../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from '../../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import ApiEngine from '../../../util/ApiEngine.js';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import PreviewMessageModal from '../../../components/custom/PreviewMessageModal';
import Dropzone from 'react-dropzone';

/// <summary>
/// Author : YJ
/// </summary>
const ShopMessageDetail = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    let _location = useLocation();
    let _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, triggerValidation, getValues } = useForm({ mode: 'all' });

    const [messageId, setMessageId] = useState('');
    const [dayCount, setDayCount] = useState('');
    const [forApp, setForApp] = useState(false);
    const [content, setContent] = useState();
    const [title, setTitle] = useState();
    const [selectedShops, setSelectedShops] = useState([]);
    const [regionId, setRegionId] = useState(false)
    const [status, setStatus] = useState(false);
    const [regionOptions, setRegionOptions] = useState([]);
    const [shopOptions, setShopOptions] = useState([]);
    const [initCompleted, setInitCompleted] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [alertRequired, setAlertRequired] = useState(false);
    const [isNewContentImg, setIsNewContentImg] = useState(false);
    const [contentImgFile, setContentImgFile] = useState();
    const [contentImg, setContentImg] = useState();
    const _submitBtnRef = useRef(null);

    const [previewParams, setPreviewParams] = useState([]);
    const [modalVisibility, setModalVisibility] = useState(false);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        register({ name: 'regionId' }, { required: "PLEASE_SELECT_REGION" });
        register({ name: 'selectedShops' }, {
            required: t("PLEASE_SELECT_AT_LEAST_ONE_SHOP"),
            validate: { exist: value => value.length > 0 || "PLEASE_SELECT_AT_LEAST_ONE_SHOP" }
        });
        setValue("dayCount", 30);
        init();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let regions = [];
            responseJson[ApiKey._API_DATA_KEY].map((region, index) => {
                regions.push({ value: region.id, label: region.name });
            })
            setRegionOptions(regions);
        }

        if (_location.state) {
            responseJson = await ApiEngine.get(ApiUrl._API_GET_SYSTEM_MESSAGE_BY_ID + "?id=" + _location.state.id);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
                setValue('content', responseJson[ApiKey._API_DATA_KEY]["content"])
                setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
                setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                setMessageId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setDayCount(responseJson[ApiKey._API_DATA_KEY]["minimumActiveDayCount"]);
                setForApp(responseJson[ApiKey._API_DATA_KEY]["istargetApp"]);
                setRegionId(responseJson[ApiKey._API_DATA_KEY]["regionIdList"][0]);
                setValue('regionId', responseJson[ApiKey._API_DATA_KEY]["regionIdList"][0]);
                setSelectedShops(responseJson[ApiKey._API_DATA_KEY]["shopIdList"]);
                setValue('selectedShops', responseJson[ApiKey._API_DATA_KEY]["shopIdList"]);
                setContentImg(responseJson[ApiKey._API_DATA_KEY]["contentImage"]);
            }
        }
        setInitCompleted(true);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function getShops() {
        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP + "&RegionId=" + regionId;

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let shops = [];
            responseJson[ApiKey._API_DATA_KEY].map((shop, index) => {
                shops.push({ value: shop.id, label: shop.username + " (" + shop.shopName + ")" });
            })
            setShopOptions(shops);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(regionId)) {
            if (initCompleted) {
                setSelectedShops([]);
                setValue('selectedShops', []);
            }
            getShops();
        }
    }, [regionId])

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (status) {
            _dispatch(showMessage({
                type: AlertTypes._WARNING,
                content: t("IT_WILL_BROADCAST_DIRECTLY_ARE_YOU_SURE") + "?",
                showCancel: true,
                confirmBtnText: t("CONFIRM"),
                onConfirm: () => { setIsConfirm(true); _submitBtnRef.current.click(); },
                onCancel: () => { setIsConfirm(false); }
            }));
        }
    }, [alertRequired])

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        if (!isConfirm && status) {
            setAlertRequired(!alertRequired);
            return;
        }

        var selectedShopString = [];

        for (var i = 0; selectedShops != null && i < selectedShops.length; i++) {
            selectedShopString.push(selectedShops[i]);
        }

        let params = {
            "regionId": data.regionId,
            "shops": selectedShopString,
            "title": data.title,
            "content": data.content,
            "minimumActiveDayCount": data.dayCount,
            "istargetApp": forApp,
            "systemMessageTypeId": AnnouncementType._ANNOUNCEMENT_TYPE_SHOP,
            "status": status
        }

        if (contentImgFile) {
            params["contentImg"] = contentImgFile;
            params["isNewContentImg"] = isNewContentImg;
        }

        if (!stringIsNullOrEmpty(messageId)) {
            params["id"] = messageId;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_MESSAGE, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_SHOP_MESSAGE)
        }));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function previewTarget() {
        const triggerResult = await triggerValidation();
        if (!triggerResult) {
            return;
        }

        var selectedShopString = [];

        for (var i = 0; selectedShops != null && i < selectedShops.length; i++) {
            selectedShopString.push(selectedShops[i]);
        }

        let params = {
            "shops": selectedShopString,
            "minimumActiveDayCount": getValues()['dayCount'],
            "systemMessageTypeId": AnnouncementType._ANNOUNCEMENT_TYPE_SHOP,
        }

        setPreviewParams(params);
        setModalVisibility(true);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setContentImgFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewContentImg(true);
                setContentImg(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <div>
            <PreviewMessageModal
                params={previewParams}
                messageType={AnnouncementType._ANNOUNCEMENT_TYPE_SHOP}
                visible={modalVisibility}
                onFinish={() => { setModalVisibility(false) }} />
            <h1 className="page-header">{messageId ? t("EDIT_SHOP_MESSAGE") : t("ADD_NEW_SHOP_MESSAGE")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <div className="panel panel-inverse">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">{t("MESSAGE_DETAILS")}</h4>
                        </div>
                        <div className="panel-body">
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("REGION")}</b></label>
                                            <div className="col-lg-5">
                                                <Select name="regionId" options={regionOptions}
                                                    placeholder={(regionOptions.filter(option => option.value == regionId)[0] !== undefined) ? (regionOptions.filter(option => option.value == regionId)[0].label) : t("PLEASE_SELECT_REGION")}
                                                    value={regionOptions.filter(option => option.value == regionId)}
                                                    onChange={(e) => {
                                                        setValue("regionId", e.value)
                                                        setRegionId(e.value);
                                                    }} />
                                                {errors.regionId && <div className="invalid-feedback">{t(errors.regionId.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("SHOPS")}</b></label>
                                            <div className="col-lg-5">
                                                <DualListBox
                                                    name="selectedShops" ref={register}
                                                    canFilter
                                                    options={shopOptions}
                                                    selected={selectedShops}
                                                    onChange={(e) => {
                                                        setValue('selectedShops', e);
                                                        setSelectedShops(e);
                                                        triggerValidation('selectedShops');
                                                    }}
                                                />
                                                {errors.selectedShops && <div className="invalid-feedback">{t(errors.selectedShops.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("TITLE")}</b></label>
                                            <div className="col-lg-5">
                                                <input type="text" name="title" className="form-control m-b-5"
                                                    ref={register({
                                                        required: t("PLEASE_ENTER_TITLE")
                                                    })}
                                                    placeholder={t("PLEASE_ENTER_TITLE")} defaultValue={title} />
                                                {errors.title && <div className="invalid-feedback">{t(errors.title.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("CONTENT")}</b></label>
                                            <div className="col-lg-5">
                                                <textarea class="form-control" rows="5"
                                                    ref={register({
                                                        required: t("PLEASE_ENTER_CONTENT")
                                                    })}
                                                    style={{ marginTop: "10px" }}
                                                    name="content"
                                                    defaultValue={content}>
                                                </textarea>
                                                {errors.content && <div className="invalid-feedback">{t(errors.content.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("CONTENT_IMG")}</b></label>
                                            <div className="col-lg-5">
                                                <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {stringIsNullOrEmpty(contentImg) && <h4 style={{ color: "grey" }} >{t("UPLOAD_LOGO")}</h4>}
                                                                {!stringIsNullOrEmpty(contentImg) && <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={contentImg}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                {
                                                    !stringIsNullOrEmpty(contentImg) &&
                                                    <button type="button" className="mt-2 btn btn-danger"
                                                        onClick={() => {
                                                            setContentImg("");
                                                            setContentImgFile();
                                                        }}>
                                                        {t("REMOVE_IMAGE")}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t('MEMBER_ACTIVE_DAY')} : ({t('DEFAULT')}: 30 {t('DAYS')})</b></label>
                                            <div className="col-lg-5">
                                                <input type="number" className="form-control" name="dayCount"
                                                    ref={register({
                                                        required: t("PLEASE_ENTER_DAY_COUNT"),
                                                        min: {
                                                            value: 0.01,
                                                            message: t('PLEASE_ENTER_VALUE_MORE_THAN_ZERO')
                                                        },
                                                    })}
                                                />
                                                {errors.dayCount && <div className="invalid-feedback">{t(errors.dayCount.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("FOR_APPS_ONLY")}</b></label>
                                            <div className="col-lg-5">
                                                <div className="switcher">
                                                    <input type="checkbox" name="forApp" id="forApp"
                                                        onChange={(e) => setForApp(e.target.checked)}
                                                        value={forApp}
                                                        checked={forApp}
                                                        ref={register} />
                                                    <label htmlFor="forApp"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("BROADCAST")}</b></label>
                                            <div className="col-lg-5">
                                                <div className="switcher">
                                                    <input type="checkbox" name="status" id="status"
                                                        onChange={(e) => {
                                                            setStatus(e.target.checked);
                                                            setIsConfirm(!e.target.checked);
                                                        }}
                                                        value={status}
                                                        checked={status}
                                                        ref={register} />
                                                    <label htmlFor="status"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button type="submit" ref={_submitBtnRef} style={{ margin: "5px" }} className="btn btn-primary">{t("SUBMIT")}</button>
                                            <button type="button" onClick={previewTarget} style={{ margin: "5px" }} className="btn btn-warning">{t("PREVIEW")}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ShopMessageDetail);